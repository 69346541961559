import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar_01,
  MedExpertIcon,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_16,
} from "../../../../Routes/ImagePath";

const ProjectSearch = () => {
  const projectsData = [
    {
      id: 1,
      title: "MedExpert Planning",
      openTasks: 1,
      completedTasks: 9,
      description: "Dummy industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "Jeffery Lalor", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "Anil Choudhary", avatar: "MedExpertIcon" },
        { id: 2, name: "Rakesh Yadav", avatar: "Avatar_09" },
        { id: 3, name: "Amit Bajaj", avatar: "Avatar_10" },
        { id: 4, name: "Thirumalai Narayanan", avatar: "Avatar_05" },
      ],
    },
    {
      id: 2,
      title: "Project Management",
      openTasks: 2,
      completedTasks: 5,
      description: "Medicine industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "Jeffery Lalor", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "Anil Choudhary", avatar: "MedExpertIcon" },
        { id: 2, name: "Rakesh Yadav", avatar: "Avatar_09" },
        { id: 3, name: "Amit Bajaj", avatar: "Avatar_10" },
        { id: 4, name: "Thirumalai Narayanan", avatar: "Avatar_05" },
      ],
    },
    {
      id: 3,
      title: "MedExpert",
      openTasks: 3,
      completedTasks: 3,
      description: "Medicine industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "Jeffery Lalor", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "Anil Choudhary", avatar: "MedExpertIcon" },
        { id: 2, name: "Rakesh Yadav", avatar: "Avatar_09" },
        { id: 3, name: "Amit Bajaj", avatar: "Avatar_10" },
        { id: 4, name: "Thirumalai Narayanan", avatar: "Avatar_05" },
      ],
    },
    {
      id: 4,
      title: "MedExpert Billing and Consulting",
      openTasks: 12,
      completedTasks: 4,
      description: "Medicine industry...",
      deadline: "17 Apr 2019",
      projectLeader: { name: "Jeffery Lalor", avatar: "Avatar_16" },
      teamMembers: [
        { id: 1, name: "Anil Choudhary", avatar: "MedExpertIcon" },
        { id: 2, name: "Rakesh Yadav", avatar: "Avatar_09" },
        { id: 3, name: "Amit Bajaj", avatar: "Avatar_10" },
        { id: 4, name: "Thirumalai Narayanan", avatar: "Avatar_05" },
      ],
    },
  ];
  return (
    <div>
      <div className="tab-content">
        <div className="tab-pane show active" id="results_projects">
          <div className="row">
            {projectsData.map((project, index) => (
              <div className="col-lg-4 col-sm-6 col-md-4 col-xl-3 d-flex">
                <div className="card w-100">
                  <div className="card-body">
                    <div className="dropdown dropdown-action profile-action">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>
                      <div className="dropdown-menu dropdown-menu-right">
                        <Link className="dropdown-item" to="#">
                          <i className="fa fa-pencil m-r-5" /> Edit
                        </Link>
                        <Link className="dropdown-item" to="#">
                          <i className="fa fa-trash m-r-5" /> Delete
                        </Link>
                      </div>
                    </div>
                    <h4 className="project-title">
                      <Link to="/projects-view">{project.title}</Link>
                    </h4>
                    <small className="block text-ellipsis m-b-15">
                      <span className="text-xs">{project.openTasks}</span>{" "}
                      <span className="text-muted">open tasks, </span>
                      <span className="text-xs">
                        {project.completedTasks}
                      </span>{" "}
                      <span className="text-muted">tasks completed</span>
                    </small>
                    <p className="text-muted">{project.description}</p>
                    <div className="pro-deadline m-b-15">
                      <div className="sub-title">Deadline:</div>
                      <div className="text-muted">{project.deadline}</div>
                    </div>
                    <div className="project-members m-b-15">
                      <div>Project Leader :</div>
                      <ul className="team-members">
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            title="Jeffery Lalor"
                          >
                            <img alt="" src={Avatar_16} />
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="project-members m-b-15">
                      <div>Team :</div>
                      <ul className="team-members">
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            title="Anil Choudhary"
                          >
                            <img alt="" src={MedExpertIcon} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            title="Rakesh Yadav"
                          >
                            <img alt="" src={Avatar_09} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            title="Amit Bajaj"
                          >
                            <img alt="" src={Avatar_10} />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            data-bs-toggle="tooltip"
                            title="Thirumalai Narayanan"
                          >
                            <img alt="" src={Avatar_05} />
                          </Link>
                        </li>
                        <li className="dropdown avatar-dropdown">
                          <Link
                            to="#"
                            className="all-users dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            +15
                          </Link>
                          <div className="dropdown-menu dropdown-menu-right">
                            <div className="avatar-group">
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={MedExpertIcon} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_09} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_10} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_05} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_11} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_12} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_13} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_01} />
                              </Link>
                              <Link className="avatar avatar-xs" to="#">
                                <img alt="" src={Avatar_16} />
                              </Link>
                            </div>
                            <div className="avatar-pagination">
                              <ul className="pagination">
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Previous"
                                  >
                                    <span aria-hidden="true">«</span>
                                    <span className="sr-only">Previous</span>
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    1
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link className="page-link" to="#">
                                    2
                                  </Link>
                                </li>
                                <li className="page-item">
                                  <Link
                                    className="page-link"
                                    to="#"
                                    aria-label="Next"
                                  >
                                    <span aria-hidden="true">»</span>
                                    <span className="sr-only">Next</span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <p className="m-b-5">
                      Progress{" "}
                      <span className="text-success float-end">40%</span>
                    </p>
                    <div className="progress progress-xs mb-0">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-bs-toggle="tooltip"
                        title="40%"
                        style={{ width: "40%" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectSearch;
