/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { MedExpertIcon } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import _ from "lodash";
import PreLoader from "../../../components/PreLoader";
import { useNavigate } from "react-router-dom";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import CandidateProfileTab from "./CandidateProfileTab";
import EditCandidateModal from "../../../components/Administration/Jobs/CandidatesModal/EditCandidateModal";

const CandidateProfile = () => {
  const headers = getAuthHeaders();
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [candidateAvatar, setCandidateAvatar] = useState(MedExpertIcon);
  const navigate = useNavigate();

  const fetchCandidateData = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/candidates/${id}`,
        { headers }
      );

      setUserData(response.data.data);
      if (response.data.data.profile_image) {
        setCandidateAvatar(response.data.data.profile_image.file_path);
      } else {
        setCandidateAvatar(MedExpertIcon);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 404) {
        navigate("/error-404");
      }
    }
  };
  useEffect(() => {
    

    fetchCandidateData();
  }, [id]);

  const handleCandidateUpdate = () => {
    fetchCandidateData();
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Candidate Profile"
            title="Dashboard"
            subtitle="Candidate Profile"
            modal="#edit_candidate"
            name="Edit Candidate Profile"
            Linkname="#"
          />
          <PreLoader showLoader={loading} />
          {!loading && (
            <>
              <div className="card mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-view">
                        <div className="profile-img-wrap">
                          <div
                            className="profile-img-wrap edit-img"
                          >
                            <img
                              className="inline-block"
                              src={candidateAvatar}
                              alt="user"
                            />
                          </div>
                        </div>
                        <div className="profile-basic">
                          <div className="row">
                            <div className="col-md-10">
                              <div className="profile-info-left" style={{borderRight: 0}}>
                                <h3 className="user-name m-t-0 mb-0">
                                  {_.startCase(userData.full_name)}
                                </h3>
                                <ul className="personal-info">
                                  <li>
                                    <div className="text">
                                      {_.upperCase(userData.entity_name)}
                                    </div>
                                  </li>
                                  {userData.applied_date && (
                                    <li>
                                      <div className="title">
                                        Applied Date: 
                                      </div>
                                      <div className="text">
                                        {moment(userData.applied_date).format(
                                          "D MMMM, YYYY"
                                        )}
                                      </div>
                                    </li>
                                  )}

                                  {userData.offer_date && (
                                    <li>
                                      <div className="title">Offer Date: </div>
                                      <div className="text">
                                        {moment(userData.offer_date).format(
                                          "D MMMM, YYYY"
                                        )}
                                      </div>
                                    </li>
                                  )}

                                  {userData.offer_acceptance_date && (
                                    <li>
                                      <div className="title">
                                        Offer Acceptance Date: 
                                      </div>
                                      <div className="text">
                                        {moment(
                                          userData.offer_acceptance_date
                                        ).format("D MMMM, YYYY")}
                                      </div>
                                    </li>
                                  )}

                                  {userData.joining_date && (
                                    <li>
                                      <div className="title">Joining Date:</div>
                                      <div className="text">
                                        {moment(userData.joining_date).format(
                                          "D MMMM, YYYY"
                                        )}
                                      </div>
                                    </li>
                                  )}

                                  <li>
                                    <div className="title">Status:</div>
                                    <div className="text">
                                      {_.startCase(userData.status)}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">
                                      Candidate Status:
                                    </div>
                                    <div className="text">
                                      {_.startCase(userData.candidate_status)}
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <ul className="personal-info"></ul>
                            </div>
                          </div>
                        </div>
                        <div className="pro-edit">
                          <Link
                            data-bs-target="#edit_candidate"
                            data-bs-toggle="modal"
                            className="edit-icon"
                            to="#"
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card tab-box mb-0">
                <div className="row user-tabs">
                  <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <Link
                          to="#candidate_profile"
                          data-bs-toggle="tab"
                          className="nav-link active"
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#candidate_jobs"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Applied Jobs
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#candidate_documents"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Documents
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#candidate_notifications"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Task Notifications
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Profile Info Tab */}
              <CandidateProfileTab
                userData={userData}
                onUpdate={handleCandidateUpdate}
              />
            </>
          )}
        </div>
      </div>
      <EditCandidateModal candidate={userData} handleUpdateList={handleCandidateUpdate}/>
    </>
  );
};

export default CandidateProfile;
