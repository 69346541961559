import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";
import moment from "moment";

import { ts_logo } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import StatusUpdateEntityDocumentModal from "./StatusUpdateEntityDocumentModal";
import DeleteEntityDocumentModal from "./DeleteEntityDocumentModal";
import EditEntityDocumentModal from "./EditEntityDocumentModal";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";

const EntityDocuments = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const [entityDocumentsData, setEntityDocumentsData] = useState([]);
  const [entityDocumentData, setEntityDocumentData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const documentTypesOptions = JSON.parse(
    localStorage.getItem("document_types")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");

  useImperativeHandle(ref, () => ({
    updateDocumentsList() {
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() +
          `/api/entities/${props.entity.id}/documents`,
        {
          params: {
            filter: props.filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let entityDocumentData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      entityDocumentData = entityDocumentData.map((item) => {
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setEntityDocumentsData(entityDocumentData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    if (props.entity && props.entity.id) {
      fetchData();
    }
  }, [
    currentPage,
    pageSize,
    sortingColumn,
    sortingOrder,
    props.filterText,
    props.entity.id,
  ]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const [showFilter, setShowFilter] = useState(false);
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [focusedThree, setFocusedThree] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [inputValueThree, setInputValueThree] = useState("");

  const columns = [
    {
      title: "Document Category",
      dataIndex: "document_category",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <Link to="#" className="profile-split">
                {record.document_category}
              </Link>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Title",
      dataIndex: "document_title",
      sorter: true,
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <Link
              to={record.file_info.file_path}
              target="_blank"
            >
              {record.document_title}
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
    {
      title: "Size",
      dataIndex: "document_size",
      sorter: true,
      render: (text, record) => (
        <div className="d-flex">
          <div>{record.file_info.file_size}
          </div>
        </div>
      ),
    },
    {
      title: "Last Updated",
      dataIndex: "document_last_updated",
      sorter: true,
      render: (text, record) => {
        return moment(record.modified_at).format(
          "D MMMM YYYY, hh:mm A"
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_document"
                onClick={(e) => {
                  e.preventDefault();
                  setEntityDocumentData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_entity_document"
              onClick={(e) => {
                e.preventDefault();
                setEntityDocumentData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_company_document"
              onClick={(e) => {
                e.preventDefault();
                setEntityDocumentData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="table-responsive">
        <Table
          className="table-striped"
          columns={columns}
          dataSource={entityDocumentsData}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalRecords,
          }}
          onChange={handleTableChange}
        />
      </div>
      <EditEntityDocumentModal
        entity={props.entity}
        entityDocument={entityDocumentData}
        handleUpdateList={handleUpdateList}
      />
      <StatusUpdateEntityDocumentModal
        entity={props.entity}
        entityDocument={entityDocumentData}
        handleUpdateList={handleUpdateList}
        newStatus={newStatus}
      />
      <DeleteEntityDocumentModal
        entity={props.entity}
        entityDocument={entityDocumentData}
        handleUpdateList={handleUpdateList}
      />
    </>
  );
});

export default EntityDocuments;
