import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import SearchBox from "../../../../components/SearchBox";
import PreLoader from "../../../../components/PreLoader";
import MasterModal from "../../../../components/modelpopup/AllMasterModal";
import DeleteModal from "../../../../components/modelpopup/DeleteConfirmationModal";
import {
    statuses // Importing industry status constants
} from "../../../../utils/Constants"; // Importing constants
import {
    getAuthHeaders, getApiUrl // Importing industry status class 
} from "../../../../utils/AuthUtils"; // Importing Misc functions
import {
    getStatusBadgeClass // Importing designation status constants
} from "../../../../utils/Misc"; // Importing constants
import SearchFilter from "../../../../components/SearchFilter";

const AllIndustries = () => {
    // State variables initialization
    const headers = getAuthHeaders();
    const [industryData, setIndustryData] = useState([]); // State to hold industry data
    const [currentPage, setCurrentPage] = useState(1); // State for current page number
    const [pageSize, setPageSize] = useState(10); // State for number of items per page
    const [totalRecords, setTotalRecords] = useState(0); // State for total number of records
    const [loading, setLoading] = useState(true); // State for loading status
    const [sorter, setSorter] = useState({}); // State for sorting information
    const [selectedIndustryData, setSelectedIndustryData] = useState({});
    const [searchText, setSearchText] = useState('');
    // Fetch data from API on component mount or when dependencies change
    const fetchData = async () => {
        try {
            const response = await axios.get(`${getApiUrl()}/api/masters/industries/`, {
                params: {
                    filter: searchText, // Placeholder for filter criteria
                    limit: pageSize, // Number of items per page
                    page: currentPage, // Current page number
                    sortField: sorter.field, // Field to sort by
                    sortOrder: sorter.order === "descend" ? "desc" : "asc", // Sort order (ascending or descending)
                },
                headers: headers
            });
            setIndustryData(response.data.data); // Set fetched industry data
            setTotalRecords(response.data.total); // Set total number of records
            setLoading(false); // Update loading state to false after data is fetched
        } catch (error) {
            setLoading(true); // Set loading state to true on error
            console.error("Error fetching industry data", error);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetch data function
    }, [searchText, currentPage, pageSize, sorter]); // Dependencies for useEffect hook

    // Handle table pagination and sorting changes
    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current); // Update current page
        setPageSize(pagination.pageSize); // Update page size
        setSorter(sorter); // Update sorting information
    };
    const handleAddClick = () => {
        setSelectedIndustryData({}); // To add a new record
        // Trigger modal to open
    };

    const handleEditClick = (masterData) => {
        setSelectedIndustryData(masterData); // To edit an existing record
        // Trigger modal to open
    };

    const handleSubmitSuccess = async (data) => {
        fetchData();
    };

    /// search componant functions     
    const handleSearchTextChange = (event) => {
        setSearchText(event.target.value);        
    };

    const handleSearchButtonClick = () => {
        fetchData(); 
    };
    // Table columns configuration
    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            render: (text, record) => (
                <span className="table-avatar">
                    {text}
                </span>
            ),
            sorter: true, // Enable sorting by this column
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (text, record) => (
                <span className="table-avatar">
                    {text}
                </span>
            ),
            sorter: (a, b) => (a.name?.length || 0) - (b.name?.length || 0),
        },
        {
            title: "Description",
            dataIndex: "description",
            sorter: (a, b) => (a.description?.length || 0) - (b.description?.length || 0),
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (text, record) => {
                const badgeClass = getStatusBadgeClass(text);
                return <span className={badgeClass}>{text}</span>; // Return styled status badge
            },
            sorter: (a, b) => (a.status?.length || 0) - (b.status?.length || 0),
        },
        {
            title: "Action",
            sorter: false, // Enable sorting by this column
            render: (text, record) => (
                <div className="dropdown dropdown-action text-end">
                    <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">

                        <Link
                            className="dropdown-item"
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#master-modal"
                            onClick={() => handleEditClick(record)}
                        >
                            <i className="fa fa-pencil m-r-5" /> Edit
                        </Link>
                        <Link
                            className="dropdown-item"
                            to="#"
                            onClick={() => handleEditClick(record)}
                            data-bs-toggle="modal"
                            data-bs-target="#delete_modal"
                        >
                            <i className="fa fa-trash m-r-5" /> Delete
                        </Link>
                    </div>
                </div>
            ),
        },
    ];
    return (
        <div>
            <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                    {/* Page Header */}
                    <Breadcrumbs
                        maintitle="Industries"
                        title="Masters"
                        subtitle="Industries"
                        name="Add Industry"
                        modal="#master-modal"
                        onAddClick={handleAddClick}
                        Linkname="/masters/industries"
                    />
                    <hr />
                    {/* /Page Header */}
                    {/* <IndustryListFilter /> */}
                    <div className="row">
                        <div className="col-md-12">
                            <PreLoader showLoader={loading} />
                            {!loading &&
                                <div className="table-responsive">
                                   <SearchFilter
                                        handleSearchTextChange={handleSearchTextChange}
                                        onSearchButtonClick={handleSearchButtonClick}
                                        placeholder="Search..."
                                        inputClassName=""
                                        buttonClassName=""
                                        containerClassName=""
                                        searchValue={searchText}
                                    /> 
                                    <Table
                                        className="table-striped"
                                        columns={columns}
                                        dataSource={industryData}
                                        rowKey={(record) => record.id}
                                        pagination={{
                                            current: currentPage,
                                            pageSize: pageSize,
                                            total: totalRecords,
                                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                            showSizeChanger: true, // Enables the page size selection dropdown
                                            pageSizeOptions: ['10', '20', '50', '100'], // Options for the page size selection
                                        }}
                                        onChange={handleTableChange}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <MasterModal
                module="industries"
                masterData={selectedIndustryData}
                onSubmitSuccess={handleSubmitSuccess}
            />
            <DeleteModal
                method="patch" // Specify the HTTP method for deletion (e.g., 'delete', 'patch', etc.)
                url={`${getApiUrl()}/api/masters/industries/${selectedIndustryData.id}`} // Specify the API endpoint for deletion                
                itemName="Industry" // Specify the name of the item being deleted
                handleUpdateList={handleSubmitSuccess} // Specify a function to handle list update after deletion
            />
        </div>
    );
};

export default AllIndustries;
