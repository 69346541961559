import {
  MedExpertIcon,
  Avatar_03,
  Avatar_06,
  Avatar_13,
  Avatar_17,
} from "../../Routes/ImagePath";

// Create an object and assign it to a variable
const notificationsData = {
  notifications: [
    {
      id: 1,
      image: MedExpertIcon,
      name: "Rakesh Yadav",
      contents: "added new task",
      contents_2: "Patient appointment booking",
      time: "4 mins ago",
    },
    {
      id: 2,
      image: Avatar_03,
      name: "Kiran Joji",
      contents: "changed the task name",
      contents_2: "Appointment booking with payment gateway",
      time: "6 mins ago",
    },
    {
      id: 3,
      image: Avatar_06,
      name: " Sukesh Gopi",
      contents: "added",
      contents_2:
        "Sukesh Gopi and Claire Mapes to project Doctor available module",
      time: "8 mins ago",
    },
    {
      id: 4,
      image: Avatar_17,
      name: "Mukesh Kumar",
      contents: "completed task",
      contents_2: "Patient and Doctor video conferencing",
      time: "12 mins ago",
    },
    {
      id: 5,
      image: Avatar_13,
      name: "Mukesh Kumar",
      contents: "added new task",
      contents_2: "Private chat module",
      time: "2 days ago",
    },
  ],
};

// Export the variable as the default export
export default notificationsData;
