import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import EmployeeContactPopup from "../../../components/modelpopup/EmployeeContactPopup";
import EmployeeBankDetailsPopup from "../../../components/modelpopup/EmployeeBankDetailsPopup";
import UpdateProfileModel from "../../../components/modelpopup/EmployeeUpdateModelPopup";
import EducationInfoPopup from "../../../components/modelpopup/EducationInfoPopup";
import EditEducationInfoPopup from "../../../components/modelpopup/EditEducationInfoPopup";
import ExperienceInfoPopup from "../../../components/modelpopup/ExperienceInfoPopup";
import EditExperienceInfoPopup from "../../../components/modelpopup/EditExperienceInfoPopup";
import FamilyInformationModal from "../../../components/modelpopup/FamilyInformationModal";
import EditFamilyInfoPopup from "../../../components/modelpopup/EditFamilyInfoPopup";
import { ListItem, ProjectDetails } from "./ProfileContent";
import EmployeeDocuments from "./EmployeeDocuments";
import moment from 'moment';
import _ from "lodash";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import DeleteConfirmModal from "../../../components/modelpopup/DeleteConfirmationModal";
const ProfileTab = ({ userData, onUpdate, reportingManagers }) => {
  const headers = getAuthHeaders();
  const employeeId = userData.id;
  const [primaryContactData, setPrimaryContactData] = useState([]);
  const [secondaryContactData, setSecondaryContactData] = useState([]);
  const [bankInformation, setBankInformation] = useState([]);
  const [familyInformation, setFamilyInformation] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [selectedFamilyData, setSelectedFamilyData] = useState([]);
  const [deleteUrl, setDeleteUrl] = useState([]);
  const [itemName, setItemName] = useState([]);

  const personalInfoData = [
    { id: 1, title: "Nationality", text: "Indian" },
    { id: 2, title: "Gender", text: _.startCase(userData.gender) },
    { id: 3, title: "Birthday", text: moment(userData.dob).format("D MMMM") },
    { id: 4, title: "Marital status", text: _.startCase(userData.marital_status) },
    { id: 5, title: "Blood Group", text: userData.blood_group },
  ];
  const bankInfoData = [

  ];
  const familyInfoData = [
    // {
    //   id: 1,
    //   name: "Leo",
    //   relationship: "Brother",
    //   dob: "Feb 16th, 2019",
    //   phone: "9876543210",
    // },
  ];

  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);

  const [isFamilyEditModalOpen, setFamilyEditModalOpen] = useState(false);
  const [selectedFamilyEntry, setSelectedFamilyEntry] = useState(null);

  const [isExperienceEditModalOpen, setExperienceEditModalOpen] = useState(false);
  const [selectedExperienceEntry, setSelectedExperienceEntry] = useState(null);

  // family action functions

  const handleFamilyEditClick = (entry) => {
    setSelectedFamilyEntry(entry);
    setFamilyEditModalOpen(true);
  };
  const handleFamilyDeleteClick = (entry) => {
    setDeleteUrl(
      `${getApiUrl()}/api/employees/${employeeId}/families/${entry.id}`
    );
    setItemName("Employee Family");
  };

  const handleFamilySaveChanges = (updatedEntry) => {
    // Ensure DOB is formatted as a string before saving
    const formattedEntry = {
      ...updatedEntry,
      dob: updatedEntry.dob ? moment(updatedEntry.dob).format("YYYY-MM-DD") : "",
    };

    setFamilyInformation((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === selectedFamilyEntry.id ? { ...formattedEntry, id: entry.id } : entry
      )
    );
  };
  // family action functions

  // Function to handle edit button click
  const handleEcucationEditClick = (entry) => {
    setSelectedEntry(entry);
    setEditModalOpen(true);
  };
  const handleEducationDeleteClick = (entry) => {
    setDeleteUrl(
      `${getApiUrl()}/api/employees/${employeeId}/educations/${entry.id}`
    );
    setItemName("Employee Education");
  };

  const handleEcucationSaveChanges = (updatedEntry) => {
    setEducationData((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === selectedEntry.id ? { ...updatedEntry, id: entry.id } : entry
      )
    );
  };

  /// experince
  const handleExperianceEditClick = (entry) => {
    setSelectedExperienceEntry(entry);
    setExperienceEditModalOpen(true);
  };
  const handleExperianceDeleteClick = (entry) => {
    setDeleteUrl(
      `${getApiUrl()}/api/employees/${employeeId}/experiences/${entry.id}`
    );
    setItemName("Employee Experience");
  };
  const handleExperienceSaveChanges = (updatedEntry) => {
    setExperienceData((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === selectedExperienceEntry.id ? { ...updatedEntry, id: entry.id } : entry
      )
    );
  };
  /// experince

  const handleEmergencyContactSubmit = (updatedPrimaryContact, updatedSecondaryContact) => {
    setPrimaryContactData(updatedPrimaryContact);
    setSecondaryContactData(updatedSecondaryContact);
    // Additional logic after updating contact data, e.g., closing modal
  };

  // Function to update bank information on bank form submit
  const handleBankInfoSubmit = (updatedBankInformation) => {
    setBankInformation((prevBankInformation) => ({
      ...prevBankInformation,
      ...updatedBankInformation,
    }));
    // Additional logic after updating contact data, e.g., closing modal
  };

  // Fetch employee contact data for reporting manager dropdown
  const fetchEmployeeContact = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/${employeeId}/contacts`,
        { headers }
      );
      const contacts = response.data.data;

      // Filter contacts based on type
      const primaryContact = contacts.find((contact) => contact.type === 'primary');
      const secondaryContact = contacts.find((contact) => contact.type === 'secondary');

      // Assign to state variables
      setPrimaryContactData(primaryContact);
      setSecondaryContactData(secondaryContact);
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch employee bank data
  const fetchEmployeeBank = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/${employeeId}/bank`,
        { headers }
      );
      const bankData = response.data.data[0];

      // Assign to state variables
      setBankInformation(bankData);
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch employee family data
  const fetchEmployeeFamily = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/${employeeId}/families`,
        { headers }
      );
  
      if (response.status === 204) {
        // No content returned, set family information to an empty array
        setFamilyInformation([]);
      } else {
        const familyData = response.data.data;
        if (familyData.length > 0) {
          // Assign to state variables
          setFamilyInformation(familyData);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchEmployeeEducation = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/${employeeId}/educations`,
        { headers }
      );
  
      if (response.status === 204) {
        // No content returned, set education data to an empty array
        setEducationData([]);
      } else {
        const educationInformation = response.data.data;
  
        if (educationInformation.length > 0) {
          // Assign to state variables
          setEducationData(educationInformation);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const fetchEmployeeExperience = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/${employeeId}/experiences`,
        { headers }
      );
  
      if (response.status === 204) {
        // No content returned, set experience data to an empty array
        setExperienceData([]);
      } else {
        const experienceInformation = response.data.data;
        if (experienceInformation.length > 0) {
          // Assign to state variables
          setExperienceData(experienceInformation);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect to fetch initial data
  useEffect(() => {
    fetchEmployeeContact();
    fetchEmployeeBank();
    fetchEmployeeFamily();
    fetchEmployeeEducation();
    fetchEmployeeExperience();
  }, [employeeId]);

  // Handle delete operation
  const handleDelete = async (type) => {
    try {
      const response = await axios.delete(`${getApiUrl()}/api/employees/${employeeId}/contacts/${type}`, { headers }); // Pass type as part of the URL or request body      
      if (type === 'primary') {
        setPrimaryContactData({});
      } else {
        setSecondaryContactData({});
      }

      // Optionally, you can perform additional actions after successful deletion (e.g., update state, show a message)
    } catch (error) {
      console.error('Error deleting:', error);
      // Handle error state or show an error message
    }
  };



  // Handle submit success by re-fetching the updated data
  const handleSubmitSuccess = async () => {
    await Promise.all([
      fetchEmployeeContact(),
      fetchEmployeeBank(),
      fetchEmployeeFamily(),
      fetchEmployeeEducation(),
      fetchEmployeeExperience(),
    ]);
    setItemName([]);
  };

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Personal Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_employee"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {personalInfoData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">

                  <div className="col-md-12 ">
                    <h3 className="card-title">
                      Emergency Contact{" "}
                      {/* {Object.keys(primaryContactData ?? {}).length > 0 && Object.keys(secondaryContactData ?? {}).length > 0 && ( */}
                      <Link
                        to="#"
                        className="edit-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#emergency_contact_modal"
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                      {/* )} */}
                    </h3>
                    {primaryContactData && Object.keys(primaryContactData).length > 0 && (
                      <>
                        <h5 className="primary-title mb-2">
                          Primary {" "}
                          <Link
                            to="#"
                            className="edit-icon"
                            onClick={() => handleDelete('primary')}
                          >
                            <i className="fa fa-trash" />
                          </Link></h5>
                        <ul className="personal-info">
                          <li><div className="title">Name </div><div className="text">{primaryContactData.name}</div></li>
                          <li><div className="title">Relationship </div><div className="text">{primaryContactData.relationship}</div></li>
                          <li><div className="title"><i className="fa fa-phone" /> Contact </div><div className="text">  {primaryContactData.phone}</div></li>
                          {primaryContactData.type === 'primary' && primaryContactData.alternative_phone && (
                            <li><div className="title"><i className="fa fa-phone" />Alternative Contact </div> <div className="text">  {primaryContactData.alternative_phone}</div></li>
                          )}
                        </ul>
                      </>
                    )}
                    {secondaryContactData && Object.keys(secondaryContactData).length > 0 && (
                      <>
                        <hr />
                        <h4 className="primary-title mb-2">Secondary {" "}
                          <Link
                            to="#"
                            className="edit-icon"
                            onClick={() => handleDelete('secondary')}
                          >
                            <i className="fa fa-trash" />
                          </Link></h4>
                        <ul className="personal-info">
                          <li><div className="title">Name </div><div className="text">{secondaryContactData.name}</div></li>
                          <li><div className="title">Relationship </div><div className="text">{secondaryContactData.relationship}</div></li>
                          <li><div className="title"><i className="fa fa-phone" /> Contact  </div><div className="text">{secondaryContactData.phone}</div></li>
                          {secondaryContactData.type === 'secondary' && secondaryContactData.alternative_phone && (
                            <li><div className="title"><i className="fa fa-phone" /> Alternative Contact </div> <div className="text">{secondaryContactData.alternative_phone}</div></li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                  {Object.keys(primaryContactData ?? {}).length === 0 && Object.keys(secondaryContactData ?? {}).length === 0 && (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '75%' }}>
                      <button
                        type="button"
                        className="btn btn-soft-danger rounded-pill"
                        data-bs-toggle="modal"
                        data-bs-target="#emergency_contact_modal"
                      >
                        Add Emergency Contact
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">Bank information {" "}
                    {Object.keys(bankInformation ?? {}).length > 0 && (
                      <Link
                        to="#"
                        className="edit-icon"
                        data-bs-toggle="modal"
                        data-bs-target="#bank_data_modal"
                      >
                        <i className="fa fa-pencil" />
                      </Link>
                    )}
                  </h3>
                  {Object.keys(bankInformation ?? {}).length > 0 && (
                    <>
                      <ul className="personal-info">
                        <li> <div className="title">Bank Name </div><div className="text">{bankInformation.bank_name}</div> </li>
                        <li> <div className="title">Branch </div><div className="text">{bankInformation.branch_name}</div> </li>
                        <li> <div className="title">Account Holder Name </div><div className="text">{bankInformation.account_holder_name}</div> </li>
                        <li> <div className="title">Account Number </div><div className="text">{bankInformation.account_number}</div> </li>
                        <li> <div className="title">IFSC Code </div><div className="text">{bankInformation.ifsc_code}</div> </li>
                        {bankInformation.swift_code && (
                          <li> <div className="title">SWIFT Code </div><div className="text">{bankInformation.swift_code}</div> </li>
                        )}
                      </ul>
                    </>
                  )}
                  {Object.keys(bankInformation ?? {}).length === 0 && (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '50%' }}>
                      <button
                        type="button"
                        className="btn btn-soft-danger rounded-pill"
                        data-bs-toggle="modal"
                        data-bs-target="#bank_data_modal"
                      >
                        Add Bank Details
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Family Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#family_info_modal"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </h3>
                  <div className="table-responsive">

                    {familyInformation.length > 0 && (
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Relationship</th>
                            <th>Date of Birth</th>
                            <th>Phone</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {familyInformation.map((item) => (
                            <tr key={item.id}>
                              <td>{item.name}</td>
                              <td>{item.relationship}</td>
                              <td>{item.dob}</td>
                              <td>{item.phone}</td>
                              <td className="text-end">
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleFamilyDeleteClick(item)}

                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_modal"
                                >

                                  <i className="fa fa-trash" />
                                </Link>
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleFamilyEditClick(item)}
                                >
                                  <i className="fa fa-pencil" />
                                </Link>
                                {/* <div className="dropdown dropdown-action">
                                  <Link
                                    aria-expanded="false"
                                    data-bs-toggle="dropdown"
                                    className="action-icon dropdown-toggle"
                                    to="#"
                                  >
                                    <i className="material-icons">more_vert</i>
                                  </Link>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <Link to="#" onClick={() => handleFamilyEditClick("edit", item)} className="dropdown-item">
                                      <i className="fa fa-pencil m-r-5" /> Edit
                                    </Link>
                                    <Link 
                                      to="#"
                                      onClick={() => handleFamilyEditClick("delete", item)} 
                                      data-bs-toggle="modal"
                                      data-bs-target="#delete_modal"
                                      className="dropdown-item">
                                        <i className="fa fa-trash m-r-5" /> Delete
                                    </Link>
                                  </div>
                                </div> */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                    {familyInformation.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '50%' }}>
                        <button
                          type="button"
                          className="btn btn-soft-danger rounded-pill"
                          data-bs-toggle="modal"
                          data-bs-target="#family_info_modal"
                        >
                          Add Family Information
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#education_info"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </h3>
                  <div className="education-box">
                    {educationData.length > 0 && (
                      <ul className="education-list">
                        {educationData.map((item) => (
                          <li key={item.id}>
                            <div className="education-user">
                              <div className="before-circle" />
                            </div>
                            <div className="education-content">
                              <div className="timeline-content">
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleEducationDeleteClick(item)}

                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_modal"
                                >

                                  <i className="fa fa-trash" />
                                </Link>
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleEcucationEditClick(item)}
                                >
                                  <i className="fa fa-pencil" />
                                </Link>

                                <span className="name">{item.institution} {item.degree && ` - ${item.degree}`}</span>
                                <div>{item.subject && `${item.subject}`} {item.grade && ` ${item.grade}`}</div>
                                <span className="time">{moment(item.startDate).format("D MMMM Y")} {item.endDate && ` - ${moment(item.endDate).format("D MMMM Y")}`} </span>

                                {/* <span className="time">{item.startDate}</span> */}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {educationData.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '75%' }}>
                        <button
                          type="button"
                          className="btn btn-soft-danger rounded-pill"
                          data-bs-toggle="modal"
                          data-bs-target="#education_info"
                        >
                          Add Education Information
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    {experienceData.length > 0 && (
                      <ul className="experience-list">
                        {experienceData.map((item) => (
                          <li key={item.id}>
                            <div className="experience-user">
                              <div className="before-circle" />
                            </div>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleExperianceDeleteClick(item)}

                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_modal"
                                >

                                  <i className="fa fa-trash" />
                                </Link>
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() => handleExperianceEditClick(item)}
                                >
                                  <i className="fa fa-pencil" />
                                </Link>

                                <span className="name">{item.company} {item.location && ` -  ${item.location}`}</span>
                                <div>{item.position && `${item.position}`} <span className="time">{moment(item.period_from).format("D MMMM Y")} {item.period_from && ` - ${moment(item.period_from).format("D MMMM Y")}`} </span></div>


                                {/* <span className="time">{item.startDate}</span> */}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {experienceData.length === 0 && (
                      <div className="d-flex justify-content-center align-items-center" style={{ height: '50%' }}>
                        <button
                          type="button"
                          className="btn btn-soft-danger rounded-pill"
                          data-bs-toggle="modal"
                          data-bs-target="#experience_info"
                        >
                          Add Experience Information
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
          </div>
        </div>
        <EmployeeDocuments />
        <ProjectDetails />
        {/* Bank Statutory Tab */}

        {/* Bank Statutory Tab */}
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      <PersonalInformationModelPopup />
      <EmployeeBankDetailsPopup bankInformation={bankInformation} employeeId={employeeId} onSubmitSuccess={handleBankInfoSubmit} />
      <EmployeeContactPopup
        primaryContactData={primaryContactData}
        secondaryContactData={secondaryContactData}
        employeeId={employeeId}
        onSubmitSuccess={handleEmergencyContactSubmit}
      // onUpdate={handleContactUpdate} // Pass update function to handle updates from popup
      />
      <FamilyInformationModal employeeId={employeeId} onSubmitSuccess={handleSubmitSuccess} />
      <UpdateProfileModel employeeData={userData} onUpdate={onUpdate} reportingManagers={reportingManagers} />
      <EducationInfoPopup employeeId={employeeId} onSubmitSuccess={handleSubmitSuccess} />
      <ExperienceInfoPopup employeeId={employeeId} onSubmitSuccess={handleSubmitSuccess} />
      {isEditModalOpen && selectedEntry && (
        <EditEducationInfoPopup
          employeeId={employeeId}
          entryData={selectedEntry}
          onSave={handleEcucationSaveChanges}
          onClose={() => setEditModalOpen(false)} // Close modal on close
        />
      )}
      {isFamilyEditModalOpen && selectedFamilyEntry && (
        <EditFamilyInfoPopup
          employeeId={employeeId}
          entryData={selectedFamilyEntry}
          onSave={handleFamilySaveChanges}
          onClose={() => setFamilyEditModalOpen(false)} // Close modal on close
        />
      )}

      {isExperienceEditModalOpen && selectedExperienceEntry && (
        <EditExperienceInfoPopup
          employeeId={employeeId}
          entryData={selectedExperienceEntry}
          onSave={handleExperienceSaveChanges}
          onClose={() => setExperienceEditModalOpen(false)} // Close modal on close
        />
      )}
      {itemName && itemName && (
        <DeleteConfirmModal
          method="delete" // Specify the HTTP method for deletion (e.g., 'delete', 'patch', etc.)
          url={deleteUrl} // Specify the API endpoint for deletion                
          itemName={itemName} // Specify the name of the item being deleted
          handleUpdateList={handleSubmitSuccess} // Specify a function to handle list update after deletion
        />
      )}
    </>
  );
};

export default ProfileTab;
