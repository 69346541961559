import { MedExpertIcon } from "../../Routes/ImagePath";

const contactsData = {
  Contacts: [
    {
      name: "Anil Choudhary",
      jobTitle: "Web Developer",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Rakesh Yadav",
      jobTitle: "Brand Manager",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Amit Bajaj",
      jobTitle: "Payroll Specialist",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Thirumalai Narayanan",
      jobTitle: "Operations Manager",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Anil Choudhary",
      jobTitle: "Team Leader",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Rahul Soni",
      jobTitle: "Executive Assistant",
      imageSrc: MedExpertIcon,
    },
    {
      name: "R Saravanan",
      jobTitle: "Operations Manager",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Lesley Grauer",
      jobTitle: "Team Leader",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Jeffery Lalor",
      jobTitle: "Team Leader",
      imageSrc: MedExpertIcon,
    },
    {
      name: "Loren Gatlin",
      jobTitle: "Payroll Specialist",
      imageSrc: MedExpertIcon,
    },
  ],
};

export default contactsData;
