import React, { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const DeleteConfirmationModal = ({ method, url, data = {}, itemName, handleUpdateList }) => {
    // console.log(itemName);
    const headers = getAuthHeaders();
    const [showButton, setShowButton] = useState(true);
    const titleText = `Are you sure you want to delete ?`    
    const [titlePropmpt, setTitlePropmpt] = useState(itemName ? titleText : "Are you sure you want to delete this?");
    const [titleIconClass, setTitleIconClass] = useState('bg-danger');
    const [titleIcon, setTitleIcon] = useState('fa fa-trash');
    const handleDeleteItem = async () => {
        setShowButton(false);
        try {
            const response = await axios({
                method: method,
                url: url,
                headers: headers,
            });
            // Check if deletion was successful      
            if (response.data.status === "success") {
                setTitlePropmpt(`"${itemName}" deleted successfully?`);
                setTitleIconClass("bg-success");
                setTitleIcon(`fa fa-check-circle`);
                // Refresh list after successful deletion
                setTimeout(() => {
                    setTimeout(() => {
                        setTitlePropmpt(titleText);
                        setShowButton(true);
                        setTitleIconClass("bg-danger");
                        setTitleIcon(`fa fa-trash`);
                    }, 2000);
                    document.getElementById("close-delete-modal").click(); // Close modal after success
                    handleUpdateList();
                }, 3000);
            }
        } catch (error) {
            console.error("Error:", error);
            setTitlePropmpt(`Failed to delete "${itemName}". Please try again.`);
            setTitleIconClass("bg-danger");
            setTitleIcon(`fa fa-times`);
            setTimeout(() => {
                setTitlePropmpt(titleText);
                setShowButton(true);
                setTitleIconClass("bg-danger");
                setTitleIcon(`fa fa-trash`);
            }, 3000);
            // Handle error states here
        }
    };

    return (
        <div>
            {/* Delete Modal */}
            <div className="modal custom-modal fade" id="delete_modal" role="dialog">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="success-message text-center">

                                <div className={`success-popup-icon ${titleIconClass}`}>
                                    <i className={titleIcon} />
                                </div>
                                <h3>{titlePropmpt}</h3>
                                <div className="col-lg-12 text-center form-wizard-button">
                                    <Link to="#" className="button btn-lights" data-bs-dismiss="modal" id="close-delete-modal">
                                        Close
                                    </Link>
                                    {showButton && (
                                        <Link to="#" className="btn btn-primary" onClick={handleDeleteItem}>
                                            Yes
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* /Delete Modal */}
        </div>
    );
};

export default DeleteConfirmationModal;
