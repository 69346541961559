/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unescaped-entities */

import React, { useState } from "react";


const Footer = (props) => {


  return (
    <div className="footer">
      <div className="text-center py-3">&copy;2024 Table Space Technologies</div>
    </div>
  );
};

export default Footer;
