import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { MedExpertIcon } from "../../../Routes/ImagePath";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeFilter from "../../../components/EmployeeListFilter";
import { getApiUrl, getAuthHeaders } from "../../../utils/AuthUtils";

const AllEmployee = () => {
  const headers = getAuthHeaders();
  const [employeeData, setEmployeeData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          getApiUrl() + "/api/employees/?limit=50&page=0",
          { headers }
        );
        setEmployeeData(response.data.data);
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Employees"
            title="Dashboard"
            subtitle="Employees"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees-list"
            // Linkname1="/employees"
          />
          <EmployeeFilter />

          <div className="row">
            {employeeData.map((employee) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={employee.id}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to="#" className="avatar">
                      <img src={MedExpertIcon} alt="" />
                    </Link>
                  </div>
                  <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_employee"
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                      >
                        <i className="fa-regular fa-trash-can m-r-5" /> Delete
                      </Link>
                    </div>
                  </div>
                  <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                    <Link to={`/profile/${employee.id}`}>
                      {employee.full_name}
                    </Link>
                  </h4>
                  <div className="small text-muted">{employee.designation}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <AllEmployeeAddPopup />
      {/* Delete Modal */}
      <DeleteModal Name="Delete Employee" />
      {/* Delete Modal */}
    </div>
  );
};

export default AllEmployee;
