import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import InputMask from "react-input-mask";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../utils/Misc";
// import Alert from "../../Alert";

const validationSchema = Yup.object().shape({
  branch_name: Yup.string().required("Branch name is required"),
  branch_code: Yup.string().required("Branch code is required"),
  branch_type: Yup.object().required("Please select branch type"),
  pan: Yup.string().required("PAN is required"),
  // is_gst_applicable: Yup.object().required("Please select if gst is applicable"),
  // gstin: Yup
  //     .string()
  //     .when("is_gst_applicable", {
  //       is: 1,
  //       then: Yup.string().required("GSTIN is required")
  //     }),
  country: Yup.object().required("Please select country"),
  city: Yup.object().required("Please select city"),
  state: Yup.object().required("Please select state"),
  address: Yup.string().required("Address is required"),
  zip_code: Yup.string().required("zip_code is required"),
  phone_number: Yup.string().required("Phone number is required"),
  status: Yup.object().required("Please select status"),
});

const AddEntityBranch = (props) => {
  const headers = getAuthHeaders();
  const [gstApplicable, setGstApplicable] = useState(1);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [formData, setFormData] = useState({
    branch_name: "",
    branch_code: "",
    branch_type: null,
    pan: "",
    tan: "",
    is_gst_applicable: 1,
    gstin: "",
    city: null,
    state: null,
    country: null,
    address: "",
    zip_code: "",
    phone_number: "",
    fax_number: "",
    status: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [countriesOptions, setCountriesOptions] = useState([]);
  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const branchTypeOptions = [
    {
      value: "head_office",
      label: "Regional Headquarter",
    },
    {
      value: "normal_branch",
      label: "Branch Office",
    },
  ];

  useEffect(() => {
    const fetchEntityData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/masters/entity-masters`,
          {
            headers,
          }
        );
        if (response) {
          setLocalStorageJSONData("branch_types", branchTypeOptions);
          setLocalStorageJSONData(
            "countries",
            response.data.data.countries.map((country) => {
              return { value: country.id, label: country.name };
            })
          );
          setLocalStorageJSONData("states", response.data.data.states);
          setLocalStorageJSONData("cities", response.data.data.cities);
          setLocalStorageJSONData("status", response.data.data.status);
        }
        setCountriesOptions(getLocalStorageJSONData("countries"));
        setStatesOptions(
          getLocalStorageJSONData("states").filter(
            (state) =>
              formData.country === state.country_id
          ).map((state) => {
            return { value: state.id, label: state.name };
          })
        );
        setCitiesOptions(
          getLocalStorageJSONData("cities").filter(
            (city) =>
              formData.state === city.state_id
          ).map((city) => {
            return { value: city.id, label: city.name };
          })
        );
        setStatusOptions(getLocalStorageJSONData("status"));
      } catch (err) {
        // setError(err.message);
        // setLoading(false);
      }
    };

    fetchEntityData();
    reset(formData);
  }, []);

  const onSubmitEntityBranch = async (data) => {
    const formmattedData = {
      branch_name: data.branch_name,
      branch_type: data.branch_type.value,
      branch_code: data.branch_code,
      is_gst_applicable: data.is_gst_applicable,
      gstin: data.is_gst_applicable === 1 ? data.gstin : "",
      pan: data.pan,
      tan: data.tan,
      address: data.address,
      country_id: data.country.value,
      state_id: data.state.value,
      city_id: data.city.value,
      zip_code: data.zip_code,
      phone_number: data.phone_number,
      fax_number: data.fax_number,
      status: data.status.value,
    };

    try {
      const response = await axios.post(
        `${getApiUrl()}/api/entities/${props.entity.id}/branches/create`,
        formmattedData,
        {
          headers,
        }
      );

      // Reset form after successful submission
      setFormData({ ...formData });
      reset(formData);
      setGstApplicable(0);
      document.getElementById("close-modal-entity-branch").click();
      document.getElementById("success_btn_branch").click();
      props.handleUpdateList();
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        }
        setErrorsMessage(errorData);
        setShowErrors(true);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
    // FieldsetTwo();
  };

  return (
    <div>
      {/* Add Branch */}
      <div
        className="modal custom-modal fade modal-padding"
        id="add_entity_branch"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Add New Branch - {props.entity.entity_name}
              </h5>
              <button
                type="button"
                id="close-modal-entity-branch"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                <form
                  onSubmit={handleSubmit(onSubmitEntityBranch)}
                  id="add_branch_form"
                >
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Branch Name <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="branch_name"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.branch_name ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.branch_name?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Branch Type <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="branch_type"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.branch_type ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={branchTypeOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.branch_type?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Branch Code <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="branch_code"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.branch_code ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxLength={10}
                                onChange={(e) => {
                                  setValue(
                                    "branch_code",
                                    e.target.value.toUpperCase()
                                  );
                                }}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.branch_code?.message}{" "}
                          </span>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <label className="col-lg-4 col-form-label">
                          Is GST Applicable?
                        </label>
                        <div className="col-lg-8">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_gst_applicable"
                              id="is_gst_applicable_yes"
                              value="1"
                              checked={gstApplicable === 1}
                              onChange={(e) => {
                                // handleChange(
                                //   "is_gst_applicable",
                                //   e.target.value
                                // )
                                setValue("is_gst_applicable", 1);
                                setGstApplicable(1);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="is_gst_applicable_yes"
                            >
                              Yes
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="is_gst_applicable"
                              id="is_gst_applicable_no"
                              value="0"
                              defaultChecked
                              checked={gstApplicable === 0}
                              onChange={(e) => {
                                // handleChange(
                                //   "is_gst_applicable",
                                //   e.target.value
                                // );
                                setValue("is_gst_applicable", 0);
                                setGstApplicable(0);
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="is_gst_applicable_no"
                            >
                              No
                            </label>
                          </div>
                        </div>
                      </div>
                      {gstApplicable === 1 && (
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              GSTIN <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="gstin"
                              control={control}
                              render={({ field }) => (
                                <InputMask
                                  className={`form-control ${
                                    errors?.gstin ? "error-input" : ""
                                  }`}
                                  mask="99aaaaa9999a*Z*"
                                  onChange={(e) => {
                                    setValue(
                                      "gstin",
                                      e.target.value.toUpperCase()
                                    );
                                  }}
                                  value={field.value}
                                ></InputMask>
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.gstin?.message}{" "}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            PAN <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="pan"
                            control={control}
                            render={({ field }) => (
                              <InputMask
                                className={`form-control ${
                                  errors?.pan ? "error-input" : ""
                                }`}
                                mask="aaaaa9999a"
                                onChange={(e) => {
                                  setValue("pan", e.target.value.toUpperCase());
                                }}
                                value={field.value}
                              ></InputMask>
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.pan?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">TAN</label>
                          <Controller
                            name="tan"
                            control={control}
                            render={({ field }) => (
                              <InputMask
                                className={`form-control`}
                                mask="aaaa99999a"
                                onChange={(e) => {
                                  setValue("tan", e.target.value.toUpperCase());
                                }}
                                value={field.value}
                              ></InputMask>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Address
                            <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="address"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.address ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.address?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Country <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="country"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.country ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={(selected) => {
                                  setValue("state", null);
                                  setValue("city", null);
                                  setStatesOptions(
                                    getLocalStorageJSONData("states")
                                      .filter(
                                        (state) =>
                                          selected && selected.value === state.country_id
                                      )
                                      .map((state) => ({
                                        value: state.id,
                                        label: state.name,
                                      }))
                                  );
                                  field.onChange(selected);
                                }}
                                value={field.value}
                                options={countriesOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.country?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            State <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.state ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={(selected) => {
                                  setValue("city", null);
                                  setCitiesOptions(
                                    getLocalStorageJSONData("cities")
                                      .filter(
                                        (city) =>
                                          selected && selected.value === city.state_id
                                      )
                                      .map((city) => ({
                                        value: city.id,
                                        label: city.name,
                                      }))
                                  );
                                  field.onChange(selected);
                                }}
                                value={field.value}
                                options={statesOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.state?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            City <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.city ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={citiesOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.city?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Zipcode <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="zip_code"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.zip_code ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={7}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.zip_code?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Phone Number <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="phone_number"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.phone_number ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={15}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.phone_number?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Fax</label>
                          <Controller
                            name="fax_number"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control`}
                                type="text"
                                defaultValue=""
                                maxlength={15}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={statusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.status?.message}{" "}
                          </span>
                        </div>
                      </div>
                      {/* {showErrors && (
                          <>
                            <div className="col-sm-12">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )} */}
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights reset-btn"
                          type="reset"
                        >
                          Reset
                        </button>
                        {/* <button
                            className="btn btn-primary wizard-next-btn"
                            type="submit"
                          >
                            Save &amp; Next
                          </button> */}
                        <button
                          className="btn btn-primary"
                          type="submit"
                          form="add_branch_form"
                        >
                          Save
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_btn_branch"
                          data-bs-toggle="modal"
                          data-bs-target="#success_msg_branch"
                        >
                          Success
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Entity Branch */}
      {/* Success Branch */}
      <div
        className="modal custom-modal fade"
        id="success_msg_branch"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Entity Branch Created Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Branch */}
    </div>
  );
};

export default AddEntityBranch;
