import {
  MedExpertIcon
} from "../../Routes/ImagePath";

const defaultExport = {
  Salary: [
    {
      id: 1,
      name: "Anil Choudhary",
      position: "CEO",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-1203",
      email: "anil.choudhary@medexpertbilling.com",
      joiningDate: "18 Jun 2020",
      roles: "CEO",
      salary: "110300",
      annualCTC: "1323600",
    },
    {
      id: 2,
      name: "Rakesh Yadav",
      position: "Brand Manager",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-1234",
      email: "rakesh.yadav@medexpertbilling.com",
      joiningDate: "1 Dec 2023",
      roles: "Brand Manager",
      salary: "72000",
      annualCTC: "864000",
    },
    {
      id: 3,
      name: "Amit Bajaj",
      position: "Payroll Specialist",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-1224",
      email: "amit.bajaj@medexpertbilling.com",
      joiningDate: "4 Jul 2020",
      roles: "Payroll Specialist",
      salary: "48200",
      annualCTC: "578400",
    },
    {
      id: 4,
      name: "Thirumalai Narayanan",
      position: "Operations Manager",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-0121",
      email: "t.narayanan@medexpertbilling.com",
      joiningDate: "31 Jul 2022",
      roles: "Operations Manager",
      salary: "59698",
      annualCTC: "716376",
    },
    {
      id: 6,
      name: "Rahul Soni",
      position: "Executive Assistant",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-0006",
      email: "rahul.soni@medexpertbilling.com",
      joiningDate: "4 May 2023",
      roles: "Executive Assistant",
      salary: "45000",
      annualCTC: "540000",
    },
    {
      id: 7,
      name: "R Saravanan",
      position: "Operations Manager",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-0909",
      email: "r.saravanan@medexpertbilling.com",
      joiningDate: "2 Aug 2014",
      roles: "Operations Manager",
      salary: "38400",
      annualCTC: "460800",
    },
    {
      id: 8,
      name: "Kiran Joji",
      position: "Finance",
      avatar: MedExpertIcon,
      profileLink: "profile",
      employeeId: "MED-0726",
      email: "kiran.joji@medexpertbilling.com",
      joiningDate: "4 Jul 2022",
      roles: "Finance",
      salary: "92400",
      annualCTC: "1108800",
    },
  ],
};

export default defaultExport;
