import React, { useState, useEffect } from "react";
import Select from "react-select";
import axios from "axios";
import Alert from "../Alert";

const StatusUpdateModal = (props) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);

  useEffect(() => {
    setValidationError(false);
    setErrorAlert(false);
    if (props.employeeDetails.currentStatus) {
      setSelectedStatus(props.employeeDetails.currentStatus);
    }
  }, [props.employeeDetails.currentStatus]);

  const handleChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleStatusChange = async (e) => {
    e.preventDefault();
    if (!selectedStatus) {
      setValidationError(true);
      return;
    }
    try {
      await props.onUpdateStatus(
        props.employeeDetails.employeeId,
        selectedStatus
      );
      setSuccessAlert(true);
      setTimeout(() => {
        document.getElementById("close-employee-status-update").click();
        setSuccessAlert(false);
      }, 3000);
    } catch (error) {
      setErrorAlert(true);
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="modal custom-modal fade" id="update-status" role="dialog">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Update Status For {props.employeeDetails.employeeName}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Status:
                  </label>
                  <Select
                    options={props.statusValues}
                    value={props.statusValues.find(
                      (option) => option.value === selectedStatus
                    )}
                    onChange={handleChange}
                    styles={customStyles}
                  />
                  {validationError && (
                    <div className="text-danger">Select A Status</div>
                  )}
                </div>
              </form>
              {showSuccessAlert && (
                <div className="col-sm-12">
                  <Alert
                    message="Status has been updated successfully!!"
                    type="success"
                    close={false}
                  />
                </div>
              )}
              {showErrorAlert && (
                <div className="col-sm-12">
                  <Alert
                    message="Failed to update status!"
                    type="danger"
                    close={false}
                  />
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                id="close-employee-status-update"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleStatusChange}
              >
                Change Status
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusUpdateModal;
