import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import axios from "axios";

import { ts_logo } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import StatusUpdateEntityBranchModal from "./StatusUpdateEntityBranchModal";
import DeleteEntityBranchModal from "./DeleteEntityBranchModal";
import EditEntityBranchModal from "./EditEntityBranchModal";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import { getLocalStorageJSONData } from "../../../utils/Misc";

const EntityBranches = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const [entityBranchesData, setEntityBranchesData] = useState([]);
  const [entityBranchData, setEntityBranchData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const branchTypesOptions = getLocalStorageJSONData("branch_types");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");

  useImperativeHandle(ref, () => ({
    updateBranchesList() {
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() +
          `/api/entities/${props.entity.id}/branches`,
        {
          params: {
            filter: props.filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let entityBranchData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      entityBranchData = entityBranchData.map((item) => {
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setEntityBranchesData(entityBranchData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    if (props.entity && props.entity.id) {
      fetchData();
    }
  }, [
    currentPage,
    pageSize,
    sortingColumn,
    sortingOrder,
    props.filterText,
    props.entity.id,
  ]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const [showFilter, setShowFilter] = useState(false);
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [focusedThree, setFocusedThree] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [inputValueThree, setInputValueThree] = useState("");

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branch_name",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <Link to="#" className="profile-split">
                {record.branch_name}
              </Link>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "branch_code",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "branch_type",
      render: (text, record) => (
        <>
          {
            branchTypesOptions &&
            branchTypesOptions.filter(
              (item) => item.value === record.branch_type
            )[0].label
          }
        </>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      sorter: true,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    {
      title: "PAN",
      dataIndex: "pan",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_branch"
                onClick={(e) => {
                  e.preventDefault();
                  setEntityBranchData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_entity_branch"
              onClick={(e) => {
                e.preventDefault();
                setEntityBranchData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_company_branch"
              onClick={(e) => {
                e.preventDefault();
                setEntityBranchData(record);
                // setEntityData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const optionSort = [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "Canada", label: "Canada" },
    { value: "China", label: "China" },
  ];

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const handleLabelClickTwo = () => {
    setFocusedTwo(true);
  };

  const handleInputBlurTwo = () => {
    if (inputValueTwo === "") {
      setFocusedTwo(false);
    }
  };
  const handleInputChangeTwo = (e) => {
    const value = e.target.value;
    setInputValueTwo(value);
    if (value !== "" && !focusedTwo) {
      setFocusedTwo(true);
    }
  };

  const handleLabelClickThree = () => {
    setFocusedThree(true);
  };

  const handleInputBlurThree = () => {
    if (inputValueThree === "") {
      setFocusedThree(false);
    }
  };
  const handleInputChangeThree = (e) => {
    const value = e.target.value;
    setInputValueThree(value);
    if (value !== "" && !focusedThree) {
      setFocusedThree(true);
    }
  };

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      <div className="table-responsive">
        <Table
          className="table-striped"
          columns={columns}
          dataSource={entityBranchesData}
          rowKey={(record) => record.id}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: totalRecords,
          }}
          onChange={handleTableChange}
        />
      </div>
      <EditEntityBranchModal
        entity={props.entity}
        entityBranch={entityBranchData}
        handleUpdateList={handleUpdateList}
      />
      <StatusUpdateEntityBranchModal
        entity={props.entity}
        entityBranch={entityBranchData}
        handleUpdateList={handleUpdateList}
        newStatus={newStatus}
      />
      <DeleteEntityBranchModal
        entity={props.entity}
        entityBranch={entityBranchData}
        handleUpdateList={handleUpdateList}
      />
    </>
  );
});

export default EntityBranches;
