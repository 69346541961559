/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getApiUrl } from "../../../utils/AuthUtils"; // Import the apiUrl  function
import { TSLogoDark } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import axios from "axios";
import Footer from "../../layout/Footer";
// import { login } from "../../../user";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address")
    .max(255, "Email must be at most 255 characters"),
});

const CandidateLogin = () => {
  localStorage.clear();
  const {hash} = useParams();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");

  const onSubmit = (data) => {
    axios
      .post(getApiUrl() + "/api/candidate-login", {
        email: data.email,
        hash: hash,
      })
      .then(function (response) {
        if (response.status === 200) {
          const success = response.data.success;
          if (success === 1) {
            const userData = response.data.data;
            const token = response.data.token;
            localStorage.setItem("candidateData", JSON.stringify(userData));
            localStorage.setItem("loginUrl", "/candidate-login/"+hash);
            localStorage.setItem("token", token);
            localStorage.setItem("colorschema", "blue");
            localStorage.setItem("layout", "vertical");
            localStorage.setItem("layoutwidth", "fluid");
            localStorage.setItem("layoutpos", "fixed");
            localStorage.setItem("topbartheme", "light");
            localStorage.setItem("layoutSized", "lg");
            localStorage.setItem("layoutStyling", "default");
            localStorage.setItem("layoutSidebarStyle", "light");
            dispatch(login(userData));
            const redirectPath = localStorage.getItem("redirect_path");
            if (redirectPath && redirectPath !== "") {
              navigate(redirectPath);
            } else {
              navigate("/upload-candidate-documents");
            }
          } else {
            setLoginErrorMessage(response.data.data);
            setLoginError(true); // Email is not registered
            navigate("/candidate-login/"+hash);
            setTimeout(() => {
              setLoginErrorMessage("");
              setLoginError(false);
            }, 10000);
          }
        }
      })
      .catch(function (error) {
        setLoginErrorMessage(
          "Unable to connect at the moment, please try again."
        );
        setLoginError(true); // Email is not registered
        setTimeout(() => {
          setLoginErrorMessage("");
          setLoginError(false);
        }, 10000);
        navigate("/candidate-login/"+hash);
      });

  };

  useEffect(() => {
    setValue("email", "");
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* <Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                  <img
                    src={TSLogoDark}
                    alt="Logo"
                  />
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h3 className="account-title">Candidate Login</h3>
                  <p className="account-subtitle">Enter your email address to validate</p>
                  {/* Account Form */}
                  <div>
                    {loginError === true && (
                      <div className="alert alert-danger" role="alert">
                        {loginErrorMessage}
                      </div>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                    <div className="account-footer">
                    </div>
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateLogin;
