import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import DatePicker from "react-datepicker";
import moment from "moment";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
import Alert from "../Alert";

const EditEducationInfoPopup = ({ employeeId, entryData, onSave, onClose }) => {
    // State for a single education entry
    const [entry, setEntry] = useState({
        institution: entryData.institution || "",
        subject: entryData.subject || "",
        start_date: entryData.start_date ? new Date(entryData.start_date) : null,
        completion_date: entryData.completion_date
          ? new Date(entryData.completion_date)
          : null,
        degree: entryData.degree || "",
        grade: entryData.grade || "",
      });
      const headers = getAuthHeaders();
    // State for errors
    const [errors, setErrors] = useState({});
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);

    // Function to handle input change
    const handleInputChange = (field, value) => {
        setEntry({
            ...entry,
            [field]: value,
        });

        // Clear error when user starts typing
        setErrors({
            ...errors,
            [field]: "",
        });
    };

    // Function to validate the entry
    const validateEntry = () => {
        const entryErrors = {};

        // Validate Institution
        if (!entry.institution.trim()) {
            entryErrors.institution = "Institution is required";
        }

        // Validate Starting Date
        if (!entry.start_date) {
            entryErrors.start_date = "Starting Date is required";
        }

        // Validate Completion Date
        if (!entry.completion_date) {
            entryErrors.completion_date = "Completion Date is required";
        } else if (entry.start_date && entry.completion_date <= entry.start_date) {
            entryErrors.completion_date =
                "Completion Date must be after the start date.";
        }

        // Validate Degree
        if (!entry.degree.trim()) {
            entryErrors.degree = "Degree is required";
        }

        setErrors(entryErrors);
        return Object.keys(entryErrors).length === 0; // Return true if no errors
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();


        if (validateEntry()) {
            // Format the start and completion dates
            const formattedEntry = {
                ...entry,
                start_date: moment(entry.start_date).format("YYYY-MM-DD"),
                completion_date: moment(entry.completion_date).format("YYYY-MM-DD"),
              };
              console.log(formattedEntry);
            try {
                const response = await axios.put(
                    `${getApiUrl()}/api/employees/${employeeId}/educations/${entryData.id}`,
                    formattedEntry,
                    { headers }
                );
    
                setErrors({});
                setSuccessAlert(true);
                setTimeout(() => {
                    onSave(entry);
                    onClose()
                }, 3000);
            } catch (error) {
                setErrorAlert(true);
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data.errors;
                    const newErrors = {};
    
                    // Map error fields to their respective error messages
                    Object.keys(errorData).forEach((field) => {
                        const errorMessage = errorData[field][0];
                        newErrors[field] = errorMessage;
                    });
    
                    // Set errors state to display error messages
                    setErrors(newErrors);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error("Error:", error);
                    // You can show a generic error message to the user if needed
                }
            }
        } else {
            setErrorAlert(true);
        }

    };

    return (
        <div
            id="edit_education_info"
            className="modal custom-modal fade show"
            role="dialog"
            style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Education Information</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {showSuccessAlert && <Alert type="success" message="Education details submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting education details." />}
                        <form onSubmit={handleSubmit}>
                            <div className="form-scroll">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            Edit Education Information
                                        </h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                    <label className="col-form-label">
                                                        Institution <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={entry.institution}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "institution",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                    {errors.institution && (
                                                        <div className="text-danger">
                                                            {errors.institution}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Subject
                                                        </label>
                                                    <input
                                                        type="text"
                                                        value={entry.subject}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "subject",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Starting Date <span className="text-danger">*</span>
                                                        </label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={entry.start_date}
                                                            onChange={(date) =>
                                                                handleInputChange(
                                                                    "start_date",
                                                                    date
                                                                )
                                                            }
                                                            className="form-control floating datetimepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                        />
                                                    </div>
                                                    {errors.start_date && (
                                                        <div className="text-danger">
                                                            {errors.start_date}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Complete Date <span className="text-danger">*</span>
                                                        </label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={entry.completion_date}
                                                            onChange={(date) =>
                                                                handleInputChange(
                                                                    "completion_date",
                                                                    date
                                                                )
                                                            }
                                                            className="form-control floating datetimepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                        />
                                                    </div>
                                                    {errors.completion_date && (
                                                        <div className="text-danger">
                                                            {errors.completion_date}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Degree <span className="text-danger">*</span>
                                                        </label>
                                                    <input
                                                        type="text"
                                                        value={entry.degree}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "degree",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                    {errors.degree && (
                                                        <div className="text-danger">
                                                            {errors.degree}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Grade
                                                        </label>
                                                    <input
                                                        type="text"
                                                        value={entry.grade}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "grade",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditEducationInfoPopup;
