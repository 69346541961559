import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

/**
 * A reusable search filter component for React applications.
 * 
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.handleSearchTextChange - The function to call when the input changes.
 * @param {Function} props.onSearchButtonClick - The function to call when the search button is clicked.
 * @param {string} props.placeholder - The placeholder text for the search input.
 * @param {string} props.inputClassName - Additional class names for the input element.
 * @param {string} props.buttonClassName - Additional class names for the button element.
 * @param {string} props.containerClassName - Additional class names for the container element.
 * @param {string} props.searchValue - The current value of the search input.
 */
const SearchFilter = ({
  handleSearchTextChange,
  onSearchButtonClick,
  placeholder,
  inputClassName,
  buttonClassName,
  containerClassName,
  searchValue
}) => {
  return (
    <div className={`filter-section filter-bottom ${containerClassName}`}>
      <ul>
        <li>
          <div className="search-set">
            <div className="search-input">
              <Link
                to="#"
                onClick={onSearchButtonClick}
                className={`btn btn-searchset mt-1 ${buttonClassName}`}
                aria-label="Search"
              >
                <i className="las la-search" />
              </Link>
              <div className="dataTables_filter">
                <label>
                  <input
                    type="text"
                    className={`form-control floating ${inputClassName}`}
                    placeholder={placeholder}
                    onChange={handleSearchTextChange}
                    value={searchValue}
                    aria-label="Search input"
                  />
                </label>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

SearchFilter.propTypes = {
  handleSearchTextChange: PropTypes.func.isRequired,
  onSearchButtonClick: PropTypes.func,
  placeholder: PropTypes.string,
  inputClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  searchValue: PropTypes.string
};

SearchFilter.defaultProps = {
  placeholder: 'Search',
  inputClassName: '',
  buttonClassName: '',
  containerClassName: '',
  onSearchButtonClick: () => {},
  searchValue: ''
};

export default SearchFilter;
