import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TSIcon,
  TSLogoLight,
} from "../../Routes/ImagePath";

import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";

const Header1 = (props) => {
  const [profile, setProfile] = useState(false);


  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };


  const handleProfile = () => {
    setProfile(!profile);
  };

  const candidateData = localStorage.getItem("candidateData");
  const Value = JSON.parse(candidateData);

  const ProfileName = Value?.first_name || '';
  const ProfilePic = Value?.profile_image?.file_path || '';

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/" className="logo">
          <img
            src={TSLogoLight}
            alt="img"
            style={{ padding: "15px", maxHeight: "60px" }}
          />
        </Link>
        <Link to="/" className="logo2">
          <img src={TSIcon} width={40} height={40} alt="img" />
        </Link>
      </div>
      {/* /Logo */}
      {/* <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
              ? "none"
              : "",
        }}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link> */}
      {/* Header Title */}
      <div className="page-title-box">
        <h3>Advantage</h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
          >
            {" "}
            <span className="user-img me-1 p-1">
              <img src={ProfilePic} alt="img" />
              <span className="status online" />
            </span>
            <span>{ProfileName ? `${ProfileName}` : "Candidate"}</span>
          </Link>
          <div
            className={`dropdown-menu dropdown-menu-end ${
              profile ? "show" : ""
            }`}
          >
            <Link className="dropdown-item" to="/logout">
              Logout
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          <Link className="dropdown-item" to="/login">
            Logout
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header1;
