import React, { useRef } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
// import UserFilter from "./UserFilter";
import UserGroupsTable from "./UserGroupsTable";
import AddUserGroupModal from "../../../../components/Administration/UserGroups/AddUserGroupModal";
import Footer from "../../../layout/Footer";

const UserGroups = () => {
  const userGroupRef = useRef();
  const updateUserGroupTableData = () => {
    userGroupRef.current.updateUserGroupsList();
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="User Groups "
          title="Dashboard"
          subtitle="User Groups"
          modal="#add_user_group"
          name="Add User Group"
        />
        {/* <UserFilter /> */}
        <UserGroupsTable ref={userGroupRef} />
        <AddUserGroupModal handleUpdateList={updateUserGroupTableData} />
      </div>
      <Footer />
    </div>
  );
};

export default UserGroups;
