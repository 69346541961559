import React, { useEffect, useState,
  forwardRef,
  useImperativeHandle, } from "react";
  import axios from "axios";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { getAuthHeaders, getApiUrl } from "../../../../../utils/AuthUtils";
import EditCandidateModal from "../../../../../components/Administration/Jobs/CandidatesModal/EditCandidateModal";
import StatusUpdateCandidateModal from "../../../../../components/Administration/Jobs/CandidatesModal/StatusUpdateCandidateModal";
import DeleteCandidateModal from "../../../../../components/Administration/Jobs/CandidatesModal/DeleteCandidateModal";
const CandidatesTable = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const [candidatesData, setCandidatesData] = useState([]);
  const [candidateData, setCandidateData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  useImperativeHandle(ref, () => ({
    updateCandidatesList() {
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() + `/api/candidates`,
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let candidatesData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      candidatesData = candidatesData.map((item) => {
        return {
          ...item,
          // image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setCandidatesData(candidatesData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "first_name",
      render: (text, record) => (
        <div className="table-avatar">
          <Link to={`/candidate-profile/${record.id}`} className="avatar">
            <img className="square-full" alt="" src={record.profile_image && record.profile_image.file_path} />
          </Link>
          <Link to={`/candidate-profile/${record.id}`}>{record.full_name}</Link>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile",
      sorter: true,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_candidate"
                onClick={(e) => {
                  e.preventDefault();
                  setCandidateData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_candidate"
              onClick={(e) => {
                e.preventDefault();
                setCandidateData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_candidate"
              onClick={(e) => {
                e.preventDefault();
                setCandidateData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
    <div className="filter-section filter-bottom">
        <ul>
          <li>
            <div className="search-set">
              <div className="search-input">
                <Link to="#" className="btn btn-searchset">
                  <i className="las la-search" />
                </Link>
                <div className="dataTables_filter">
                  <label>
                    {" "}
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search"
                      onChange={handleSearchTextChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              className="table-striped"
              columns={columns}
              style={{ overflowX: "auto" }}
              dataSource={candidatesData}
              rowKey={(record) => record.id}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
              }}
              onChange={handleTableChange}
            />
            <EditCandidateModal
              candidate={candidateData}
              handleUpdateList={handleUpdateList}
            />
            <StatusUpdateCandidateModal
              candidate={candidateData}
              handleUpdateList={handleUpdateList}
              newStatus={newStatus}
            />
            <DeleteCandidateModal
              candidate={candidateData}
              handleUpdateList={handleUpdateList}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default CandidatesTable;
