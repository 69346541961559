import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import { getLocalStorageJSONData, setLocalStorageJSONData } from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  nationality: Yup.string().required("Nationality is required"),
  iso_code2: Yup.string().required("ISO CODE 2 is required"),
  iso_code3: Yup.string().required("ISO CODE 3 is required"),
  isd_code: Yup.string().required("ISD CODE is required"),
  status: Yup.object().required("Please select status"),
});

const EditCountryModal = (props) => {
  const headers = getAuthHeaders();
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    nationality: "",
    iso_code2: "",
    iso_code3: "",
    isd_code: "",
    status: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/masters/status-masters`,
          {
            headers,
          }
        );
        if (response) {
          setLocalStorageJSONData("status", response.data.data.status);
        }
        setStatusOptions(getLocalStorageJSONData("status"));
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchCountryData();
  }, []);

  const onSubmitCountry = async (data) => {
    const formmattedData = {
      id: props.country.id,
      name: data.name,
      nationality: data.nationality,
      iso_code2: data.iso_code2,
      iso_code3: data.iso_code3,
      isd_code: data.isd_code,
      status: data.status.value,
    };

    try {
      const response = await axios.put(
        `${getApiUrl()}/api/masters/countries/update`,
        formmattedData,
        {
          headers,
        }
      );

      // Reset form after successful submission
      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        document.getElementById("close-edit-modal-country").click();
        document.getElementById("success_update_btn_country").click();
        props.handleUpdateList();
      }
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };
  useEffect(() => {
    const fetchCountryData = async () => {
      try {
        if (props.country) {
          const response = await axios.get(
            `${getApiUrl()}/api/masters/countries/${props.country.id}`,
            {
              headers,
            }
          );
          const editFormData = {
            ...formData,
            ...response.data.data,
            status: statusOptions.filter(
              (status) => status.value === response.data.data.status
            )[0],
          };
          reset(editFormData);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchCountryData();
  }, [props.country]);

  return (
    <div>
      {/* Edit Country */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_country"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Edit Country
              </h5>
              <button
                type="button"
                id="close-edit-modal-country"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                {loading && <PreLoader showLoader={loading} />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(onSubmitCountry)}
                    id="add_country_form"
                  >
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                             Name <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.name?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                          Nationality <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="nationality"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.nationality ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={100}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.nationality?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            ISO Code 2 <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="iso_code2"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.iso_code2 ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={2}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.iso_code2?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            ISO Code 3 <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="iso_code3"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.iso_code3 ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={3}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.iso_code3?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                          ISD Code <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="isd_code"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.isd_code ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={10}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.isd_code?.message}{" "}
                          </span>
                        </div>
                      </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="status"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.status ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={statusOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.status?.message}{" "}
                            </span>
                          </div>
                        </div>
                        {showErrors && (
                          <>
                            <div className="col-sm-12 mb-3">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )} 
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights"
                            type="button"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary d-none"
                            type="button"
                            id="success_update_btn_country"
                            data-bs-toggle="modal"
                            data-bs-target="#success_update_msg_country"
                          >
                            Success
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Country */}
      {/* Success Country */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_country"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Country Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Country */}
    </div>
  );
};

export default EditCountryModal;
