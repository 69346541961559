import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation, Navigate } from "react-router-dom";
import AppContainer from "../Appcontainer";
import store from "../../store";
import { getApiUrl, isAuthenticated, validateDomain } from "../../utils/AuthUtils"; // Import the authentication check function
import { Provider } from "react-redux";
import Login from "../../views/pages/Authentication/Login";
import Logout from "../../views/pages/Authentication/Logout";
import Register from "../../views/pages/Authentication/Register";
import Otp from "../../views/pages/Authentication/Otp";
import Error404 from "../../views/pages/Error/Error404";
import Error500 from "../../views/pages/Error/Error500";
import ChangePassword from "../../views/pages/Authentication/ChangePassword";
import ForgotPassword from "../../views/pages/Authentication/ForgotPassword";
import ComingSoon from "../../views/pages/Pages/ComingSoon";
import UnderManitenance from "../../views/pages/Pages/UnderManitenance";
import CandidateLogin from "../../views/pages/Authentication/CandidateLogin";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const AppRouter = () => {
  const [isValidDomain, setIsValidDomain] = useState(true);

  useEffect(() => {
    // Define an async function to handle domain validation
    const checkDomain = async () => {
      try {
        const isValid = await validateDomain(); // Await the result of validateDomain
        if (isValid) {
          setIsValidDomain(true);
          // Proceed with the application
        } else {
          setIsValidDomain(false);
          // Handle invalid domain case (e.g., redirect to error page)
        }
      } catch (error) {
        console.error("Error validating domain:", error);
        setIsValidDomain(false); // Handle error case by marking the domain as invalid
      }
    };

    // Call the async function inside useEffect
    checkDomain();

    // Set other localStorage values as per your original setup
    localStorage.setItem("colorschema", "blue");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fluid");
    localStorage.setItem("layoutpos", "fixed");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "light");
  }, []);


  return (
    <div>
      <Provider store={store}>
        <BrowserRouter basename="">
          <ScrollToTop />
          {!isValidDomain ? (
            // Render the 404 error page if the domain is not valid
            <Routes>
              <Route path="/*" element={<Error404 />} />
            </Routes>
          ) : (
            // Render the rest of the routes if the domain is valid
            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Login />} />
              <Route path="/candidate-login/:hash" element={<CandidateLogin />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/register" element={<Register />} />
              <Route path="/otp" element={<Otp />} />
              <Route path="/error-404" element={<Error404 />} />
              <Route path="/error-500" element={<Error500 />} />
              <Route path="/coming-soon" element={<ComingSoon />} />
              <Route path="/under-maintenance" element={<UnderManitenance />} />

              {/* Protected Routes */}
              <Route
                path="/logout"
                element={
                  isAuthenticated() ? <Logout /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/change-password"
                element={
                  isAuthenticated() ? <ChangePassword /> : <Navigate to="/login" />
                }
              />
              <Route
                path="/*"
                element={
                  isAuthenticated()
                    ? (console.log("User is authenticated"), <AppContainer />)
                    : (console.log("User is not authenticated, redirecting to login"), <AppContainer />)
                }
              />

              {/* Catch-all Route */}
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          )}

        </BrowserRouter>
      </Provider>
    </div>
  );
};

export default AppRouter;