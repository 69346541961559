import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import InputMask from "react-input-mask";
import PreLoader from "../PreLoader";
import Alert from "../Alert";
import { getAuthHeaders, getApiUrl } from "../../utils/AuthUtils";
import { getLocalStorageJSONData } from "../../utils/Misc";

const validationSchema = Yup.object().shape({
  entity_type: Yup.object().required("Please select entity type"),
  company_name: Yup.string().required("Company name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  entity_size: Yup.object().required("Please select entity size"),
  address: Yup.string().required("Address is required"),
  country: Yup.object().required("Please select country"),
  city: Yup.object().required("Please select city"),
  state: Yup.object().required("Please select state"),
  zipcode: Yup.string().required("Zipcode is required"),
  phone_number: Yup.string().required("Phone number is required"),
  pan: Yup.string().required("PAN is required"),
  currency: Yup.object().required("Please select currency"),
  language: Yup.object().required("Please select language"),
  industry: Yup.object().required("Please select industry"),
  service_type: Yup.object().required("Please select service model"),
  status: Yup.object().required("Please select status"),
});

const EditEntityModal = (props) => {
  const navigate = useNavigate();
  const headers = getAuthHeaders();
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [entityTypesOptions, setEntityTypesOptions] = useState(
    getLocalStorageJSONData("entity_types")
  );
  const [entitySizesOptions, setEntitySizesOptions] = useState(
    getLocalStorageJSONData("entity_sizes")
  );
  const [countriesOptions, setCountriesOptions] = useState(
    getLocalStorageJSONData("countries")
  );
  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [entitiesOptions, setEntitiesOptions] = useState(
    getLocalStorageJSONData("entities")
  );
  const [currencyOptions, setCurrencyOptions] = useState(
    getLocalStorageJSONData("currencies")
  );
  const [languageOptions, setLanguageOptions] = useState(
    getLocalStorageJSONData("languages")
  );
  const [industryOptions, setIndustryOptions] = useState(
    getLocalStorageJSONData("industries")
  );
  const [serviceTypeOptions, setServiceTypeOptions] = useState(
    getLocalStorageJSONData("service_types")
  );
  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    company_name: "",
    entity_type: null,
    is_master_entity: 0,
    entity_size: null,
    address: "",
    phone_number: "",
    fax_number: "",
    state: null,
    city: null,
    zip_code: "",
    country: null,
    pan: "",
    tan: "",
    parent_entity: { value: null, label: "None" },
    status: { value: "active", label: "Active" },
    website: "",
    email: "",
    about: "",
    currency: null,
    language: null,
    industry: null,
    service_type: null,
  });
  const {
    reset,
    setError,
    setValue,
    control,
    handleSubmit,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [fieldOne, setFieldOne] = useState(true);
  const handleChange = (fieldName, value) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  const FieldsetOne = () => {
    setFieldOne(true);
  };

  useEffect(() => {
    FieldsetOne();
  }, []);

  const onSubmitCompany = async (data) => {
    const formmattedData = {
      entity_name: data.company_name,
      entity_type_id: data.entity_type.value,
      is_master_entity: formData.is_master_entity,
      entity_size: data.entity_size.value,
      address: data.address,
      phone_number: data.phone_number,
      fax_number: data.fax_number,
      state_id: data.state.value,
      city_id: data.city.value,
      zip_code: data.zipcode,
      country_id: data.country.value,
      pan: data.pan,
      tan: data.tan,
      parent_id:
        data.parent_entity && data.parent_entity.value !== ""
          ? data.parent_entity.value
          : null,
      status: data.status.value,
      website: data.website,
      email: data.email,
      about: data.about,
      currency_id: data.currency.value,
      language_id: data.language.value,
      industry_id: data.industry.value,
      service_type_id: data.service_type.value,
      id: props.companyId,
    };

    try {
      const response = await axios.put(
        `${getApiUrl()}/api/entities/update`,
        formmattedData,
        {
          headers,
        }
      );

      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        props.handleUpdateList();
        document.getElementById("close-edit-modal-entity").click();
        document.getElementById("success_update_btn").click();
      }
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status > 300) {
        const errorStatus = error.response.data.status;
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        }
        if (errorStatus === "error" && typeof errorData === "object") {
          const newErrors = [];

          // Map error fields to their respective error messages
          Object.keys(errorData).forEach((field) => {
            const errorMessage = errorData[field][0];
            newErrors.push(errorMessage);
          });
          errorData = newErrors.join(", ");
        }
        setErrorsMessage(errorData);
        setShowErrors(true);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
    // FieldsetTwo();
  };

  const fetchEntityData = async (companyId) => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/entities/${companyId}`,
        {
          headers,
        }
      );
      const selectedCountry = countriesOptions && countriesOptions.filter(
        (country) => country.label === response.data.data.country
      )[0];
      const selectedState = getLocalStorageJSONData("states") &&
      getLocalStorageJSONData("states")
        .filter(
          (state) => selectedCountry && selectedCountry.value === state.country_id
        )
        .map((state) => {
          return { value: state.id, label: state.name };
        })
        .filter((state) => state.label === response.data.data.state)[0];
      const editFormData = {
        ...formData,
        ...response.data.data,
        company_name: response.data.data.entity_name,
        zipcode: response.data.data.zip_code,
        entity_type: entityTypesOptions.filter(
          (entity_type) => entity_type.label === response.data.data.entity_type
        )[0],
        parent_entity: entitiesOptions.filter(
          (parent_entity) =>
            parent_entity.value === response.data.data.parent_id
        )[0],
        entity_size: entitySizesOptions.filter(
          (entity_size) => entity_size.value === response.data.data.entity_size
        )[0],
        country: selectedCountry,
        state:selectedState,
        city:
          getLocalStorageJSONData("cities") &&
          getLocalStorageJSONData("cities")
            .filter((city) => selectedState && selectedState.value === city.state_id)
            .map((state) => {
              return { value: state.id, label: state.name };
            })
            .filter((city) => city.label === response.data.data.city)[0],
        status: statusOptions.filter(
          (status) => status.value === response.data.data.status
        )[0],
        currency: currencyOptions.filter(
          (currency) => currency.value === response.data.data.currency_id
        )[0],
        language: languageOptions.filter(
          (language) => language.value === response.data.data.language_id
        )[0],
        industry: industryOptions.filter(
          (industry) => industry.value === response.data.data.industry_id
        )[0],
        service_type: serviceTypeOptions.filter(
          (service_type) =>
            service_type.value === response.data.data.service_type_id
        )[0],
      };
      setCountriesOptions(getLocalStorageJSONData("countries"));
      setStatesOptions(
        getLocalStorageJSONData("states")
          .filter(
            (state) =>
              editFormData.country &&
              editFormData.country.value === state.country_id
          )
          .map((state) => {
            return { value: state.id, label: state.name };
          })
      );
      setCitiesOptions(
        getLocalStorageJSONData("cities")
          .filter(
            (city) =>
              editFormData.state && editFormData.state.value === city.state_id
          )
          .map((city) => {
            return { value: city.id, label: city.name };
          })
      );
      setEntitiesOptions(
        getLocalStorageJSONData("entities").filter(
          (entity) => entity.value !== companyId
        )
      );
      reset(editFormData);

      setLoading(false);

      // setLoading(false);
    } catch (err) {
      // setError(err.message);
      // setLoading(false);
    }
  };
  useEffect(() => {
    if (props.companyId) {
      fetchEntityData(props.companyId);
    }
  }, [props.companyId]);

  return (
    <div>
      {/* Edit Company */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_company"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Edit Company</h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-edit-modal-entity"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <ul id="progressbar2" className="progress-bar-wizard shadow-sm">
                  <li className={fieldOne ? "active" : ""}>
                    <Link onClick={FieldsetOne} className="d-flex">
                      <span>
                        <i className="la la-user-tie" />
                      </span>
                      <div className="multi-step-info">
                        <h6>Entity</h6>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="add-info-fieldset">
                <fieldset
                  id="edit-first-field"
                  style={{ display: fieldOne ? "block" : "none" }}
                >
                  {loading && <PreLoader showLoader={loading} />}
                  {!loading && (
                    <form onSubmit={handleSubmit(onSubmitCompany)}>
                      <div className="contact-input-set">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Company Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="company_name"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.company_name ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={255}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.company_name?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Entity Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="entity_type"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.entity_type ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={entityTypesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.entity_type?.message}{" "}
                              </span>
                            </div>
                          </div>

                          {/* <div className="col-md-4">
                            <label className="col-lg-4 col-form-label">
                              Is Master Entity?
                            </label>
                            <div className="col-lg-8">
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="master_entity"
                                  id="master_entity_yes"
                                  value="1"
                                  checked={formData.is_master_entity == 1}
                                  onChange={(e) =>
                                    handleChange(
                                      "is_master_entity",
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="master_entity_yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="master_entity"
                                  id="master_entity_no"
                                  value="0"
                                  defaultChecked
                                  checked={formData.is_master_entity == 0}
                                  onChange={(e) =>
                                    handleChange(
                                      "is_master_entity",
                                      e.target.value
                                    )
                                  }
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="master_entity_no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Entity Code{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="entity_code"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.entity_code ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    readOnly={true}
                                    maxlength={10}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      const alphanumericRegex =
                                        /^[a-zA-Z0-9]*$/;
                                      // Check if the input value matches the alphanumeric pattern and is max 10 characters
                                      if (
                                        alphanumericRegex.test(inputValue) &&
                                        inputValue.length <= 10
                                      ) {
                                        setValue(
                                          "entity_code",
                                          e.target.value.toUpperCase(),
                                          { shouldDirty: true }
                                        );
                                      }
                                    }}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Email Address{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.email ? "error-input" : ""
                                    }`}
                                    type="email"
                                    defaultValue=""
                                    onChange={(e) => {
                                      setValue(
                                        "email",
                                        e.target.value.toLowerCase(),
                                        { shouldDirty: true }
                                      );
                                    }}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.email?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Parent Entity
                              </label>
                              <Controller
                                name="parent_entity"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.parent_entity ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={entitiesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Entity Size{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="entity_size"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.entity_size ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={entitySizesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.entity_size?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                PAN <span className="text-danger"> *</span>
                              </label>
                              <Controller
                                name="pan"
                                control={control}
                                render={({ field }) => (
                                  <InputMask
                                    className={`form-control ${
                                      errors?.pan ? "error-input" : ""
                                    }`}
                                    mask="aaaaa9999a"
                                    onChange={(e) => {
                                      setValue(
                                        "pan",
                                        e.target.value.toUpperCase(),
                                        {
                                          shouldDirty: true,
                                        }
                                      );
                                    }}
                                    value={field.value}
                                  ></InputMask>
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.pan?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">TAN</label>
                              <Controller
                                name="tan"
                                control={control}
                                render={({ field }) => (
                                  <InputMask
                                    className={`form-control`}
                                    mask="aaaa99999a"
                                    onChange={(e) => {
                                      setValue(
                                        "tan",
                                        e.target.value.toUpperCase(),
                                        { shouldDirty: true }
                                      );
                                    }}
                                    value={field.value}
                                  ></InputMask>
                                )}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Address
                                <span className="text-danger"> *</span>
                              </label>
                              <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.address ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={255}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.address?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Country <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="country"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.country ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={(selected) => {
                                      setValue("state", null);
                                      setValue("city", null);
                                      setStatesOptions(
                                        getLocalStorageJSONData("states")
                                          .filter(
                                            (state) =>
                                              selected &&
                                              selected.value ===
                                                state.country_id
                                          )
                                          .map((state) => ({
                                            value: state.id,
                                            label: state.name,
                                          }))
                                      );
                                      field.onChange(selected);
                                    }}
                                    value={field.value}
                                    options={countriesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.country?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                State <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="state"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.state ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={(selected) => {
                                      setValue("city", null);
                                      setCitiesOptions(
                                        getLocalStorageJSONData("cities")
                                          .filter(
                                            (city) =>
                                              selected &&
                                              selected.value === city.state_id
                                          )
                                          .map((city) => ({
                                            value: city.id,
                                            label: city.name,
                                          }))
                                      );
                                      field.onChange(selected);
                                    }}
                                    value={field.value}
                                    options={statesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.state?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                City <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="city"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.city ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={citiesOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.city?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Zipcode <span className="text-danger"> *</span>
                              </label>
                              <Controller
                                name="zipcode"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.zipcode ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={7}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.zipcode?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Phone Number{" "}
                                <span className="text-danger"> *</span>
                              </label>
                              <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.phone_number ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={15}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.phone_number?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">Fax</label>
                              <Controller
                                name="fax_number"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={15}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                            </div>
                          </div>

                          <div className="col-md-12">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                About Company
                              </label>
                              <Controller
                                name="about"
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    className="form-control"
                                    rows={5}
                                    defaultValue={""}
                                    onChange={field.onChange}
                                    value={field.value}
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">Website</label>
                              <Controller
                                name="website"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.website ? "error-input" : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    maxlength={255}
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Currency <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="currency"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.currency ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={currencyOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.currency?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Language <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="language"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.language ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={languageOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.language?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Industry <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="industry"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.industry ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={industryOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.industry?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Service Model{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="service_type"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.service_type ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={serviceTypeOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.service_type?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Status <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="status"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.status ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={statusOptions}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.status?.message}{" "}
                              </span>
                            </div>
                          </div>
                          {showErrors && (
                            <>
                              <div className="col-sm-12">
                                <Alert
                                  message={errorsMessage}
                                  type="danger"
                                  close={true}
                                />
                              </div>
                            </>
                          )}
                          <div className="col-lg-12 text-end form-wizard-button">
                            <button
                              className="button btn-lights"
                              type="button"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                            {/* <button
                              className="btn btn-primary wizard-next-btn"
                              type="submit"
                            >
                              Save &amp; Next
                            </button> */}

                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={!isDirty}
                              // data-bs-toggle="modal"
                              // data-bs-target="#success_msg"
                            >
                              Update
                            </button>
                            <button
                              className="btn btn-primary d-none"
                              type="button"
                              id="success_update_btn"
                              data-bs-toggle="modal"
                              data-bs-target="#success_update_msg"
                            >
                              Success
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  )}
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Company */}
      {/* Success Company */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Company Details Updated Successfully!!!</h3>
                <p>View the details of Company</p>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                  {props.companyId !== null ? (
                    <Link
                      to={`/company-details/${props.companyId}`}
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        document
                          .getElementById("close-modal-update-success")
                          .click();
                        navigate(`/company-details/${props.companyId}`);
                      }}
                    >
                      View Details
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Company */}
    </div>
  );
};

export default EditEntityModal;
