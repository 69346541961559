import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "../../../Alert";
import { getAuthHeaders, getApiUrl } from "../../../../utils/AuthUtils";

const CandidateDocumentPopup = ({
  candidateId,
  documentType,
  actionMode,
  documentDetails = {},
  onUpdateFunction,
}) => {
  const authHeaders = getAuthHeaders();
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [documentData, setDocumentData] = useState({});
  const [errors, setErrors] = useState({});
  const [files, setFiles] = useState([]);
  const clearForm = () => {
    const document_number = document.getElementsByName('document_number')[0];
    const issued_date = document.getElementsByName('issued_date')[0];
    const validity_date = document.getElementsByName('validity_date')[0];
    const comments = document.getElementsByName('comments')[0];
    const candidate_document_file = document.getElementsByName('candidate_document_file')[0];
    if (document_number) document_number.value = '';
    if (issued_date) issued_date.value = '';
    if (validity_date) validity_date.value = '';
    if (comments) comments.value = '';
    if (candidate_document_file) candidate_document_file.value = '';
  }
  useEffect(() => {
    const updatedDocumentDetails = { ...documentDetails, document_status: "active" };
    setDocumentData(updatedDocumentDetails);
    clearForm();
  }, [documentDetails]);

  const handleChange = (field, value) => {
    setDocumentData((prevDocumentData) => ({
      ...prevDocumentData,
      [field]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFiles(Array.from(e.target.files));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    if (actionMode === "file-upload") {
      if (files.length === 0 || files.length > 10) {
        setErrors({
          files:
            files.length === 0
              ? "At least one document file is required"
              : files.length > 10
              ? "You can upload up to 10 files only"
              : "",
        });
        return;
      }
    } else {
      if (files.length === 0 || files.length > 10) {
        setErrors({
          files:
            files.length === 0
              ? "At least one document file is required"
              : files.length > 10
              ? "You can upload up to 10 files only"
              : "",
        });
        return;
      }
    }
    const formData = new FormData();
    if (actionMode !== "file-upload") {
      formData.append("doc_type_id", documentType);
      formData.append("document_number", documentData.document_number);
      formData.append("issued_date", documentData.issued_date || "");
      formData.append("validity_date", documentData.validity_date || "");
      formData.append("comments", documentData.comments || "");
    }
    formData.append("document_status", documentData.document_status);
    formData.append("document_id", documentData.candidate_document_id || null);
    if (actionMode !== "edit") {
      for (let i = 0; i < files.length; i++) {
        formData.append("candidate_document_file", files[i]);
      }
    }
    try {
      let response;
      const headers = {
        "Content-Type": "multipart/form-data",
        ...authHeaders, // Merge the headers
      };
      if (actionMode === "edit") {
        response = await axios.put(
          `${getApiUrl()}/api/candidates/${candidateId}/documents/document-info`,
          formData,
          { headers }
        );
      } else if (actionMode === "file-upload") {
        response = await axios.put(
          `${getApiUrl()}/api/candidates/${candidateId}/documents/document-files`,
          formData,
          { headers }
        );
      } else {
        response = await axios.post(
          `${getApiUrl()}/api/candidates/${candidateId}/documents`,
          formData,
          { headers }
        );
      }

      if (response.status === 200 || response.status === 201) {
        setSuccessAlert(true);
        setTimeout(() => {
          document.getElementById("close-document-details-modal").click();
          setSuccessAlert(false);
          onUpdateFunction();
        }, 3000);
      }
    } catch (error) {
      setErrorAlert(true);
      setTimeout(() => setErrorAlert(false), 3000);
    }
  };
  return (
    <div
      id="candidate_document_modal"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Upload Document</h5>
            <button
              id="close-document-details-modal"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {showSuccessAlert && (
              <Alert
                type="success"
                message="Document submitted successfully!"
              />
            )}
            {showErrorAlert && (
              <Alert type="danger" message="Error submitting document." />
            )}
            <form onSubmit={handleSubmit}>
              <div className="card">
                <div className="card-body">
                  {actionMode !== "file-upload" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Document Number
                          </label>
                          <input
                            type="text"
                            name="document_number"
                            className="form-control"
                            value={documentData.document_number}
                            onChange={(e) =>
                              handleChange("document_number", e.target.value)
                            }
                          />
                          {errors.document_number && (
                            <div className="text-danger">
                              {errors.document_number}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Issued Date</label>
                          <input
                            type="date"
                            className="form-control"
                            name="issued_date"
                            value={documentData.issued_date}
                            onChange={(e) =>
                              handleChange("issued_date", e.target.value)
                            }
                          />
                          {errors.issued_date && (
                            <div className="text-danger">
                              {errors.issued_date}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Valid Till</label>
                          <input
                            type="date"
                            name="validity_date"
                            className="form-control"
                            value={documentData.validity_date}
                            onChange={(e) =>
                              handleChange("validity_date", e.target.value)
                            }
                          />
                          {errors.validity_date && (
                            <div className="text-danger">
                              {errors.validity_date}
                            </div>
                          )}
                        </div>
                      </div>
                      {actionMode !== "edit" && (
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Select Document{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="file"
                              name="candidate_document_file"
                              className="form-control"
                              multiple
                              onChange={handleFileChange}
                            />
                            {errors.files && (
                              <div className="text-danger">{errors.files}</div>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Comments</label>
                          <textarea
                            className="form-control"
                            name="comments"
                            value={documentData.comments || ""}
                            onChange={(e) =>
                              handleChange("comments", e.target.value)
                            }
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  )}
                  {actionMode === "file-upload" && (
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Select Document{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="file"
                            name="candidate_document_file"
                            className="form-control"
                            multiple
                            onChange={handleFileChange}
                          />
                          {errors.files && (
                            <div className="text-danger">{errors.files}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="submit-section">
                <button type="submit" className="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDocumentPopup;
