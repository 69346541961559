import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Alert from '../Alert';
import Select from "react-select";
import { statuses } from "../../utils/Constants"; // Importing constants
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const AllMasterModal = ({ module, masterData = {}, onSubmitSuccess, hideDescription = false }) => {
    const headers = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    const [formData, setFormData] = useState({
        name: '',
        description: '',
        status: 'active',
    });
    const [errors, setErrors] = useState({});
    const [documentCategories, setDocumentCategories] = useState([]);
    const moduleName = (module === 'documentCategories' ? 'Document Category' : module === 'documentTypes' ? 'Document Type' : module === 'serviceModels' ? 'service Model ' : module === 'languages' ? 'Language' : module === 'employment-types' ? 'Employment Type' :  module);
    const generateinitialFormData = async () => {
        if (masterData) {
            setFormData({
                name: masterData.name || '',
                description: masterData.description || '',
                status: masterData.status || 'active',
                ...getAdditionalFieldsDefaultValues(module, masterData)
            });
        }
    }
    useEffect(() => {
        generateinitialFormData()
    }, [masterData]);

    useEffect(() => {
        if (module === 'documentTypes') {
            fetchDocumentCategories();
        }
    }, [module]);

    const fetchDocumentCategories = async () => {
        try {
            const response = await axios.get(`${getApiUrl()}/api/masters/document-categories?limit=1000`, { headers });
            setDocumentCategories(response.data.data.map(cat => ({ value: cat.id, label: cat.name })));
        } catch (error) {
            console.error('Error fetching document categories:', error);
        }
    };

    const getAdditionalFieldsDefaultValues = (module, data) => {
        const defaultValues = {};
        if (module === 'documentCategories') {
            defaultValues.source = data.source || 'all';
        } else if (module === 'documentTypes') {
            defaultValues.source = data.source || 'all';
            defaultValues.document_category_id = data.document_category_id || '';
            defaultValues.is_mandatory = (data.is_mandatory === 1 ? '1' : '0');
        }
        return defaultValues;
    };

    const handleChange = (field, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        // if (!hideDescription && !formData.description) newErrors.description = 'Description is required';

        if (module === 'documentTypes' && !formData.document_category_id) {
            newErrors.document_category_id = 'Document category is required';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorAlert(false);
        if (!validateForm()) {
            return;
        }

        const moduleUrl = (module === 'documentCategories' ? 'document-categories' : module === 'documentTypes' ? 'document-types' : module === 'serviceModels' ? 'service-models' : module);
        
        // Prepare data for submission
        const submissionData = { ...formData };
        if (hideDescription) {
            delete submissionData.description; // Remove description from submission if hideDescription is true
        }

        try {
            let response;
            if (masterData.id) {
                response = await axios.put(`${getApiUrl()}/api/masters/${moduleUrl}/${masterData.id}`, submissionData, { headers });
            } else {
                response = await axios.post(`${getApiUrl()}/api/masters/${moduleUrl}`, submissionData, { headers });
            }
            setSuccessAlert(true);
            setErrorAlert(false);
            setTimeout(() => {
                document.getElementById(`close-${module}-modal`).click();
                setSuccessAlert(false);
                setFormData({
                    name: '',
                    description: '',
                    status: 'active',
                });
            }, 3000);
            onSubmitSuccess(submissionData);
        } catch (error) {
            setErrorAlert(true);
            if (error.response && error.response.status === 400) {
                const errorData = error.response.data.message;
                const newErrors = {};
                Object.keys(errorData).forEach((field) => {
                    const errorMessage = errorData[field][0];
                    newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
            } else {
                console.error("Error:", error);
            }
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
            color: state.isFocused ? "#fff" : "#000",
            "&:hover": {
                backgroundColor: "#ff9b44",
            },
        }),
    };

    const additionalFields = {
        documentCategories: [
            {
                name: 'source',
                label: 'Source',
                type: 'select',
                options: [
                    { value: '', label: 'Select Source' },
                    { value: 'organisation', label: 'Organisation' },
                    { value: 'employee', label: 'Employee' },
                    { value: 'candidate', label: 'Candidate' },
                    { value: 'all', label: 'All' }
                ]
            }
        ],
        documentTypes: [
            {
                name: 'document_category_id',
                label: 'Document Category',
                type: 'select',
                options: [{ value: '', label: 'Select Category' }, ...documentCategories],
            },
            {
                name: 'source',
                label: 'Source',
                type: 'select',
                options: [
                    { value: '', label: 'Select Source' },
                    { value: 'organisation', label: 'Organisation' },
                    { value: 'employee', label: 'Employee' },
                    { value: 'candidate', label: 'Candidate' },
                    { value: 'all', label: 'All' }
                ]
            },
            {
                name: 'is_mandatory',
                label: 'Is Mandatory',
                type: 'radio',
                options: [
                    { value: '1', label: 'Yes' },
                    { value: '0', label: 'No' }
                ]
            }
        ]
    };

    return (
        <div id="master-modal" className="modal custom-modal fade modal-padding" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header header-border justify-content-between p-0">
                        <h5 className="modal-title">{masterData.id ? 'Edit' : 'Add New'} {moduleName.charAt(0).toUpperCase() + moduleName.slice(1)}</h5>
                        <button
                            id={`close-${module}-modal`}
                            type="button"
                            className="btn-close position-static"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body p-0">
                        <div className="add-details-wizard">
                            <div className="row mb-3">
                            <div className="col-md-12">
                                <div
                                className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                                role="alert"
                                >
                                <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                                Fields marked with an{" "}
                                <span className="text-danger"> &nbsp; * &nbsp;</span> are
                                mandatory.
                                </div>
                            </div>
                            </div>
                        </div>
                        {showSuccessAlert && <Alert type="success" message={`${module.charAt(0).toUpperCase() + module.slice(1)} details submitted successfully!`} />}
                        {showErrorAlert && <Alert type="danger" message={`Error submitting ${module} details.`} />}
                        <div className="add-info-fieldset">
                            <form onSubmit={handleSubmit}>
                                <div className="contact-input-set">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Name <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={formData.name}
                                                    onChange={(e) => handleChange('name', e.target.value)}
                                                />
                                                {errors.name && <div className="text-danger">{errors.name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Status
                                                </label>
                                                <Select
                                                    options={statuses}
                                                    value={statuses.find(
                                                        (option) => option.value === formData.status
                                                    )}
                                                    onChange={(selectedOption) =>
                                                        handleChange("status", selectedOption.value)
                                                    }
                                                    styles={customStyles}
                                                />
                                            </div>
                                        </div>
                                        {!hideDescription && (
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Description 
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        value={formData.description}
                                                        onChange={(e) => handleChange('description', e.target.value)}
                                                        rows="4" // Sets the number of visible text lines for the textarea
                                                    />
                                                    {errors.description && <div className="text-danger">{errors.description}</div>}
                                                </div>
                                            </div>
                                        )}
                                        {additionalFields[module]?.map((field, index) => (
                                            !(module === 'documentTypes' && field.name === 'document_category_id' && masterData.id) && (
                                                <div className="col-md-6" key={index}>
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            {field.label} {field.required && <span className="text-danger">*</span>}
                                                        </label>
                                                        {field.type === 'select' && (
                                                            <Select
                                                                options={field.options}
                                                                value={field.options.find(
                                                                    (option) => option.value === formData[field.name]
                                                                )}
                                                                onChange={(selectedOption) =>
                                                                    handleChange(field.name, selectedOption.value)
                                                                }
                                                                styles={customStyles}
                                                            />
                                                        )}
                                                        {field.type === 'radio' && (
                                                            <div>
                                                                {field.options.map((option) => (
                                                                    <div key={option.value} className="form-check form-check-inline">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="radio"
                                                                            name={field.name}
                                                                            value={option.value}
                                                                            checked={formData[field.name] === option.value}
                                                                            onChange={(e) => handleChange(field.name, e.target.value)}
                                                                        />
                                                                        <label className="form-check-label">
                                                                            {option.label}
                                                                        </label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                        {errors[field.name] && <div className="text-danger">{errors[field.name]}</div>}
                                                    </div>
                                                </div>
                                            )
                                        ))}
                                        <div className="col-lg-12 text-end form-wizard-button">
                                            <button
                                                className="button btn-lights reset-btn"
                                                type="reset"
                                                onClick={() => {
                                                    generateinitialFormData();
                                                    setErrorAlert(false);
                                                    setErrors({});
                                                  }}
                                                >
                                                Reset
                                            </button>
                                            <button className="btn btn-primary" type="submit">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>  
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllMasterModal;