import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { ListItem, ProjectDetails } from "./ProfileContent";
import moment from "moment";
import _ from "lodash";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import DeleteConfirmModal from "../../../components/modelpopup/DeleteConfirmationModal";
import ExperienceInfoPopup from "../../../components/Administration/Jobs/CandidatesModal/ExperienceInfoPopup";
import EditExperienceInfoPopup from "../../../components/Administration/Jobs/CandidatesModal/EditExperienceInfoPopup";
import CandidateDocuments from "./CandidateDocuments";
import CandidateJobs from "./CandidateJobs";
import CandidateNotifications from "./CandidateNotifications";
const CandidateProfileTab = ({ userData, onUpdate }) => {
  const headers = getAuthHeaders();
  const candidateId = userData.id;
  const [experienceData, setExperienceData] = useState([]);
  const [deleteUrl, setDeleteUrl] = useState([]);
  const [itemName, setItemName] = useState([]);

  const personalInfoData = [
    { id: 1, title: "Mobile", text: userData.mobile },
    { id: 2, title: "Email", text: userData.email },
    {
      id: 3,
      title: "Gender",
      text: userData.gender ? _.startCase(userData.gender) : "",
    },
    {
      id: 4,
      title: "Birthday",
      text: userData.dob ? moment(userData.dob).format("D MMMM, YYYY") : "",
    },
    {
      id: 5,
      title: "Marital status",
      text: userData.marital_status ? _.startCase(userData.marital_status) : "",
    },
    { id: 6, title: "PAN", text: userData.pan },
  ];

  const [isExperienceEditModalOpen, setExperienceEditModalOpen] =
    useState(false);
  const [selectedExperienceEntry, setSelectedExperienceEntry] = useState(null);

  /// experince
  const handleExperianceEditClick = (entry) => {
    setSelectedExperienceEntry(entry);
    setExperienceEditModalOpen(true);
  };
  const handleExperianceDeleteClick = (entry) => {
    setDeleteUrl(
      `${getApiUrl()}/api/candidates/${candidateId}/experiences/${entry.id}`
    );
    setItemName("Candidate Experience");
  };
  const handleExperienceSaveChanges = (updatedEntry) => {
    setExperienceData((prevEntries) =>
      prevEntries.map((entry) =>
        entry.id === selectedExperienceEntry.id
          ? { ...updatedEntry, id: entry.id }
          : entry
      )
    );
  };
  /// experince

  const fetchCandidateExperience = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/candidates/${candidateId}/experiences`,
        { headers }
      );
      const educationInformation = response.data.data;
      if (educationInformation.length > 0) {
        // Assign to state variables
        setExperienceData(educationInformation);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect to fetch initial data
  useEffect(() => {
    fetchCandidateExperience();
  }, [candidateId]);

  // Handle submit success by re-fetching the updated data
  const handleSubmitSuccess = async () => {
    await Promise.all([
      fetchCandidateExperience(),
    ]);
    setItemName([]);
  };

  return (
    <>
      <div className="tab-content">
        <div
          id="candidate_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Personal Information
                  </h3>
                  <ul className="personal-info">
                    {personalInfoData.map((item, index) => {
                      return item.text !== "" && (
                        <ListItem
                          id={item.id}
                          key={index}
                          title={item.title}
                          text={item.text}
                        />
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-plus" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    {experienceData.length > 0 && (
                      <ul className="experience-list">
                        {experienceData.map((item) => (
                          <li key={item.id}>
                            <div className="experience-user">
                              <div className="before-circle" />
                            </div>
                            <div className="experience-content">
                              <div className="timeline-content">
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() =>
                                    handleExperianceDeleteClick(item)
                                  }
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_modal"
                                >
                                  <i className="fa fa-trash" />
                                </Link>
                                <Link
                                  to="#"
                                  className="edit-icon"
                                  onClick={() =>
                                    handleExperianceEditClick(item)
                                  }
                                >
                                  <i className="fa fa-pencil" />
                                </Link>

                                <span className="name">
                                  <strong>{item.company}{" "}
                                  {item.location && ` -  ${item.location}`}</strong>
                                </span>
                                <div>
                                  <i>{item.position && `${item.position}`}{" "}</i>
                                  <span className="time">
                                    {moment(item.period_from).format(
                                      "D MMMM Y"
                                    )}{" "}
                                    {item.period_to &&
                                      ` - ${moment(item.period_to).format(
                                        "D MMMM Y"
                                      )}`}{" "}
                                  </span>
                                </div>

                                {/* <span className="time">{item.startDate}</span> */}
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
                    {experienceData.length === 0 && (
                      <div
                        className="d-flex justify-content-center align-items-center"
                        style={{ height: "50%" }}
                      >
                        <button
                          type="button"
                          className="btn btn-soft-danger rounded-pill"
                          data-bs-toggle="modal"
                          data-bs-target="#experience_info"
                        >
                          Add Experience Information
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>
        </div>
        <CandidateDocuments />
        <CandidateJobs />
        <CandidateNotifications />
      </div>
      {/* Model Popup*/}
      <ExperienceInfoPopup
        candidateId={candidateId}
        onSubmitSuccess={handleSubmitSuccess}
      />

      {isExperienceEditModalOpen && selectedExperienceEntry && (
        <EditExperienceInfoPopup
          candidateId={candidateId}
          entryData={selectedExperienceEntry}
          onSave={handleExperienceSaveChanges}
          onClose={() => setExperienceEditModalOpen(false)} // Close modal on close
        />
      )}
      {itemName && itemName && (
        <DeleteConfirmModal
          method="delete" // Specify the HTTP method for deletion (e.g., 'delete', 'patch', etc.)
          url={deleteUrl} // Specify the API endpoint for deletion
          itemName={itemName} // Specify the name of the item being deleted
          handleUpdateList={handleSubmitSuccess} // Specify a function to handle list update after deletion
        />
      )}
    </>
  );
};

export default CandidateProfileTab;
