/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getApiUrl, getEntityDetails } from "../../../utils/AuthUtils"; // Import the apiUrl  function
import { TSLogoDark } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { useDispatch } from "react-redux";
import { login } from "../../../user";
import axios from "axios";
import Footer from "../../layout/Footer";
// import { login } from "../../../user";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address")
    .max(255, "Email must be at most 255 characters"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters"),
});

const Login = () => {
  localStorage.clear();
  const details = localStorage.getItem("loginDetails");
  const [userData, setUserData] = useState({});

  const loginData = JSON.parse(details);

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [companyData, setCompanyData] = useState(false);

  const onSubmit = (data) => {
    axios
      .post(getApiUrl() + "/api/login", {
        email: data.email,
        password: data.password,
      })
      .then(function (response) {
        if (response.status === 200) {
          const success = response.data.success;
          if (success === 1) {
            const userData = response.data.data;
            const token = response.data.token;
            const role = response.data.role;
            setUserData(userData);
            localStorage.setItem("userData", JSON.stringify(userData));
            localStorage.setItem("loginUrl", "/login");
            localStorage.setItem("token", token);
            localStorage.setItem("role", role);
            localStorage.setItem("colorschema", "blue");
            localStorage.setItem("layout", "vertical");
            localStorage.setItem("layoutwidth", "fluid");
            localStorage.setItem("layoutpos", "fixed");
            localStorage.setItem("topbartheme", "light");
            localStorage.setItem("layoutSized", "lg");
            localStorage.setItem("layoutStyling", "default");
            localStorage.setItem("layoutSidebarStyle", "light");
            dispatch(login(userData));
            const redirectPath = localStorage.getItem("redirect_path");
            if (redirectPath && redirectPath !== "") {
              navigate(redirectPath);
            } else {   
              if(role === 'super-admin'){
                navigate("/admin-dashboard");
              }else{
                navigate("/dashboard");
              }           
              
            }
          } else {
            setLoginErrorMessage(response.data.data);
            setLoginError(true); // Email is not registered
            navigate("/login");
            setTimeout(() => {
              setLoginErrorMessage("");
              setLoginError(false);
            }, 10000);
          }
        }
      })
      .catch(function (error) {
        setLoginErrorMessage(
          "Unable to connect at the moment, please try again."
        );
        setLoginError(true); // Email is not registered
        setTimeout(() => {
          setLoginErrorMessage("");
          setLoginError(false);
        }, 10000);
        navigate("/login");
      });

    // const currentUser = loginData?.find((item) => item?.email === data?.email);
    // if (!currentUser) {
    //   setLoginError(true); // Email is not registered
    //   navigate("/");
    // } else if (currentUser.password === data?.password) {
    //   setLoginError(false); // Email is registered, and password is correct
    //   const Value = {
    //     email: data?.email,
    //     password: data?.password,
    //   };
    //   dispatch(login(Value));
    // localStorage.setItem("credencial", JSON.stringify(Value));
      
    //   navigate("/dashboard");
    // } else {
    //   setLoginError(true); // Email is registered, but the password is incorrect
    //   navigate("/");
    // }
  };

  function refreshPage() {
    window.location.reload(false);
  }

  useEffect(() => {
    setValue("email", "");
    setValue("password", "");
    const entitySettings = async () => {
      try {
        const enityDetails = await getEntityDetails(); // Await the result of validateDomain   
        setCompanyData(enityDetails);     
      } catch (error) {
        console.error("Error validating domain:", error);
      }
    };
    // Call the async function inside useEffect
    entitySettings();
  }, []);

  const [eye, seteye] = useState(true);

  const onEyeClick = () => {
    seteye(!eye);
  };

  return (
    <div>
      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            {/* <Link to="/job-list" className="btn btn-primary apply-btn">
              Apply Job
            </Link> */}
            <div className="container">
              {/* Account Logo */}
              <div className="account-logo">
                <Link to="/dashboard">
                  <img
                    src={companyData && companyData.logo? companyData.logo : TSLogoDark}
                    
                    alt="MedExpert Billing and Consulting"
                  />
                </Link>
              </div>
              {/* /Account Logo */}
              <div className="account-box">
                <div className="account-wrapper">
                  <h2 className="account-title">
                    {(companyData && companyData.entity_name) && (                    
                      <h2 className="account-title">{companyData.entity_name}</h2>
                    )}
                  </h2>
                  {/* <h3 className="account-title">Login</h3> */}
                  <p className="account-subtitle">Access to our dashboard</p>
                  {/* Account Form */}
                  <div>
                    {loginError === true && (
                      <div className="alert alert-danger" role="alert">
                        {loginErrorMessage}
                      </div>
                    )}

                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="input-block mb-4">
                        <label className="col-form-label">Email Address</label>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.email ? "error-input" : ""
                              }`}
                              type="text"
                              defaultValue={localStorage.getItem("email")}
                              onChange={field.onChange}
                              value={field.value}
                              autoComplete="true"
                            />
                          )}
                        />

                        <span className="text-danger">
                          {" "}
                          {errors.email?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block mb-4">
                        <div className="row">
                          <div className="col">
                            <label className="col-form-label">Password</label>
                          </div>
                          <div className="col-auto">
                            <Link className="text-muted" to="/forgot-password">
                              Forgot password?
                            </Link>
                          </div>
                        </div>
                        <div style={{ position: "relative" }}>
                          <Controller
                            name="password"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.password ? "error-input" : ""
                                }`}
                                type={eye ? "password" : "text"}
                                defaultValue={localStorage.getItem("password")}
                                value={field.value}
                                onChange={field.onChange}
                                // autoComplete="true"
                              />
                            )}
                          />
                          <span
                            style={{
                              position: "absolute",
                              right: "5%",
                              top: "30%",
                            }}
                            onClick={onEyeClick}
                            className={`fa-solid ${
                              eye ? "fa-eye-slash" : "fa-eye"
                            } `}
                          />
                        </div>
                        <span className="text-danger">
                          {" "}
                          {errors.password?.message}{" "}
                        </span>
                      </div>
                      <div className="input-block text-center">
                        <button
                          className="btn btn-primary account-btn"
                          type="submit"
                        >
                          Login
                        </button>
                      </div>
                    </form>
                    <div className="account-footer">
                      {/* <p>
                        Don't have an account yet?{" "}
                        <Link to="/register">Register</Link>
                      </p> */}
                    </div>
                  </div>
                  {/* /Account Form */}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
