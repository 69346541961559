import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import moment from "moment";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Calendar,
  Edit,
  Mail,
  MoreVertical,
  Phone,
  Type,
  Link2,
} from "react-feather";
import Footer from "../../views/layout/Footer";
import DeleteEntityModal from "./DeleteEntityModal";
import EntityBranches from "./EntityBranches/EntityBranches";
import EditEntityModal from "./EditEntityModal";
import EditEntityConfigurationModal from "./EditEntityConfigurationModal";
import EditEntitySetupModal from "./EditEntitySetupModal";
import AddEntityBranch from "./EntityBranches/AddEntityBranchModal";
import { getAuthHeaders, getApiUrl } from "../../utils/AuthUtils";
import EntityDocuments from "./EntityDocuments/EntityDocuments";
import AddEntityDocument from "./EntityDocuments/AddEntityDocumentModal";
import EntitySmtpSettings from "./EntitySmtpSettings";

const ViewEntity = () => {
  const branchRef = useRef();
  const documentRef = useRef();
  const navigate = useNavigate();
  const headers = getAuthHeaders();
  const { id } = useParams();
  const [entityData, setEntityData] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState("");
  const [isSubdomain, setIsSubdomain] = useState(
    localStorage.getItem("isSubdomain") === "true"
  );
  const role = localStorage.getItem("role");
  let currentUserRole = "super-admin";
  if (role && role !== "undefined") {
    currentUserRole = role;
  }
  // const optionsSortValue = [
  //   { value: "sortDate", label: "Sort By Date" },
  //   { value: "ascending", label: "Ascending" },
  //   { value: "descending", label: "Descending" },
  // ];

  const fetchEntityData = async () => {
    try {
      const response = await axios.get(`${getApiUrl()}/api/entities/${id}`, {
        headers,
      });
      setEntityData(response.data.data);
      setLoading(false);
      setIsSubdomain(localStorage.getItem("isSubdomain"));
    } catch (err) {
      // setError(err.message);
      // setLoading(false);
    }
  };

  useEffect(() => {
    fetchEntityData();
  }, [localStorage.getItem("isSubdomain"),id]);

  if (loading) {
    return (
      <div className="card-body">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  const handleUpdateList = () => {
    document.getElementById("close-delete-modal-entity").click();
    navigate(`/companies`);
  };

  const handleUpdateBranchesList = () => {
    branchRef.current.updateBranchesList();
  };

  const handleUpdateDocumentsList = () => {
    documentRef.current.updateDocumentsList();
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };
  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}

        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="page-title">Company Profile</h3>
              {currentUserRole === "super-admin" && (
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/companies">Companies</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {entityData.entity_name} - {entityData.entity_code}
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* /Page Header */}
        <hr />
        <div className="row">
          {/* Contact User */}
          <div className="col-md-12">
            {/* <div className="contact-head">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <ul className="contact-breadcrumb">
                    <li>
                      <Link to="/companies">
                        <i className="las la-arrow-left" /> Companies
                      </Link>
                    </li>
                    <li>{entityData.entity_code}</li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="contact-wrap">
              <div className="contact-profile">
                <div className="avatar company-avatar">
                  <img
                    src={entityData.logo_file && entityData.logo_file.file_path}
                    alt="logo"
                  />
                </div>
                <div className="name-user">
                  <h4>{entityData.entity_name}</h4>
                  <p>
                    <i className="las la-map-marker" /> {entityData.address}
                  </p>
                  <p>
                    <i className="las la-map" /> {entityData.city},{" "}
                    {entityData.state}, {entityData.country} -{" "}
                    {entityData.zip_code}
                  </p>
                </div>
              </div>
              <div className="contacts-action">
                {currentUserRole === "tenant-admin" && (
                  <Link
                    to="#"
                    className="btn-icon"
                    data-bs-toggle="modal"
                    data-bs-target="#edit_company"
                  >
                    <Edit size={15} />
                  </Link>
                )}
                {currentUserRole === "super-admin" && (
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle marg-tp btn-icon"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <MoreVertical size={15} />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_company"
                        onClick={(e) => {
                          e.preventDefault();
                          if (entityData) {
                            setEntityData(entityData);
                          }
                        }}
                      >
                        Delete
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* /Contact User */}
          {/* Contact Sidebar */}
          <div className="col-xl-3">
            <div className="card contact-sidebar">
              <h5>Basic Information</h5>
              <ul className="basic-info">
                <li>
                  <span>
                    <Type size={15} />
                  </span>
                  <p>{entityData.entity_type}</p>
                </li>
                <li>
                  <span>
                    <Mail size={15} />
                  </span>
                  <p>{entityData.email}</p>
                </li>
                <li>
                  <span>
                    <Phone size={15} />
                  </span>
                  <p>{entityData.phone_number}</p>
                </li>
                <li>
                  <span>
                    <Calendar size={15} />
                  </span>
                  <p>
                    Created on{" "}
                    {moment(entityData.created_at).format(
                      "D MMMM YYYY, hh:mm A"
                    )}
                  </p>
                </li>
                {entityData.website && (
                  <li>
                    <span>
                      <Link2 size={15} />
                    </span>
                    <p>{entityData.website}</p>
                  </li>
                )}
              </ul>
              <h5>Other Information</h5>
              <ul className="other-info">
                <li>
                  <span className="other-title">Entity Size</span>
                  <span>{entityData.entity_size}</span>
                </li>
                {entityData.fax_number && (
                  <li>
                    <span className="other-title">Fax</span>
                    <span>{entityData.fax_number}</span>
                  </li>
                )}
                <li>
                  <span className="other-title">Language</span>
                  <span>{entityData.language}</span>
                </li>
                <li>
                  <span className="other-title">Currency</span>
                  <span>{entityData.currency}</span>
                </li>
                <li>
                  <span className="other-title">Industry</span>
                  <span>{entityData.industry}</span>
                </li>
                <li>
                  <span className="other-title">Service Model</span>
                  <span>{entityData.service_type}</span>
                </li>
                <li>
                  <span className="other-title">Last Modified</span>
                  <span>
                    {moment(entityData.modified_at).format(
                      "D MMMM YYYY, hh:mm A"
                    )}
                  </span>
                </li>
              </ul>
              <h5>Status</h5>
              <ul className="tag-info">
                {entityData.status === "active" ? (
                  <li>
                    <Link to="#" className="bg-success-light">
                      Active
                    </Link>
                  </li>
                ) : (
                  <li>
                    <Link to="#" className="bg-warning-light">
                      Inactive
                    </Link>
                  </li>
                )}
              </ul>
              {/* <div className="d-flex align-items-center justify-content-between flex-wrap">
                <h5>Company</h5>
                <Link
                  to="#"
                  className="com-add"
                  data-bs-toggle="modal"
                  data-bs-target="#add_contact"
                >
                  <i className="las la-plus-circle me-1" />
                  Add New
                </Link>
              </div> */}
              {/* <ul className="company-info com-info">
                <li>
                  <span>
                    <img src={company_icon_01} alt="companyicon" />
                  </span>
                  <div>
                    <h6>TS Energy</h6>
                    <p>
                      <i className="las la-star" />
                      3.5
                    </p>
                  </div>
                </li>
                <li>
                  <span>
                    <img src={company_icon_02} alt="companyicon" />
                  </span>
                  <div>
                    <h6>MedExpert Technologies</h6>
                    <p>
                      <i className="las la-star" />
                      4.2
                    </p>
                  </div>
                </li>
              </ul> */}
              {/* <h5>Social Profile</h5>
              <ul className="social-info">
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-youtube" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-instagram" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-whatsapp" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-pinterest" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-linkedin" />
                  </Link>
                </li>
              </ul> */}
              <h5>Settings</h5>
              <ul className="set-info">
                {currentUserRole === "tenant-admin" && (
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#edit_company"
                    >
                      <i className="fa-solid fa-pencil"></i>
                      Edit Company
                    </Link>
                  </li>
                )}

                {/* <li>
                  <Link to="#">
                    <i className="las la-upload" />
                    Share Company
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <Star />
                    Add to Favourite
                  </Link>
                </li> */}
                {currentUserRole === "super-admin" && (
                  <li>
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#delete_company"
                      onClick={(e) => {
                        e.preventDefault();
                        setEntityData(entityData);
                      }}
                    >
                      <i class="fa-solid fa-trash"></i>
                      Delete Company
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          {/* /Contact Sidebar */}
          {/* Contact Details */}
          <div className="col-xl-9">
            <div className="contact-tab-wrap">
              <ul className="contact-nav nav">
                <li>
                  <Link
                    to="#"
                    data-bs-toggle="tab"
                    data-bs-target="#configurations"
                    className="active"
                  >
                    <i className="las la-cog" />
                    Configurations
                  </Link>
                </li>
                {currentUserRole === "tenant-admin" && (
                  <>
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="tab"
                        data-bs-target="#branches"
                        className=""
                      >
                        <i className="fas fa-code-branch" />
                        Branches
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="tab"
                        data-bs-target="#documents"
                      >
                        <i className="las la-file" />
                        Documents
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        data-bs-toggle="tab"
                        data-bs-target="#email-config"
                      >
                        <i className="las la-envelope" />
                        Email Configuration
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
            {/* Tab Content */}
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* Configuration */}
                <div className="tab-pane active show" id="configurations">
                  <div className="view-header">
                    <h4>Configurations</h4>
                  </div>
                  <div className="files-activity">
                    <div className="files-wrap">
                      <div className="row align-items-center">
                        <div className="col-md-8">
                          <div className="file-info">
                            {currentUserRole === "tenant-admin" && (
                              <div className="row">
                                <div className="col-md-12 text-center">
                                  <div className="d-flex flex-wrap colors_parent">
                                    <div>
                                      <div className="card color-box mb-3">
                                        <div className="card-body p-3">
                                          <img
                                            src={
                                              entityData.logo_file &&
                                              entityData.logo_file.file_path
                                            }
                                            alt="Logo"
                                            className="card-img"
                                          />
                                        </div>
                                      </div>
                                      <p className="flex-1 text-sm my-1 pt-1">
                                        Logo
                                      </p>
                                      <p className="flex-0 text-sm"></p>
                                    </div>
                                    <div>
                                      <div
                                        className="color-box p-4"
                                        style={{
                                          background: `${entityData.primary_color_code}`,
                                        }}
                                      ></div>
                                      <p className="flex-1 text-sm my-1 pt-1">
                                        Primary
                                      </p>
                                      <p className="flex-0 text-sm">
                                        {entityData.primary_color_code}
                                      </p>
                                    </div>
                                    <div>
                                      <div
                                        className="color-box p-4"
                                        style={{
                                          background: `${entityData.secondary_color_code}`,
                                        }}
                                      ></div>
                                      <p className="flex-1 text-sm my-1 pt-1">
                                        Secondary
                                      </p>
                                      <p className="flex-0 text-sm">
                                        {entityData.secondary_color_code}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {currentUserRole === "super-admin" && (
                              <div className="row">
                                <div className="col-md-12">
                                  <ul className="other-info">
                                    <li className="activity-wrap">
                                      <span className="activity-icon bg-info">
                                        <i className="fa-solid fa-globe"></i>
                                      </span>
                                      <div className="activity-info">
                                        <h6>Domain</h6>
                                        <p>{entityData.domain}</p>
                                      </div>
                                    </li>
                                    <li className="activity-wrap">
                                      <span className="activity-icon bg-info">
                                        <i className="fa fa-server" />
                                      </span>
                                      <div className="activity-info">
                                        <h6>Database Name</h6>
                                        <p>{entityData.database_name}</p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 text-md-end">
                          {currentUserRole === "super-admin" &&
                            !entityData.setup_status && (
                              <ul className="file-action">
                                <li>
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit_company_setup"
                                    onClick={() => {
                                      setEntityData(entityData);
                                    }}
                                  >
                                    Complete Setup
                                  </Link>
                                </li>
                              </ul>
                            )}
                          {currentUserRole === "tenant-admin" && (
                            <ul className="file-action">
                              <li>
                                <Link
                                  to="#"
                                  className="btn btn-primary"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_company_configuration"
                                  onClick={() => {
                                    setEntityData(entityData);
                                  }}
                                >
                                  Edit Configuration
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Configuration */}
                {/* Branches */}
                <div className="tab-pane" id="branches">
                  <div className="view-header">
                    <h4>Branches</h4>
                    <ul>
                      <li>
                        <div className="search-set">
                          <div className="search-input">
                            <Link to="#" className="btn btn-searchset">
                              <i className="las la-search" />
                            </Link>
                            <div className="dataTables_filter">
                              <label>
                                {" "}
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search"
                                  onChange={handleSearchTextChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#add_entity_branch"
                        >
                          <i className="la la-plus-circle" /> Add Branch
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="contact-activity">
                    <EntityBranches
                      filterText={filterText}
                      entity={entityData}
                      ref={branchRef}
                    />
                  </div>
                </div>
                {/* /Branches */}
                {/* Documents */}
                <div className="tab-pane" id="documents">
                  <div className="view-header">
                    <h4>Organisation Documents</h4>
                    <ul>
                      <li>
                        <div className="search-set">
                          <div className="search-input">
                            <Link to="#" className="btn btn-searchset">
                              <i className="las la-search" />
                            </Link>
                            <div className="dataTables_filter">
                              <label>
                                {" "}
                                <input
                                  type="search"
                                  className="form-control form-control-sm"
                                  placeholder="Search"
                                  onChange={handleSearchTextChange}
                                />
                              </label>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li>
                        <Link
                          to="#"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#add_entity_document"
                        >
                          <i className="la la-plus-circle" /> Add Document
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="contact-activity">
                    <EntityDocuments
                      filterText={filterText}
                      entity={entityData}
                      ref={documentRef}
                    />
                  </div>
                </div>
                {/* /Documents */}
                {/* SMTP settings */}
                <div className="tab-pane" id="email-config">
                  <EntitySmtpSettings />
                </div>
                {/* /Documents */}
              </div>
            </div>
            {/* /Tab Content */}
          </div>
          {/* /Contact Details */}
        </div>
      </div>
      <Footer />

      <EditEntityConfigurationModal
        entity={entityData}
        handleUpdateData={fetchEntityData}
      />
      <EditEntitySetupModal
        entity={entityData}
        handleUpdateData={fetchEntityData}
      />
      <EditEntityModal
        companyId={entityData.id}
        handleUpdateList={handleUpdateList}
      />
      <DeleteEntityModal
        entity={entityData}
        handleUpdateList={handleUpdateList}
      />
      <AddEntityBranch
        entity={entityData}
        handleUpdateList={handleUpdateBranchesList}
      />
      <AddEntityDocument
        entity={entityData}
        handleUpdateList={handleUpdateDocumentsList}
      />
    </div>
  );
};

export default ViewEntity;
