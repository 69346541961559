import {
  MedExpertIcon,
  Avatar_03,
  Avatar_05,
  Avatar_08,
  Avatar_09,
} from "../../Routes/ImagePath";

const defaultExport = {
  message: [
    {
      id: "1",
      image: Avatar_09,
      name: "Kiran Joji",
      contents: "send a message to you",
      time: "12:28 AM",
    },
    {
      id: 2,
      image: MedExpertIcon,
      name: "Rakesh Yadav",
      contents: "send a message to you",
      time: "6 Mar",
    },
    {
      id: 3,
      image: Avatar_03,
      name: "Kiran Joji",
      contents: "send a message to you",
      time: "5 Mar",
    },
    {
      id: 4,
      image: Avatar_05,
      name: "Kiran Joji",
      contents: "send a message to you",
      time: "3 Mar",
    },
    {
      id: 5,
      image: Avatar_08,
      name: "Ravi S ",
      contents: "send a message to you",
      time: "27 Feb",
    },
  ],
};

export default defaultExport;
