import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import { getLocalStorageJSONData, setLocalStorageJSONData } from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  slug: Yup.string().required("Slug is required"),
  cost: Yup.string().required("Cost is required"),
  status: Yup.object().required("Please select status"),
});

const EditModuleModal = (props) => {
  const headers = getAuthHeaders();
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    title: "",
    slug: "",
    description: "",
    cost: "",
    status: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/masters/status-masters`,
          {
            headers,
          }
        );
        if (response) {
          setLocalStorageJSONData("status", response.data.data.status);
        }
        setStatusOptions(getLocalStorageJSONData("status"));
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchModuleData();
  }, []);

  const onSubmitModule = async (data) => {
    const formmattedData = {
      id: props.module.id,
      title: data.title,
      slug: data.slug,
      description: data.description,
      cost: data.cost,
      status: data.status.value,
    };

    try {
      const response = await axios.put(
        `${getApiUrl()}/api/masters/modules/update`,
        formmattedData,
        {
          headers,
        }
      );

      // Reset form after successful submission
      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        document.getElementById("close-edit-modal-module").click();
        document.getElementById("success_update_btn_module").click();
        props.handleUpdateList();
      }
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };
  useEffect(() => {
    const fetchModuleData = async () => {
      try {
        if (props.module) {
          const response = await axios.get(
            `${getApiUrl()}/api/masters/modules/${props.module.id}`,
            {
              headers,
            }
          );
          const editFormData = {
            ...formData,
            ...response.data.data,
            status: statusOptions.filter(
              (status) => status.value === response.data.data.status
            )[0],
          };
          reset(editFormData);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchModuleData();
  }, [props.module]);

  return (
    <div>
      {/* Edit Module */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_module"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Edit Module</h5>
              <button
                type="button"
                id="close-edit-modal-module"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                {loading && <PreLoader showLoader={loading} />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(onSubmitModule)}
                    id="add_module_form"
                  >
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Title <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="title"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.title ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.title?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Slug <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="slug"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.slug ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.slug?.message}{" "}
                            </span>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Cost <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="cost"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.cost ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.cost?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="status"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.status ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={statusOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.status?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Description
                            </label>
                            <Controller
                              name="description"
                              control={control}
                              render={({ field }) => (
                                <textarea
                                  className="form-control"
                                  rows={5}
                                  defaultValue={""}
                                  onChange={field.onChange}
                                  value={field.value}
                                />
                              )}
                            />
                          </div>
                        </div>
                        {showErrors && (
                          <>
                            <div className="col-sm-12 mb-3">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights"
                            type="button"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary d-none"
                            type="button"
                            id="success_update_btn_module"
                            data-bs-toggle="modal"
                            data-bs-target="#success_update_msg_module"
                          >
                            Success
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Module */}
      {/* Success Module */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_module"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Module Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Module */}
    </div>
  );
};

export default EditModuleModal;
