import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import DatePicker from "react-datepicker";
import moment from "moment";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
import Alert from "../Alert";
import { familyRelations } from "../../utils/Constants";
import { isValidDate, validateDate, validatePhoneNumber } from "../../utils/ValidatorFunctions";
const EditFamilyInfoPopup = ({ employeeId, entryData, onSave, onClose }) => {
  // State for a single family entry
  const [entry, setEntry] = useState({
    name: entryData.name || "",
    relationship: entryData.relationship || "",
    dob: entryData.dob ? new Date(entryData.dob) : null,
    phone: entryData.phone || "",
  });
  const headers = getAuthHeaders();
  // State for errors
  const [errors, setErrors] = useState({});
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);

  // Function to handle input change
  const handleInputChange = (field, value) => {
    setEntry({
      ...entry,
      [field]: value,
    });

    // Clear error when user starts typing
    setErrors({
      ...errors,
      [field]: "",
    });
  };

  // Function to validate the entry
  const validateEntry = () => {
    const entryErrors = {};
    // Validate Name
    if (!entry.name.trim()) {
      entryErrors.name = "Name is required";
    }
    // Validate relationship
    if (!entry.relationship.trim()) {
      entryErrors.relationship = "Relationship is required";
    }
    if (entry.phone && !validatePhoneNumber(entry.phone)) {
      entryErrors.phone = "Invalid phone number";
    }
    // Validate DOB and Phone only if at least one is provided
    // if (entry.dob || entry.phone) {
    //   // Validate DOB using Moment.js if a value is entered
    //   if (entry.dob && !validateDate(entry.dob)) {
    //     entryErrors.dob = "Invalid date of birth";
    //   }

    //   // Validate Phone Number if a value is entered
    //   if (entry.phone && !validatePhoneNumber(entry.phone)) {
    //     entryErrors.phone = "Invalid phone number";
    //   }
    // }
    setErrors(entryErrors);
    return Object.keys(entryErrors).length === 0; // Return true if no errors
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateEntry()) {
      // Format the start and Period To dates
      const formattedEntry = {
        ...entry,
        dob: moment(entry.dob).format("YYYY-MM-DD"),
      };
      try {
        const response = await axios.put(
          `${getApiUrl()}/api/employees/${employeeId}/families/${entryData.id}`,
          formattedEntry,
          { headers }
        );

        setErrors({});
        setSuccessAlert(true);
        setTimeout(() => {
          console.log(entry);
          onSave(entry);
          onClose()
        }, 3000);
      } catch (error) {
        setErrorAlert(true);
        if (error.response && error.response.status === 400) {
          const errorData = error.response.data.errors;
          const newErrors = {};

          // Map error fields to their respective error messages
          Object.keys(errorData).forEach((field) => {
            const errorMessage = errorData[field][0];
            newErrors[field] = errorMessage;
          });

          // Set errors state to display error messages
          setErrors(newErrors);
        } else {
          // Handle other types of errors (e.g., network errors)
          console.error("Error:", error);
          // You can show a generic error message to the user if needed
        }
      }
    } else {
      setErrorAlert(true);
    }

  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <div
      id="edit_family_info"
      className="modal custom-modal fade show"
      role="dialog"
      style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Family Information</h5>
            <button
              type="button"
              className="btn-close"
              onClick={onClose}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {showSuccessAlert && <Alert type="success" message="Family details submitted successfully!" />}
            {showErrorAlert && <Alert type="danger" message="Error submitting family details." />}
            <form onSubmit={handleSubmit}>
              <div className="form-scroll">
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">
                      Edit Family Information
                    </h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 ">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={entry.name}
                            onChange={(e) =>
                              handleInputChange(
                                "name",
                                e.target.value
                              )
                            }
                            className="form-control floating"
                          />
                          {errors.name && (
                            <div className="text-danger">
                              {errors.name}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 ">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <Select
                            options={familyRelations}
                            value={familyRelations.find(
                              (option) => option.value === entry.relationship
                            )}
                            onChange={(selectedOption) =>
                              handleInputChange("relationship", selectedOption.value)
                            }
                            styles={customStyles}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 ">
                          <label className="col-form-label">
                            Date Of Birth 
                          </label>
                          <div className="cal-icon">
                            <DatePicker
                              selected={entry.dob}
                              onChange={(date) =>
                                handleInputChange(
                                  "dob",
                                  date
                                )
                              }
                              className="form-control floating datetimepicker"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>
                          {errors.dob && (
                            <div className="text-danger">
                              {errors.dob}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3 ">
                          <label className="col-form-label">
                            Phone Number 
                          </label>
                          <div className="cal-icon">
                            <input
                              className="form-control"
                              type="tel"
                              value={entry.phone}
                              onChange={(e) =>
                                handleInputChange("phone", e.target.value)
                              }
                            />
                          </div>
                          {errors.entry && (
                            <div className="text-danger">
                              {errors.entry}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFamilyInfoPopup;
