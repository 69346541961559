import React, { useRef } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import CandidatesTable from "./CandidatesTable";
import AddCandidateModal from "../../../../../components/Administration/Jobs/CandidatesModal/AddCandidateModal";
import Footer from "../../../../layout/Footer";

const Candidates = () => {
  const candidateRef = useRef();
  const updateCandidateTableData = () => {
    candidateRef.current.updateCandidatesList();
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Candidates"
            title="Dashboard"
            subtitle="Candidates"
            modal="#add_candidate"
            name="Add Candidate"
          />
          <CandidatesTable ref={candidateRef}/>
          <AddCandidateModal handleUpdateList={updateCandidateTableData} />
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Candidates;
