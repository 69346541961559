import React from 'react';
import PropTypes from 'prop-types';

const PreLoader = ({ showLoader }) => {
  if (!showLoader) return null;

  return (
    <div className="tsloader">
      {[...Array(45).keys()].map((index) => (
        <div key={index} className={`tslsection section${index}`}></div>
      ))}
      <div className="tsnloading tsloading">
        <span>TS</span>
      </div>
    </div>
  );
};

PreLoader.propTypes = {
  showLoader: PropTypes.bool.isRequired,
};

export default PreLoader;