export const SidebarData = [
  {
    title: "MAIN",
    showAsTab: false,
    separateRoute: false,
    roles: ["super-admin"],
    menu: [
      {
        menuValue: "Dashboard",
        hasSubRoute: true,
        showSubRoute: true,
        route: "#",
        icon: "la la-dashcube",
        roles: ["super-admin"],
        subMenus: [
          {
            menuValue: "Admin Dashboard",
            route: "/admin-dashboard",
            roles: ["super-admin"],
          },
          {
            menuValue: "Deals Dashboard",
            route: "/deals-dashboard",
            roles: ["super-admin"],
          },
          {
            menuValue: "Leads Dashboard",
            route: "/leads-dashboard",
            roles: ["super-admin"],
          },
        ],
      },
    ],
  },
  {
    title: "MAIN",
    showAsTab: false,
    separateRoute: false,
    roles: ["tenant-admin"],
    menu: [
      {
        menuValue: "Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/dashboard",
        icon: "la la-dashcube",
        roles: ["tenant-admin"],
      },
    ],
  },
  {
    title: "CRM",
    showAsTab: false,
    separateRoute: false,
    roles: ["super-admin"],
    menu: [
      {
        menuValue: "Companies",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/companies",
        roles: ["super-admin"],
        icon: "la la-building",
      },
      {
        menuValue: "Leads",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/leads-list",
        roles: ["super-admin"],
        icon: "la la-chart-area",
      },
      {
        menuValue: "Contacts",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/contact-list",
        roles: ["super-admin"],
        icon: "la la-user-shield",
      },
      {
        menuValue: "Pipeline",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/pipeline",
        roles: ["super-admin"],
        icon: "la la-exchange-alt",
      },
      {
        menuValue: "Deals",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/deals",
        roles: ["super-admin"],
        icon: "la la-cubes",
      },
      {
        menuValue: "Analytics",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/analytics",
        roles: ["super-admin"],
        icon: "la la-dice",
      },
      {
        menuValue: "Activities",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/activities",
        roles: ["super-admin"],
        icon: "la la-directions",
      },
    ],
  },
  {
    title: "PEOPLE",
    showAsTab: false,
    separateRoute: false,
    roles: ["tenant-admin"],
    menu: [
      {
        menuValue: "All Employees",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["tenant-admin"],
        route: "#",
        icon: "la la-user",
        subMenus: [
          {
            menuValue: "All Employees",
            route: "/employees-list",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Holidays",
            route: "/holidays",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Leaves",
            route: "/adminleaves",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Leave Setting",
            route: "/leave-settings",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Attendance",
            route: "/adminattendance",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Department",
            route: "/departments",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Designation",
            route: "/designations",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Timesheet",
            route: "/timesheet",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Shift & Schedule",
            route: "/shift-scheduling",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Overtime",
            route: "/overtime",
            roles: ["tenant-admin"],
          },
        ],
      },
    ],
  },
  {
    title: "HR",
    showAsTab: false,
    separateRoute: false,
    roles: ["super-admin", "tenant-admin"],
    menu: [
      {
        menuValue: "Sales",
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-files-o",
        roles: ["super-admin"],
        subMenus: [
          {
            menuValue: "Estimates",
            route: "/estimates",
            roles: ["super-admin"],
          },
          {
            menuValue: "Invoices",
            route: "/invoices",
            roles: ["super-admin"],
          },
          {
            menuValue: "Payments",
            route: "/payments",
            roles: ["super-admin"],
          },
          {
            menuValue: "Expenses",
            route: "/expenses",
            roles: ["super-admin"],
          },
          {
            menuValue: "Provident Fund",
            route: "/provident-fund",
            roles: ["super-admin"],
          },
          {
            menuValue: "Taxes",
            route: "/taxes",
            roles: ["super-admin"],
          },
        ],
      },
      {
        menuValue: "Accounting",
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-files-o",
        roles: ["super-admin"],
        subMenus: [
          {
            menuValue: "Categories",
            route: "/categories",
            roles: ["super-admin"],
          },
          {
            menuValue: "Budgets",
            route: "/budgets",
            roles: ["super-admin"],
          },
          {
            menuValue: "Budget Revenues",
            route: "/budget-revenues",
            roles: ["super-admin"],
          },
          {
            menuValue: "Budget Expenses",
            route: "/budget-expenses",
            roles: ["super-admin"],
          },
        ],
      },
      {
        menuValue: "Payroll",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["tenant-admin"],
        icon: "la la-money",
        subMenus: [
          {
            menuValue: "Employee Salary",
            route: "/salary",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Payslip",
            route: "/salary-view",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Payroll Items",
            route: "/payroll-items",
            roles: ["tenant-admin"],
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/policies",
        roles: ["tenant-admin"],
        icon: "la la-file-pdf-o",
      },
      {
        menuValue: "Reports",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["tenant-admin"],
        icon: "la la-chart-pie",
        subMenus: [
          {
            menuValue: "Expense Report",
            route: "/expense-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Invoice Report",
            route: "/invoice-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Payments Report",
            route: "/payments-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Project Report",
            route: "/project-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Task Report",
            route: "/task-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "User Report",
            route: "/user-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Employee Report",
            route: "/employee-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Payslip Report",
            route: "/payslip-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Attendance Report",
            route: "/attendance-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Leave Report",
            route: "/leave-reports",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Daily Report",
            route: "/daily-reports",
            roles: ["tenant-admin"],
          },
        ],
      },
    ],
  },
  {
    title: "PERFORMANCE",
    showAsTab: false,
    separateRoute: false,
    roles: ["tenant-admin"],
    menu: [
      {
        menuValue: "Performance",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["tenant-admin"],
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Performance Indicator",
            route: "/performance-indicator",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Performance Review",
            route: "/performance",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Performance Appraisal",
            route: "/performance-appraisal",
            roles: ["tenant-admin"],
          },
        ],
      },

      {
        menuValue: "Goals",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["tenant-admin"],
        icon: "la la-crosshairs",
        subMenus: [
          {
            menuValue: "Goal List",
            route: "/goal-tracking",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Goal Type",
            roles: ["tenant-admin"],
            route: "/goal-type",
          },
        ],
      },
      {
        menuValue: "Training",
        hasSubRoute: true,
        roles: ["tenant-admin"],
        showSubRoute: false,
        icon: "la la-edit",
        subMenus: [
          {
            menuValue: "Training List",
            route: "/training",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Trainers",
            route: "/trainers",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Training Type",
            route: "/training-type",
            roles: ["tenant-admin"],
          },
        ],
      },
      {
        menuValue: "Promotion",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/promotion",
        icon: "la la-bullhorn",
        roles: ["tenant-admin"],
      },
      {
        menuValue: "Resignation",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/resignation",
        roles: ["tenant-admin"],
        icon: "la la-external-link-square",
      },
      {
        menuValue: "Termination",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/termination",
        roles: ["tenant-admin"],
        icon: "la la-times-circle",
      },
    ],
  },
  {
    title: "RECRUITMENT",
    showAsTab: false,
    separateRoute: false,
    roles: ["tenant-admin"],
    menu: [
      {
        menuValue: "Jobs",
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-briefcase",
        roles: ["tenant-admin"],
        subMenus: [
          {
            menuValue: "User Dasboard",
            route: "/user-dashboard",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Jobs Dasboard",
            route: "/jobs-dashboard",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Manage Jobs",
            route: "/jobs",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Manage Resumes",
            route: "/manage-resumes",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Shortlist Candidates",
            route: "/shortlist-candidates",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Interview Questions",
            route: "/interview-questions",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Offer Approvals",
            route: "/offer_approvals",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Experience Level",
            route: "/experiance-level",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Candidates List",
            route: "/candidates",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Schedule timing",
            route: "/schedule-timing",
            roles: ["tenant-admin"],
          },
          {
            menuValue: "Aptitude Results",
            route: "/apptitude-result",
            roles: ["tenant-admin"],
          },
        ],
      },
    ],
  },
  {
    title: "ADMINISTRATION",
    showAsTab: false,
    separateRoute: false,
    roles: ["super-admin", "tenant-admin"],
    menu: [
      {
        menuValue: "Masters",
        hasSubRoute: true,
        showSubRoute: false,
        roles: ["super-admin", "tenant-admin"],
        icon: "la la-object-ungroup",
        subMenus: [
          {
            menuValue: "Entity Types",
            route: "/masters/entity-types",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Currencies",
            route: "/masters/currencies",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Languages",
            route: "/masters/languages",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Countries",
            route: "/masters/countries",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Branch Types",
            route: "/masters/branch-types",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Departments",
            route: "/masters/departments",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Designations",
            route: "/masters/designations",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Employment Types",
            route: "/masters/employment-types",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Industries",
            route: "/masters/industries",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Service Models",
            route: "/masters/service-models",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Document Categories",
            route: "/masters/document-categories",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Document Types",
            route: "/masters/document-types",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Modules",
            route: "/masters/modules",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Permissions",
            route: "/masters/permissions",
            roles: ["super-admin", "tenant-admin"],
          },
          {
            menuValue: "Roles",
            route: "/masters/roles",
            roles: ["super-admin", "tenant-admin"],
          },
        ],
      },
      {
        menuValue: "Assets",
        hasSubRoute: false,
        showSubRoute: false,
        roles: ["super-admin", "tenant-admin"],
        route: "/assets",
        icon: "la la-object-ungroup",
      },
      {
        menuValue: "Notification Templates",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/notification-templates",
        roles: ["super-admin", "tenant-admin"],
        icon: "la la-bell",
      },
      {
        menuValue: "User Groups",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/user-groups",
        roles: ["super-admin", "tenant-admin"],
        icon: "la la-users",
      },
      {
        menuValue: "Users",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/users",
        roles: ["super-admin", "tenant-admin"],
        icon: "la la-user-plus",
      },
      // {
      //   menuValue: "Settings",
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: "/company-settings",
      //   roles: ["super-admin"],
      //   icon: "la la-cog",
      // },
      {
        menuValue: "Change Log",
        hasSubRoute: false,
        showSubRoute: false,
        roles: ["super-admin"],
        route: "#",
        icon: "la la-info",
      },
    ],
  },
];

export const candidateSidebarData = [
  {
    title: "Tasks",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Upload Documents",
        hasSubRoute: false,
        showSubRoute: false,
        route: "upload-candidate-documents",
        icon: "la la-upload",
      },
    ],
  },
];
