import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import DatePicker from "react-datepicker";
import moment from "moment";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
import Alert from "../Alert";

const EditExperienceInfoPopup = ({ employeeId, entryData, onSave, onClose }) => {
    // State for a single experience entry
    const [entry, setEntry] = useState({
        company: entryData.company || "",
        position: entryData.position || "",
        location: entryData.location || "",
        period_from: entryData.period_from ? new Date(entryData.period_from) : null,
        period_to: entryData.period_to
          ? new Date(entryData.period_to)
          : null
      });
      const headers = getAuthHeaders();
    // State for errors
    const [errors, setErrors] = useState({});
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);

    // Function to handle input change
    const handleInputChange = (field, value) => {
        setEntry({
            ...entry,
            [field]: value,
        });

        // Clear error when user starts typing
        setErrors({
            ...errors,
            [field]: "",
        });
    };

    // Function to validate the entry
    const validateEntry = () => {
        const entryErrors = {};

        // Validate Company
        if (!entry.company.trim()) {
            entryErrors.company = "Company is required";
        }
        // Validate Position
        if (!entry.position.trim()) {
            entryErrors.position = "Company is required";
        }

        // Validate Period From
        if (!entry.period_from) {
            entryErrors.period_from = "Period From  Date is required";
        }

        // Validate Period To Date
        if (!entry.period_to) {
            entryErrors.period_to = "Period To Date is required";
        } else if (entry.period_from && entry.period_to <= entry.period_from) {
            entryErrors.period_to =
                "Period To Date must be after the Period From Date.";
        }

        setErrors(entryErrors);
        return Object.keys(entryErrors).length === 0; // Return true if no errors
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();


        if (validateEntry()) {
            // Format the start and Period To dates
            const formattedEntry = {
                ...entry,
                period_from: moment(entry.period_from).format("YYYY-MM-DD"),
                period_to: moment(entry.period_to).format("YYYY-MM-DD"),
              };
              console.log(formattedEntry);
            try {
                const response = await axios.put(
                    `${getApiUrl()}/api/employees/${employeeId}/experiences/${entryData.id}`,
                    formattedEntry,
                    { headers }
                );
    
                setErrors({});
                setSuccessAlert(true);
                setTimeout(() => {
                    console.log(entry);
                    onSave(entry);
                    onClose()
                }, 3000);
            } catch (error) {
                setErrorAlert(true);
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data.errors;
                    const newErrors = {};
    
                    // Map error fields to their respective error messages
                    Object.keys(errorData).forEach((field) => {
                        const errorMessage = errorData[field][0];
                        newErrors[field] = errorMessage;
                    });
    
                    // Set errors state to display error messages
                    setErrors(newErrors);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error("Error:", error);
                    // You can show a generic error message to the user if needed
                }
            }
        } else {
            setErrorAlert(true);
        }

    };

    return (
        <div
            id="edit_experience_info"
            className="modal custom-modal fade show"
            role="dialog"
            style={{ display: "block", background: "rgba(0,0,0,0.5)" }}
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Edit Experience Information</h5>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                    {showSuccessAlert && <Alert type="success" message="Experience details submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting experience details." />}
                        <form onSubmit={handleSubmit}>
                            <div className="form-scroll">
                                <div className="card">
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            Edit Experience Information
                                        </h3>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                    <label className="col-form-label">
                                                        Company <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        value={entry.company}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "company",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                    {errors.company && (
                                                        <div className="text-danger">
                                                            {errors.company}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Position
                                                        </label>
                                                    <input
                                                        type="text"
                                                        value={entry.position}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "position",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Location
                                                        </label>
                                                    <input
                                                        type="text"
                                                        value={entry.location}
                                                        onChange={(e) =>
                                                            handleInputChange(
                                                                "location",
                                                                e.target.value
                                                            )
                                                        }
                                                        className="form-control floating"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Period From <span className="text-danger">*</span>
                                                        </label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={entry.period_from}
                                                            onChange={(date) =>
                                                                handleInputChange(
                                                                    "period_from",
                                                                    date
                                                                )
                                                            }
                                                            className="form-control floating datetimepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                        />
                                                    </div>
                                                    {errors.period_from && (
                                                        <div className="text-danger">
                                                            {errors.period_from}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3 ">
                                                <label className="col-form-label">
                                                            Complete Date <span className="text-danger">*</span>
                                                        </label>
                                                    <div className="cal-icon">
                                                        <DatePicker
                                                            selected={entry.period_to}
                                                            onChange={(date) =>
                                                                handleInputChange(
                                                                    "period_to",
                                                                    date
                                                                )
                                                            }
                                                            className="form-control floating datetimepicker"
                                                            dateFormat="dd-MM-yyyy"
                                                        />
                                                    </div>
                                                    {errors.period_to && (
                                                        <div className="text-danger">
                                                            {errors.period_to}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditExperienceInfoPopup;
