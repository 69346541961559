/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { useTranslation } from "react-i18next";
// import { withRouter } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import { candidateSidebarData } from "./sidebardata";
import * as Icon from "react-feather";

const Sidebar1 = () => {
  const location = useLocation();
  // const pathname = location.pathname.split("/")[1];
  const pathname = location.pathname;
  // console.log("pageurl", pathname);

  const [sidebarData, setSidebarData] = useState(candidateSidebarData);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [submenuDrop, setSubmenudrop] = useState(false);

  useEffect(() => {
    localStorage.setItem("colorschema", "blue");
    localStorage.setItem("layout", "vertical");
    localStorage.setItem("layoutwidth", "fluid");
    localStorage.setItem("layoutpos", "fixed");
    localStorage.setItem("topbartheme", "light");
    localStorage.setItem("layoutSized", "lg");
    localStorage.setItem("layoutStyling", "default");
    localStorage.setItem("layoutSidebarStyle", "light");
    const BluesThemes = document.querySelector("html");
    BluesThemes.setAttribute("data-layout-mode", "blue");
    BluesThemes.setAttribute("data-sidebar", "light");
    BluesThemes.setAttribute("data-topbar", "default");
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };
  const { t } = useTranslation();

  const expandSubMenus = (menu) => {
    sessionStorage.setItem("menuValue", menu.menuValue);
    const updatedAdminSidebar = sidebarData.map((section) => {
      const updatedSection = { ...section };
      updatedSection.menu = section.menu.map((menuItem) =>
        menu.menuValue != menuItem.menuValue
          ? {
              ...menuItem,
              showSubRoute: false,
            }
          : {
              ...menuItem,
              showSubRoute: !menu.showSubRoute,
            }
      );
      return updatedSection;
    });
    setSidebarData(updatedAdminSidebar);
  };
  const arrowDrop = () => {
    setSubmenudrop(!submenuDrop);
  };

  return (
    <div
      className={`sidebar ${isSidebarExpanded ? "" : "hidden"}`}
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll" style={{ overflow: false }}>
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide={false}
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul className="sidebar-vertical" id="veritical-sidebar">
              {sidebarData.map((mainTitle, index) => {
                return (
                  <>
                    <li className="menu-title" key={"mt" + (index + 1)}>
                      <span>{t(mainTitle.title)}</span>
                      {mainTitle?.title === "CRM" ? (
                        <small className="newly-added-features">New</small>
                      ) : (
                        ""
                      )}
                    </li>
                    {mainTitle.menu.map((menu, menuIndex) => {
                      return (
                        <>
                          {menu.hasSubRoute === false ? (
                            <li
                              key={"smt" + (menuIndex + 1)}
                              className={pathname == menu.route ? "active" : ""}
                            >
                              <Link to={menu.route}>
                                {/* {menu.icon} */}
                                <i className={menu?.icon} />
                                <span>{t(menu.menuValue)}</span>
                              </Link>
                            </li>
                          ) : (
                            <li
                              key={"smt" + (menuIndex + 1)}
                              className="submenu"
                            >
                              <Link
                                to="#"
                                onClick={() => expandSubMenus(menu)}
                                className={menu.showSubRoute ? "subdrop" : ""}
                              >
                                <i className={menu?.icon} />
                                <span
                                  className={
                                    menu?.menuValue == "Employees"
                                      ? "noti-dot"
                                      : ""
                                  }
                                >
                                  {t(menu.menuValue)}
                                </span>
                                <span className="menu-arrow"></span>
                              </Link>
                              <ul
                                style={{
                                  display: menu.showSubRoute ? "block" : "none",
                                }}
                              >
                                {menu.subMenus.map((subMenus, subMenu) => {
                                  return (
                                    <>
                                      {/* {console.log(subMenus?.showMenuRoute)} */}
                                      {subMenus?.showMenuRoute === true ? (
                                        <li key={"ssmt" + (subMenu + 1)}>
                                          <Link
                                            to={subMenus.route}
                                            className={
                                              submenuDrop ? "subdrop" : ""
                                            }
                                            onClick={arrowDrop}
                                          >
                                            {t(subMenus.menuValue)}
                                            <span className="menu-arrow"></span>
                                          </Link>

                                          <ul
                                            style={{
                                              display: submenuDrop
                                                ? "block"
                                                : "none",
                                            }}
                                          >
                                            {subMenus?.subMenusValues?.map(
                                              (value, index) => {
                                                return (
                                                  <li key={"mv" + (index + 1)}>
                                                    <span>
                                                      <Link to={value.route}>
                                                        <span>
                                                          {t(value.menuValue)}{" "}
                                                        </span>
                                                      </Link>
                                                    </span>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </li>
                                      ) : (
                                        <li key={"sm" + (subMenu + 1)}>
                                          <Link
                                            to={subMenus.route}
                                            className={
                                              pathname == subMenus?.route
                                                ? "active"
                                                : ""
                                            }
                                          >
                                            {t(subMenus.menuValue)}
                                          </Link>

                                          <ul>
                                            {subMenus?.subMenusValues?.map(
                                              (value, index) => {
                                                return (
                                                  <li key={"mv2" + (index + 1)}>
                                                    <Link
                                                      to={value.route}
                                                      className={
                                                        pathname == value?.route
                                                          ? "active"
                                                          : ""
                                                      }
                                                    >
                                                      {t(value.menuValue)}
                                                    </Link>
                                                  </li>
                                                );
                                              }
                                            )}
                                          </ul>
                                        </li>
                                      )}
                                    </>
                                  );
                                })}
                              </ul>
                            </li>
                          )}
                        </>
                      );
                    })}
                  </>
                );
              })}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default Sidebar1;
