import React, { useState } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from 'moment';
import Alert from "../Alert";

import {
    departmentOptions,
    designationOptions,
    maritialStatusOptions,
    bloodGroupOptions,
    nationalityOptions,
    branchOptions,
    employmentTypeOptions,
    employeeStatus,
    employeeStages,
} from "../../utils/Constants";

import { validateEmail, validatePhoneNumber } from "../../utils/ValidatorFunctions";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const AllEmployeeEditPopup = ({ employeeData, onUpdate, reportingManagers }) => {     

    const headers = getAuthHeaders();  
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    const fullName = employeeData.full_name;
    const [firstName, ...rest] = fullName.split(" ");
    const lastName = rest.join(" ");      
    const [formData, setFormData] = useState({
        employeeCode: employeeData.code,
        canLogin: "yes",
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: employeeData.dob,
        gender: employeeData.gender,
        maritalStatus: employeeData.marital_status,
        bloodGroup: employeeData.blood_group,
        militaryService: employeeData.military_service ? 'yes' : 'no',
        handicapped: employeeData.handicapped ? 'yes' : 'no',
        email: employeeData.email,
        officialEmail: employeeData.official_email,
        phone: employeeData.phone,
        mobile: employeeData.mobile,
        fax: employeeData.fax,
        nationality: employeeData.nationality_id,
        department: employeeData.department_id,
        designation: employeeData.designation_id,
        reportingManager: employeeData.reporting_manager_id,
        branch: employeeData.branch_id,
        joiningDate: employeeData.joining_date,
        offerDate: employeeData.offer_date,
        employmentType: employeeData.employment_type_id,
        employmentStage: employeeData.employment_stage,
        status: employeeData.status,
    });  
    const [errors, setErrors] = useState({});
    const handleChange = (fieldName, value) => {        
        setFormData({
            ...formData,
            [fieldName]: value ? value : null,
        });
    };
    const extendedReportingManagers = [
        { value: '', label: 'Select Reporting Manager' },
        ...reportingManagers,
      ];
    
    const validateForm = () => {
        const newErrors = {};
        if (!formData.employeeCode) newErrors.employeeCode = "Employee code is required";
        if (!formData.firstName) newErrors.firstName = "First name is required";
        if (!formData.lastName) newErrors.lastName = "Last name is required";
        if (!formData.dateOfBirth) newErrors.dateOfBirth = "Date of birth is required";
        if (!formData.maritalStatus) newErrors.maritalStatus = "Marital status is required";
        if (!formData.bloodGroup) newErrors.bloodGroup = "Blood group is required";
        if (!formData.email) newErrors.email = "Email is required";
        if (!formData.officialEmail) newErrors.officialEmail = "Official email is required";
        if (!formData.mobile) newErrors.mobile = "Mobile number is required";
        if (!formData.nationality) newErrors.nationality = "Nationality is required";
        if (!formData.department) newErrors.department = "Department is required";
        if (!formData.designation) newErrors.designation = "Designation is required";
        // if (!formData.reportingManager) newErrors.reportingManager = "Reporting manager is required";
        if (!formData.branch) newErrors.branch = "Branch is required";
        if (!formData.joiningDate) newErrors.joiningDate = "Joining date is required";
        if (!formData.offerDate) newErrors.offerDate = "Offer date is required";
        if (!formData.employmentType) newErrors.employmentType = "Employment type is required";
        if (!formData.employmentStage) newErrors.employmentStage = "Employment stage is required";
        // Email format validation
        if (formData.email && !validateEmail(formData.email)) {
            newErrors.email = "Invalid email address";
        }
        if (formData.officialEmail && !validateEmail(formData.officialEmail)) {
            newErrors.officialEmail = "Invalid official email address";
        }
        // Mobile number validation
        if (formData.mobile && !validatePhoneNumber(formData.mobile)) {
            newErrors.mobile = "Invalid mobile number. It should be 10 digits.";
        }

        // Phone number validation (if needed)
        if (formData.phone && !validatePhoneNumber(formData.phone)) {
            newErrors.phone = "Invalid phone number. It should be 10 digits.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorAlert(false);
        if (validateForm()) {
            const parsedDateOfBirth = moment(formData.dateOfBirth);
            const parsedJoiningDate = moment(formData.joiningDate);        
            const parsedOfferDate = moment(formData.offerDate);        
            const formattedData = {
                ...formData,
                dateOfBirth: parsedDateOfBirth.format('YYYY-MM-DD'),
                joiningDate: parsedJoiningDate.format('YYYY-MM-DD'),
                offerDate: parsedOfferDate.format('YYYY-MM-DD'),
            };         
            try {
                const response = await axios.put(`${getApiUrl()}/api/employees/${employeeData.id}`, formattedData, {headers});
                setSuccessAlert(true);
                onUpdate(response.data.data);
                setTimeout(() => {
                    document.getElementById("close-modal-employee-update").click();
                    setSuccessAlert(false);
                    // navigate(`/profile/${employeeData.id}`);
                }, 3000);
            } catch (error) {
                setErrorAlert(true);
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data.message;
                    const newErrors = {};

                    // Map error fields to their respective error messages
                    Object.keys(errorData).forEach((field) => {
                        const errorMessage = errorData[field][0];
                        newErrors[field] = errorMessage;
                    });
                    // Set errors state to display error messages
                    setErrors(newErrors);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error("Error:", error);
                    // You can show a generic error message to the user if needed
                }
            }
        } else {
            setErrorAlert(true);
        }
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
            color: state.isFocused ? "#fff" : "#000",
            "&:hover": {
                backgroundColor: "#ff9b44",
            },
        }),
    };

    return (
        <>
            <div id="edit_employee" className="modal custom-modal fade" role="dialog">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Employee - {employeeData.full_name}</h5>
                            <button
                                type="button"
                                id="close-modal-employee-update"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-block mb-3">
                                            <div
                                                className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                                                role="alert"
                                            >
                                                <i className="feather-check-circle flex-shrink-0 me-2" /> Fields marked with an <span className="text-danger"> &nbsp; * &nbsp;</span> are mandatory.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Employee Code <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={formData.employeeCode}
                                                onChange={(e) => handleChange("employeeCode", e.target.value)}
                                            />
                                            {errors.employeeCode && (
                                                <div className="text-danger">{errors.employeeCode}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Can Login <span className="text-danger">*</span>
                                            </label>
                                            <div className="col-lg-12">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="canLogin"
                                                        value="yes"
                                                        checked={formData.canLogin === "yes"}
                                                        onChange={(e) => handleChange("canLogin", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="canLogin_yes"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="canLogin"
                                                        value="no"
                                                        checked={formData.canLogin === "no"}
                                                        onChange={(e) => handleChange("canLogin", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="canLogin_no"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                First Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={formData.firstName}
                                                onChange={(e) => handleChange("firstName", e.target.value)}
                                            />
                                            {errors.firstName && (
                                                <div className="text-danger">{errors.firstName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Last Name <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                value={formData.lastName}
                                                onChange={(e) => handleChange("lastName", e.target.value)}
                                            />
                                            {errors.lastName && (
                                                <div className="text-danger">{errors.lastName}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Date Of Birth <span className="text-danger">*</span>
                                            </label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={formData.dateOfBirth}
                                                    onChange={(date) => handleChange("dateOfBirth", date)}
                                                    className="form-control floating datetimepicker"
                                                    type="date"
                                                    dateFormat="dd-MM-yyyy"
                                                />
                                            </div>
                                            {errors.dateOfBirth && (
                                                <div className="text-danger">{errors.dateOfBirth}</div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Gender <span className="text-danger">*</span>
                                            </label>
                                            <div className="col-lg-12">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        value="male"
                                                        checked={formData.gender === "male"}
                                                        onChange={(e) => handleChange("gender", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="gender_male"
                                                    >
                                                        Male
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        value="female"
                                                        checked={formData.gender === "female"}
                                                        onChange={(e) => handleChange("gender", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="gender_female"
                                                    >
                                                        Female
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="gender"
                                                        value="other"
                                                        checked={formData.gender === "other"}
                                                        onChange={(e) => handleChange("gender", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="gender_other"
                                                    >
                                                        Other
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Maritial Status <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={maritialStatusOptions}
                                                value={maritialStatusOptions.find(
                                                    (option) => option.value === formData.maritalStatus
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("maritalStatus", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.maritalStatus && (
                                                <div className="text-danger">{errors.maritalStatus}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Blood Group <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={bloodGroupOptions}
                                                value={bloodGroupOptions.find(
                                                    (option) => option.value === formData.bloodGroup
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("bloodGroup", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.bloodGroup && (
                                                <div className="text-danger">{errors.bloodGroup}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Military  Service <span className="text-danger">*</span>
                                            </label>
                                            <div className="col-lg-12">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="militaryService"
                                                        value="yes"
                                                        checked={formData.militaryService === "yes"}
                                                        onChange={(e) => handleChange("militaryService", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="militaryService_yes"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="militaryService"
                                                        value="no"
                                                        checked={formData.militaryService === "no"}
                                                        onChange={(e) => handleChange("militaryService", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="militaryService_no"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Handicapped <span className="text-danger">*</span>
                                            </label>
                                            <div className="col-lg-12">
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="handicapped"
                                                        value="yes"
                                                        checked={formData.handicapped === "yes"}
                                                        onChange={(e) => handleChange("handicapped", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="handicapped_yes"
                                                    >
                                                        Yes
                                                    </label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="handicapped"
                                                        value="no"
                                                        checked={formData.handicapped === "no"}
                                                        onChange={(e) => handleChange("handicapped", e.target.value)}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor="handicapped_no"
                                                    >
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Email <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={formData.email}
                                                onChange={(e) => handleChange("email", e.target.value)}
                                            />
                                            {errors.email && (
                                                <div className="text-danger">{errors.email}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Official Email <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                value={formData.officialEmail}
                                                onChange={(e) => handleChange("officialEmail", e.target.value)}
                                            />
                                            {errors.officialEmail && (
                                                <div className="text-danger">{errors.officialEmail}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Phone </label>
                                            <input
                                                className="form-control"
                                                type="mobile"
                                                value={formData.phone}
                                                onChange={(e) => handleChange("phone", e.target.value)}
                                            />
                                            {errors.phone && (
                                                <div className="text-danger">{errors.phone}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Mobile <span className="text-danger">*</span></label>
                                            <input
                                                className="form-control"
                                                type="mobile"
                                                value={formData.mobile}
                                                onChange={(e) => handleChange("mobile", e.target.value)}
                                            />
                                            {errors.mobile && (
                                                <div className="text-danger">{errors.mobile}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Fax </label>
                                            <input
                                                className="form-control"
                                                type="mobile"
                                                value={formData.fax}
                                                onChange={(e) => handleChange("fax", e.target.value)}
                                            />
                                            {errors.mobile && (
                                                <div className="text-danger">{errors.fax}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">Nationality </label>
                                            <Select
                                                options={nationalityOptions}
                                                value={nationalityOptions.find(
                                                    (option) => option.value === formData.nationality
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("nationality", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.nationality && (
                                                <div className="text-danger">{errors.nationality}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Department <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={departmentOptions}
                                                value={departmentOptions.find(
                                                    (option) => option.value === formData.department
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("department", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.department && (
                                                <div className="text-danger">{errors.department}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Designation <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={designationOptions}
                                                value={designationOptions.find(
                                                    (option) => option.value === formData.designation
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("designation", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.department && (
                                                <div className="text-danger">{errors.designation}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Reporting Manager
                                            </label>

                                            <Select
                                                options={extendedReportingManagers}
                                                value={reportingManagers.find(
                                                    (option) => option.value === formData.reportingManager
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("reportingManager", (selectedOption.value ? selectedOption.value : ""))
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.department && (
                                                <div className="text-danger">{errors.reportingManager}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Location <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={branchOptions}
                                                value={branchOptions.find(
                                                    (option) => option.value === formData.branch
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("branch", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.department && (
                                                <div className="text-danger">{errors.branch}</div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Joining Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={formData.joiningDate}
                                                    onChange={(date) => handleChange("joiningDate", date)}
                                                    className="form-control floating datetimepicker"
                                                    type="date"
                                                    dateFormat="dd-MM-yyyy"
                                                />                                                
                                                {errors.joiningDate && (
                                                    <div className="text-danger">{errors.joiningDate}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Offer Date <span className="text-danger">*</span>
                                            </label>
                                            <div className="cal-icon">
                                                <DatePicker
                                                    selected={formData.offerDate}
                                                    onChange={(date) => handleChange("offerDate", date)}
                                                    className="form-control floating datetimepicker"
                                                    type="date"
                                                    dateFormat="dd-MM-yyyy"
                                                />                                                
                                                {errors.offerDate && (
                                                    <div className="text-danger">{errors.offerDate}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Employment Type <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={employmentTypeOptions}
                                                value={employmentTypeOptions.find(
                                                    (option) => option.value === formData.employmentType
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("employmentType", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.employmentType && (
                                                <div className="text-danger">
                                                    {errors.employmentType}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Employment Stage <span className="text-danger">*</span>
                                            </label>
                                            <Select
                                                options={employeeStages}
                                                value={employeeStages.find(
                                                    (option) => option.value === formData.employmentStage
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("employmentStage", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                            {errors.employmentStage && (
                                                <div className="text-danger">
                                                    {errors.employmentStage}
                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="input-block mb-3">
                                            <label className="col-form-label">
                                                Status
                                            </label>
                                            <Select
                                                options={employeeStatus}
                                                value={employeeStatus.find(
                                                    (option) => option.value === formData.status
                                                )}
                                                onChange={(selectedOption) =>
                                                    handleChange("status", selectedOption.value)
                                                }
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                    {showSuccessAlert && (
                                        <div className="col-sm-12">
                                            <Alert
                                                message="Employee has been updated successfully!!"
                                                type="success"
                                                close={false}
                                            />
                                        </div>
                                    )}
                                    {showErrorAlert && (
                                        <div className="col-sm-12">
                                            <Alert
                                                message="Failed to create employee due to errors!"
                                                type="danger"
                                                close={false}
                                            />
                                        </div>
                                    )}


                                </div>
                                <div className="submit-section">
                                    <button
                                        className="btn btn-primary submit-btn"
                                        aria-label="Submit"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AllEmployeeEditPopup;
