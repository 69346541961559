import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import axios from "axios";
import moment from "moment";

const CandidateNotifications = (props) => {
  const headers = getAuthHeaders();
  const { id } = useParams();
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  // Fetch categories from the API
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/candidates/${id}/notifications`,
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let notificationsData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      setNotifications(notificationsData);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  useEffect(() => {
    fetchNotifications();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);
  const columns = [
    {
      title: "Notification Title",
      dataIndex: "notification_title",
    },
    {
      title: "Notification Date",
      dataIndex: "notification_date",
      render: (text) => moment(text).format("D MMMM YYYY"),
    },
    {
      title: "Job Title",
      dataIndex: "title",
    },
    {
      title: "Modes",
      dataIndex: "modes",
      render: (text) =>
        JSON.parse(text)
          .map((mode) => mode.label)
          .join(", "),
    },
    {
      title: "Status",
      dataIndex: "is_notification_sent",
      render: (text, record) => (
        <div className="action-label text-center">
          <div className="btn btn-white btn-sm btn-rounded">
            <i
              className={
                text === "0"
                  ? "far fa-dot-circle text-info"
                  : text === "1"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text === 0 && "Pending"}
            {text === 1 && "Sent"}
            {text === 1 &&
              moment(record.sent_at).format("D MMMM YYYY, hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => moment(text).format("D MMMM YYYY, hh:mm A"),
    },
  ];
  return (
    <>
      <div className="tab-pane fade" id="candidate_notifications">
        <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearchTextChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                style={{ overflowX: "auto" }}
                dataSource={notifications}
                rowKey={(record) => record.id}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateNotifications;
