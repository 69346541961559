import React, { useEffect, useState } from "react";
import axios from "axios";
import { ColorPicker, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { getAuthHeaders, getApiUrl } from "../../utils/AuthUtils";

const validationSchema = Yup.object().shape({  
  primary_color_code: Yup.string().required("Primary color is required"),
  secondary_color_code: Yup.string().required("Secondary color is required"),
});

const EditEntityConfigurationModal = (props) => {
  const headers = getAuthHeaders();
  const [logoFile, setLogoFile] = useState(null);
  const [formData, setFormData] = useState({
    logo_file: "",
    primary_color_code: "",
    secondary_color_code: "",
    entity_id: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const editFormData = {
    ...formData,
    entity_logo_image: null,
    logo_file: props.entity.logo_file,
    primary_color_code: props.entity.primary_color_code,
    secondary_color_code: props.entity.secondary_color_code,
    entity_id: props.entity.id,
  };
  useEffect(() => {
    reset(editFormData);
  }, []);

  const handleLogoUpload = (e) => {
    setLogoFile(e.target.files[0]);
    setValue("entity_logo_image", e.target.files[0], { shouldDirty: true });
  };
  const resetFileHandler = (e) => {
    document.getElementById("entity_logo_image").value = null;
    setLogoFile(null);
    setValue("entity_logo_image", null, { shouldDirty: true });
  };

  const onSubmitConfiguration = async (data) => {
    const finalFormData = {
      entity_id: data.entity_id,
      primary_color_code: data.primary_color_code,
      secondary_color_code: data.secondary_color_code,
    };
    let formData = finalFormData;
    const url = `${getApiUrl()}/api/entities/update-configuration`;
    let config = {
      headers,
    };

    if (data.entity_logo_image) {
      config = {
        headers: {
          ...headers,
          "content-type": "multipart/form-data",
        },
      };

      formData = new FormData();
      formData.append("entity_logo_image", data.entity_logo_image);
      for (let key in finalFormData) {
        formData.append(key, finalFormData[key]);
      }
    }

    try {
      const response = await axios.post(url, formData, config);

      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        // props.handleUpdateList();
        document
          .getElementById("close-edit-configuration-modal-entity")
          .click();
        document.getElementById("success_update_btn_configuration").click();
        resetFileHandler();
        props.handleUpdateData();
      }
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status === 400) {
        // const errorData = error.response.data.message;
        // setErrors(newErrors);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };

  return (
    <div>
      {/* Edit Company Configuration*/}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_company_configuration"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Edit Company Configuration - {props.entity.entity_name}
              </h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-edit-configuration-modal-entity"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-info-fieldset">
                <form onSubmit={handleSubmit(onSubmitConfiguration)}>
                  <div className="form-upload-profile">
                    <h6 className>
                      Logo <span> *</span>
                    </h6>
                    <div className="profile-pic-upload">
                      <div className="profile-pic">
                        <span>
                          <img src={editFormData.logo_file && editFormData.logo_file.file_path} alt="Img" />
                        </span>
                      </div>
                      <div className="employee-field">
                        {logoFile && logoFile.name}
                        <div className="mb-0">
                          <div className="image-upload mb-0 mx-3">
                            <input
                              type="file"
                              name="entity_logo_image"
                              id="entity_logo_image"
                              onChange={handleLogoUpload}
                            />
                            <div className="image-uploads">
                              <h4>Upload</h4>
                            </div>
                          </div>
                        </div>
                        <div className="img-reset-btn">
                          <Link to="#" onClick={resetFileHandler}>
                            Reset
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <Space>
                            <Space direction="vertical">
                              <ColorPicker
                                defaultValue={editFormData.primary_color_code}
                                size="large"
                                showText={(color) => (
                                  <span>
                                    Primary Color ({color.toHexString()})
                                  </span>
                                )}
                                onChangeComplete={(color) => {
                                  setValue(
                                    "primary_color_code",
                                    color.toHexString(),
                                    { shouldDirty: true }
                                  );
                                  // setPrimaryColor(color.toHexString());
                                }}
                              />
                            </Space>
                          </Space>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <Space>
                            <Space direction="vertical">
                              <ColorPicker
                                defaultValue={editFormData.secondary_color_code}
                                size="large"
                                showText={(color) => (
                                  <span>
                                    Secondary Color ({color.toHexString()})
                                  </span>
                                )}
                                onChangeComplete={(color) => {
                                  setValue(
                                    "secondary_color_code",
                                    color.toHexString(),
                                    { shouldDirty: true }
                                  );
                                }}
                              />
                            </Space>
                          </Space>
                        </div>
                      </div>
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights"
                          type="button"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!isDirty}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_update_btn_configuration"
                          data-bs-toggle="modal"
                          data-bs-target="#success_update_msg_configuration"
                        >
                          Success
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Company */}
      {/* Success Company */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_configuration"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Company Configurations Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success-configuration"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Company */}
    </div>
  );
};

export default EditEntityConfigurationModal;
