import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import _ from "lodash";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import CandidateDocumentPopup from "../../../components/Administration/Jobs/CandidatesModal/CandidateDocumentPopup";
import { getLocalStorageJSONData } from "../../../utils/Misc";

const UploadCandidateDocuments = () => {
const navigate = useNavigate();
  const headers = getAuthHeaders();
  const candidateData = getLocalStorageJSONData("candidateData");
  const id = candidateData.id;
  const [documentCategories, setDocumentCategories] = useState([]);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [documentTypeId, setDocumentTypeId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [editDocument, setEditDocument] = useState({});
  const maskDocumentNumber = (number) => {
    return _.repeat("*", number.length);
  };
  const handleToggleVisibility = () => {
    setIsRevealed(!isRevealed);
  };
  const handleEditClick = (action, document) => {
    setActionMode(action);
    setEditDocument(document);
  };

  const getCandidateDocuments = async (categoryId) => {
    try {
      const documentsResponse = await axios.get(
        `${getApiUrl()}/api/candidates/${id}/documents/${categoryId}`,
        { headers }
      );
      setCandidateDocuments(documentsResponse.data.data);
      setDocumentTypeId(documentsResponse.data.data[0].document_type_id);
      setCategoryId(categoryId);
    } catch (error) {
      console.error("Error fetching candidate documents:", error);
    }
  };
  const renderMandatoryTooltip = (props) => (
    <Tooltip id="tooltip-success" className="tooltip-danger" {...props}>
      Mandatory
    </Tooltip>
  );
  const onUpdateFunction = () => {
    getCandidateDocuments(categoryId);
  };
  // Fetch categories from the API
  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/candidates/${id}/documents/categories`,
        { headers }
      );
      const categories = response.data.data;
      setDocumentCategories(categories);
      // Fetch documents for the first category
      if (categories.length > 0) {
        const firstCategory = categories.filter(
          (category) => category.document_type_count > 0 && category
        )[0];
        await getCandidateDocuments(firstCategory.category_id);
      }
    } catch (error) {
      if (error.response) {
          if (error.response.status === 401) {
            navigate(`/logout`);
          }
        }
      console.error("Error fetching documents:", error);
    }
  };

  useEffect(() => {
    

    fetchDocuments();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col">
              <h3 className="page-title">Upload Documents</h3>
            </div>
          </div>
          <div className="row my-3">
            <div className="col">
              <div className="row">
                {/* Documents Sidebar */}
                <div className="col-xl-4">
                  <div className="stickybar">
                    <div className="card contact-sidebar">
                      <h4>CANDIDATE DOCUMENT FOLDERS</h4>
                      <hr />
                      <ul className="candidate-profile-documents">
                        {documentCategories.map((category) => {
                          return (
                            category.document_type_count > 0 && (
                              <li
                                className="document-item"
                                key={category.category_id}
                                onClick={() =>
                                  getCandidateDocuments(category.category_id)
                                }
                                style={{ cursor: "pointer" }} // To change cursor to pointer on hover
                              >
                                <span class="icon-container">
                                  <i class="fa fa-file fa-2x"></i>
                                </span>
                                <div class="text-container">
                                  <p>{category.category_name}</p>
                                  {category.category_description && (
                                    <p className="category-sub-text">
                                      {category.category_description}
                                    </p>
                                  )}
                                  <p class="document-count">
                                    {category.document_type_count}{" "}
                                    {category.document_type_count === 1
                                      ? "Document"
                                      : "Documents"}
                                  </p>
                                </div>
                              </li>
                            )
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* /Documents Sidebar */}
                {/* /Documents Details */}
                <div className="col-xl-8">
                  <div className="contact-tab-view">
                    <div className="tab-content pt-0">
                      <div className="files-activity">
                        {candidateDocuments &&
                          candidateDocuments.length > 0 &&
                          candidateDocuments.map((doc) => {
                            const fileInfo = JSON.parse(doc.file_info);
                            return (
                              <div className="files-wrap">
                                <div className="row align-items-center">
                                  <div className="col-md-8">
                                    <div className="file-info">
                                      <h4>{doc.name} &nbsp;</h4>
                                      <p>
                                        {doc.description} &nbsp;{" "}
                                        {doc.is_mandatory === 1 &&
                                          !doc.candidate_document_id && (
                                            <Link
                                              to="#"
                                              data-bs-toggle="tooltip"
                                              data-bs-custom-class="tooltip-danger"
                                              data-bs-placement="top"
                                              data-bs-original-title="Danger Tooltip"
                                            >
                                              <OverlayTrigger
                                                placement="top"
                                                overlay={renderMandatoryTooltip}
                                                offset={[0, 15]}
                                              >
                                                <span class="badge badge-soft-danger">
                                                  Pending{" "}
                                                  <i className="fa fa-exclamation fa-sm" />
                                                </span>
                                              </OverlayTrigger>
                                            </Link>
                                          )}
                                      </p>
                                    </div>
                                    <div className="file-info">
                                      <div class="row align-items-center">
                                        {doc.document_number &&
                                          doc.document_number.trim() !== "" && (
                                            <div className="col-md-4">
                                              <div className="title">
                                                Document Number
                                              </div>
                                              <div className="text">
                                                {isRevealed
                                                  ? doc.document_number
                                                  : maskDocumentNumber(
                                                      doc.document_number
                                                    )}
                                                <i
                                                  className={`fa ${
                                                    isRevealed
                                                      ? "fa-eye-slash"
                                                      : "fa-eye"
                                                  }`}
                                                  onClick={
                                                    handleToggleVisibility
                                                  }
                                                  style={{
                                                    cursor: "pointer",
                                                    marginLeft: "10px",
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        {doc.issued_date &&
                                          doc.issued_date.trim() !== "" && (
                                            <div className="col-md-4">
                                              <div className="title">
                                                Issued Date
                                              </div>
                                              <div className="text">
                                                {moment(doc.issued_date).format(
                                                  "D MMMM YYYY"
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        {doc.validity_date &&
                                          doc.validity_date.trim() !== "" && (
                                            <div className="col-md-4">
                                              <div className="title">
                                                Valid Till
                                              </div>
                                              <div className="text">
                                                {moment(
                                                  doc.validity_date
                                                ).format("D MMMM YYYY")}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4 text-md-end">
                                    <ul className="file-action d-inline-block">
                                      {!doc.candidate_document_id && (
                                        <li>
                                          <Link
                                            to="#"
                                            data-bs-toggle="modal"
                                            data-bs-target="#candidate_document_modal"
                                            onClick={() => {
                                              handleEditClick("create", doc);
                                              setDocumentTypeId(doc.document_type_id);
                                            }}
                                          >
                                            <span className="badge badge-soft-success">
                                              Upload Document
                                            </span>
                                          </Link>
                                        </li>
                                      )}
                                      {doc.candidate_document_id &&
                                        fileInfo &&
                                        fileInfo.length > 0 &&
                                        fileInfo.map((file, index) => (
                                          <li
                                            className="text-center m-3"
                                            key={index}
                                          >
                                            <div className="text ">
                                              <a
                                                href={file.file_path}
                                                download
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                Download{" "}
                                                <i className="fa fa-download"></i>
                                              </a>
                                            </div>
                                          </li>
                                        ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Documents Details */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <CandidateDocumentPopup
        candidateId={id}
        documentType={documentTypeId}
        actionMode={actionMode}
        documentDetails={editDocument}
        onUpdateFunction={onUpdateFunction}
      />
    </>
  );
};

export default UploadCandidateDocuments;
