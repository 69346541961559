/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { useParams } from "react-router-dom";
import { MedExpertIcon } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import _ from "lodash";
import PreLoader from "../../../components/PreLoader";
import { useNavigate } from "react-router-dom";
import EmployeeAvatarUploadModal from "../../../components/modelpopup/EmployeeAvatarUploadModal";
import {
  getEmployeeStatusBadgeClass,
  getEmployeeListData, // Importing employee status class
} from "../../../utils/Misc"; // Importing Misc functions
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";

const EmployeeProfile = () => {
  const headers = getAuthHeaders();
  const { id } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employeesList, setEmployeesList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [employeeAvatar, setEmployeeAvatar] = useState(MedExpertIcon);
  const [managerAvatar, setManagerAvatar] = useState(MedExpertIcon);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/employees/${id}`,
          { headers }
        );

        setUserData(response.data.data);
        if (response.data.data.avatar) {
          setEmployeeAvatar(response.data.data.avatar);
        } else {
          setEmployeeAvatar(MedExpertIcon);
        }
        if (response.data.data.manager_avatar) {
          setManagerAvatar(response.data.data.manager_avatar);
        } else {
          setManagerAvatar(MedExpertIcon);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.status === 404) {
          navigate("/error-404");
        }
      }
    };

    fetchEmployeeData();
  }, [id]);

  // fetch employee list data for reporting manager dropdown
  useEffect(() => {
    const fetchEmployeeDropdownData = async () => {
      try {
        const data = await getEmployeeListData();
        setEmployeesList(data);
      } catch (err) {
        console.log(err.message);
        // setError(err.message);
      }
    };

    fetchEmployeeDropdownData();
  }, []);

  const handleEmployeeUpdate = (updatedEmployee) => {
    setUserData(updatedEmployee);
  };
  const handleMouseHover = (event) => {
    if (event.type === "mouseover") {
      setIsHovered(true);
    } else if (event.type === "mouseout") {
      setIsHovered(false);
    }
  };
  const updateEmployeeAvatar = (newAvatar) => {
    setEmployeeAvatar(newAvatar);
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#edit_employee"
            name="Edit Profile"
            Linkname="#"
          />
          <PreLoader showLoader={loading} />
          {!loading && (
            <>
              <div className="card mb-0">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-view">
                        <div className="profile-img-wrap">
                          <div
                            className="profile-img-wrap edit-img"
                            onMouseOver={handleMouseHover}
                            onMouseOut={handleMouseHover}
                          >
                            <img
                              className="inline-block"
                              src={employeeAvatar}
                              alt="user"
                            />
                            {isHovered && (
                              <div className="fileupload btn ">
                                <span
                                  className="btn-text"
                                  data-bs-toggle="modal"
                                  data-bs-target="#employee_avatar_modal"
                                >
                                  <i className="fa fa-pencil" />
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="profile-basic">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="profile-info-left">
                                <h3 className="user-name m-t-0 mb-0">
                                  {_.startCase(userData.full_name)}
                                </h3>
                                <ul className="personal-info">
                                  <li>
                                    <h6 className="text-muted">
                                      {_.startCase(userData.designation)}
                                    </h6>
                                  </li>
                                  <li>
                                    <div className="title">Department :</div>
                                    <div className="text">
                                      {_.startCase(userData.department)}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">Employee Code :</div>
                                    <div className="text">
                                      {_.upperCase(userData.code)}
                                    </div>
                                  </li>
                                  {userData.offer_date && userData.offer_date !== '0000-00-00'  && (
                                    <li>
                                      <div className="title">Offer Date :</div>
                                      <div className="text">
                                        {moment(userData.offer_date).format("D MMMM YYYY")}
                                      </div>
                                    </li>
                                  )}
                                  <li>
                                    <div className="title">Date of Join :</div>
                                    <div className="text">
                                      {moment(userData.joining_date).format("D MMMM YYYY")}
                                      
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">Work Location :</div>
                                    <div className="text">
                                    {_.startCase(userData.branch_details)}
                                      
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">
                                      Employment Stage :
                                    </div>
                                    <div className="text">
                                      {_.startCase(userData.employment_stage)}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">
                                      Employment Type :
                                    </div>
                                    <div className="text">
                                      {_.startCase(userData.employment_type)}
                                    </div>
                                  </li>
                                  <li>
                                    <div className="title">Status :</div>
                                    <div className="text">
                                      <span
                                        className={getEmployeeStatusBadgeClass(
                                          userData.status
                                        )}
                                      >
                                        {_.startCase(userData.status)}
                                      </span>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <ul className="personal-info">
                                <li>
                                  <div className="title">
                                    <i className="fa fa-phone" /> Mobile{" "}
                                  </div>
                                  <div className="text">
                                    <Link to={`tel:${userData.mobile}`}>
                                      {userData.mobile}
                                    </Link>
                                  </div>
                                </li>
                                {userData.phone && (
                                  <li>
                                    <div className="title">
                                      <i className="fa fa-phone" /> Phone{" "}
                                    </div>
                                    <div className="text">
                                      <Link to={`tel:${userData.phone}`}>
                                        {userData.phone}
                                      </Link>
                                    </div>
                                  </li>
                                )}
                                {userData.fax && (
                                  <li>
                                    <div className="title">
                                      <i className="fa fa-fax" /> Fax{" "}
                                    </div>
                                    <div className="text">
                                      <Link to={`tel:${userData.fax}`}>
                                        {userData.fax}
                                      </Link>
                                    </div>
                                  </li>
                                )}
                                <li>
                                  <div className="title">
                                    <i className="fa fa-envelope" /> Email{" "}
                                  </div>
                                  <div className="text">
                                    <Link to={`mailto:${userData.email}`}>
                                      {userData.email}
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  <div className="title">
                                    <i className="fa fa-envelope" /> Official
                                    Email{" "}
                                  </div>
                                  <div className="text">
                                    <Link
                                      to={`mailto:${userData.official_email}`}
                                    >
                                      {userData.official_email}
                                    </Link>
                                  </div>
                                </li>
                                {userData.reporting_manager && (
                                  <li>
                                    <div className="title">Reports to:</div>
                                    <div className="text">
                                      <div className="avatar-box">
                                        <div className="avatar avatar-sm">
                                          <img
                                            src={managerAvatar}
                                            alt="User Image"
                                          />
                                        </div>
                                      </div>
                                      <Link
                                        to={`/profile/${userData.reporting_manager_id}`}
                                      >
                                        {_.startCase(
                                          userData.reporting_manager
                                        )}
                                      </Link>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="pro-edit">
                          <Link
                            data-bs-target="#edit_employee"
                            data-bs-toggle="modal"
                            className="edit-icon"
                            to="#"
                          >
                            <i className="fa-solid fa-pencil"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card tab-box">
                <div className="row user-tabs">
                  <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item">
                        <Link
                          to="#emp_profile"
                          data-bs-toggle="tab"
                          className="nav-link active"
                        >
                          Profile
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#emp_documents"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Documents
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link
                          to="#emp_projects"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Projects
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#bank_statutory"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Bank &amp; Statutory
                          <small className="text-danger ms-1">(Admin Only)</small>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          to="#emp_assets"
                          data-bs-toggle="tab"
                          className="nav-link"
                        >
                          Assets
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              {/* Profile Info Tab */}

              <EmployeeAvatarUploadModal
                employeeId={id}
                avatar={employeeAvatar}
                updateEmployeeAvatar={updateEmployeeAvatar}
              />
              <ProfileTab
                userData={userData}
                onUpdate={handleEmployeeUpdate}
                reportingManagers={employeesList}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeProfile;
