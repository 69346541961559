import React, { useState, useEffect } from "react";
import axios from "axios"; // Make sure to install axios: npm install axios
import { getAuthHeaders, getApiUrl } from "../../utils/AuthUtils";

const EntitySmtpSettings = () => {
  const [editMode, setEditMode] = useState(true); // Toggle between display and edit modes
  const headers = getAuthHeaders();
  const [formValues, setFormValues] = useState({
    fromEmail: "",
    fromName: "",
    provider: "other",
    clientId: "",
    clientSecret: "",
    refreshToken: "",
    tenantId: "",
    accessKeyId: "",
    secretAccessKey: "",
    region: "",
    host: "",
    port: "",
    username: "",
    password: "",
  });

  const [errors, setErrors] = useState({}); // For form validation errors
  const [loading, setLoading] = useState(true); // Loading state for fetching data

  // Fetch SMTP settings from the server when the component mounts
  useEffect(() => {
    axios
      .get(
        getApiUrl() +`/api/entities/smtp-settings`, {          
          headers,
        })
      .then((response) => {    
        if(response.data.data){
          setFormValues(response.data.data); // Set form values from API response
          setEditMode(false);
        }    
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching SMTP settings:", error);
        setLoading(false);
        setEditMode(true);
      });
  }, []);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  // Validate form fields based on the provider
  const validateForm = () => {
    const errors = {};
    if (!formValues.fromEmail) {
      errors.fromEmail = "From Email is required";
    }
    if (!formValues.provider) {
      errors.provider = "Provider is required";
    }
  
    // Conditional validations based on the provider
    if (formValues.provider === "gmail") {
      if (!formValues.clientId) errors.clientId = "Client ID is required for Gmail";
      if (!formValues.clientSecret) errors.clientSecret = "Client Secret is required for Gmail";
      if (!formValues.refreshToken) errors.refreshToken = "Refresh Token is required for Gmail";
    }
  
    if (formValues.provider === "outlook") {
      if (!formValues.tenantId) errors.tenantId = "Tenant ID is required for Outlook";
      if (!formValues.clientId) errors.clientId = "Client ID is required for Outlook";
      if (!formValues.clientSecret) errors.clientSecret = "Client Secret is required for Outlook";
    }
  
    if (formValues.provider === "aws_ses") {
      if (!formValues.accessKeyId) errors.accessKeyId = "Access Key ID is required for AWS SES";
      if (!formValues.secretAccessKey) errors.secretAccessKey = "Secret Access Key is required for AWS SES";
    }
  
    if (formValues.provider === "other") {
      if (!formValues.host) errors.host = "Host is required for Other SMTP";
      if (!formValues.port) errors.port = "Port is required for Other SMTP";
      if (!formValues.username) errors.username = "Username is required for Other SMTP";
      if (!formValues.password) errors.password = "Password is required for Other SMTP";
    }
  
    setErrors(errors);
  
    console.log(errors); // Log errors to see if they are populated
  
    return Object.keys(errors).length === 0;
  };

  // Toggle between view and edit modes
  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  // Submit the form data to the backend
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission  
    if (!validateForm()) {
      console.log("Validation failed");
      return; // Stop if validation fails
    }
  
    try {
      await axios.put(getApiUrl() + `/api/entities/smtp-settings`, formValues, {
        headers,
      });
      console.log("Form submitted:", formValues);
      setEditMode(false); // Reset to view mode after submission
    } catch (error) {
      console.error("Error updating SMTP settings:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  // Render the form in edit mode
  const renderForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-scroll">
        <div className="input-block mb-3 row">
          <label className="col-form-label col-md-2">
            From Email <span className="text-danger">*</span>
          </label>
          <div className="col-md-10">
            <input
              type="email"
              className="form-control"
              name="fromEmail"
              value={formValues.fromEmail}
              onChange={handleInputChange}
              required
            />
            {errors.fromEmail && <span className="text-danger">{errors.fromEmail}</span>}
          </div>
        </div>

        <div className="input-block mb-3 row">
          <label className="col-form-label col-md-2">From Name</label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              name="fromName"
              value={formValues.fromName}
              onChange={handleInputChange}
            />
          </div>
        </div>

        {/* Provider Selection */}
        <div className="input-block mb-3 row">
          <label className="col-form-label col-md-2">Provider 
            <span className="text-danger">*</span>
            </label>
          <div className="col-md-10">
            <div className="radio d-inline-block me-3">
              <label className="col-form-label">
                <input
                  type="radio"
                  name="provider"
                  value="gmail"
                  onChange={handleInputChange}
                  checked={formValues.provider === "gmail"}
                />{" "}
                Gmail
              </label>
            </div>
            <div className="radio d-inline-block me-3">
              <label className="col-form-label">
                <input
                  type="radio"
                  name="provider"
                  value="outlook"
                  onChange={handleInputChange}
                  checked={formValues.provider === "outlook"}
                />{" "}
                Outlook 360
              </label>
            </div>
            <div className="radio d-inline-block me-3">
              <label className="col-form-label">
                <input
                  type="radio"
                  name="provider"
                  value="aws_ses"
                  onChange={handleInputChange}
                  checked={formValues.provider === "aws_ses"}
                />{" "}
                AWS SES
              </label>
            </div>
            <div className="radio d-inline-block">
              <label className="col-form-label">
                <input
                  type="radio"
                  name="provider"
                  value="other"
                  onChange={handleInputChange}
                  checked={formValues.provider === "other"}
                />{" "}
                Other SMTP
              </label>
            </div>
          </div>
        </div>

        {/* Conditional fields for Gmail */}
        {formValues.provider === "gmail" && (
          <>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Client ID <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="clientId"
                  value={formValues.clientId}
                  onChange={handleInputChange}
                />
                {errors.clientId && <span className="text-danger">{errors.clientId}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Client Secret <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  name="clientSecret"
                  value={formValues.clientSecret}
                  onChange={handleInputChange}
                />
                {errors.clientSecret && <span className="text-danger">{errors.clientSecret}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Refresh Token <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  name="refreshToken"
                  value={formValues.refreshToken}
                  onChange={handleInputChange}
                />
                {errors.refreshToken && <span className="text-danger">{errors.refreshToken}</span>}
              </div>
            </div>
          </>
        )}

        {/* Conditional fields for Outlook */}
        {formValues.provider === "outlook" && (
          <>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Tenant ID <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="tenantId"
                  value={formValues.tenantId}
                  onChange={handleInputChange}
                />
                {errors.tenantId && <span className="text-danger">{errors.tenantId}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Client ID <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="clientId"
                  value={formValues.clientId}
                  onChange={handleInputChange}
                />
                {errors.clientId && <span className="text-danger">{errors.clientId}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Client Secret <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  name="clientSecret"
                  value={formValues.clientSecret}
                  onChange={handleInputChange}
                />
                {errors.clientSecret && <span className="text-danger">{errors.clientSecret}</span>}
              </div>
            </div>
          </>
        )}

        {/* Conditional fields for AWS SES */}
        {formValues.provider === "aws_ses" && (
          <>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">AWS Access Key ID <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="accessKeyId"
                  value={formValues.accessKeyId}
                  onChange={handleInputChange}
                />
                {errors.accessKeyId && <span className="text-danger">{errors.accessKeyId}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">AWS Secret Access Key <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  name="secretAccessKey"
                  value={formValues.secretAccessKey}
                  onChange={handleInputChange}
                />
                {errors.secretAccessKey && <span className="text-danger">{errors.secretAccessKey}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Region </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="region"
                  value={formValues.region}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </>
        )}

        {/* Conditional fields for Other SMTP */}
        {formValues.provider === "other" && (
          <>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Host <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="host"
                  value={formValues.host}
                  onChange={handleInputChange}
                />
                {errors.host && <span className="text-danger">{errors.host}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Port <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="port"
                  value={formValues.port}
                  onChange={handleInputChange}
                />
                {errors.port && <span className="text-danger">{errors.port}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Username <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="text"
                  className="form-control"
                  name="username"
                  value={formValues.username}
                  onChange={handleInputChange}
                />
                {errors.username && <span className="text-danger">{errors.username}</span>}
              </div>
            </div>
            <div className="input-block mb-3 row">
              <label className="col-form-label col-md-2">Password <span className="text-danger">*</span> </label>
              <div className="col-md-10">
                <input
                  type="password"
                  className="form-control"
                  name="password"
                  value={formValues.password}
                  onChange={handleInputChange}
                />
                {errors.password && <span className="text-danger">{errors.password}</span>}
              </div>
            </div>
          </>
        )}

        <div className="submit-section text-end">
          <button type="submit" onClick={handleSubmit} className="btn btn-success">
            Save Changes
          </button>
          <button type="button" className="btn btn-secondary ms-2" onClick={toggleEditMode}>
            Cancel
          </button>
        </div>
      </div>
    </form>
  );

  // Render the values in view mode
  const renderViewMode = () => (
    <div>
      <p><strong>From Email:</strong> {formValues.fromEmail}</p>
      {formValues.fromName && <p><strong>From Name:</strong> {formValues.fromName}</p>}
      <p><strong>Provider:</strong> {formValues.provider}</p>

      {/* Conditional fields for Gmail */}
      {formValues.provider === "gmail" && (
        <>
          {formValues.clientId && <p><strong>Client ID:</strong> {formValues.clientId}</p>}
          {formValues.clientSecret && <p><strong>Client Secret:</strong> {formValues.clientSecret}</p>}
          {formValues.refreshToken && <p><strong>Refresh Token:</strong> {formValues.refreshToken}</p>}
        </>
      )}

      {/* Conditional fields for Outlook */}
      {formValues.provider === "outlook" && (
        <>
          {formValues.tenantId && <p><strong>Tenant ID:</strong> {formValues.tenantId}</p>}
          {formValues.clientId && <p><strong>Client ID:</strong> {formValues.clientId}</p>}
          {formValues.clientSecret && <p><strong>Client Secret:</strong> {formValues.clientSecret}</p>}
        </>
      )}

      {/* Conditional fields for AWS SES */}
      {formValues.provider === "aws_ses" && (
        <>
          {formValues.accessKeyId && <p><strong>AWS Access Key ID:</strong> {formValues.accessKeyId}</p>}
          {formValues.secretAccessKey && <p><strong>AWS Secret Access Key:</strong> {formValues.secretAccessKey}</p>}
          {formValues.region && <p><strong>Region:</strong> {formValues.region}</p>}
        </>
      )}

      {/* Conditional fields for Other SMTP */}
      {formValues.provider === "other" && (
        <>
          {formValues.host && <p><strong>Host:</strong> {formValues.host}</p>}
          {formValues.port && <p><strong>Port:</strong> {formValues.port}</p>}
          {formValues.username && <p><strong>Username:</strong> {formValues.username}</p>}
          {formValues.password && <p><strong>Password:</strong> {formValues.password}</p>}
        </>
      )}
    </div>
  );

  return (
    <div>
      <div className="view-header">
        <h4>Email Configuration</h4>
        {!editMode && (
          <button className="btn btn-primary" onClick={toggleEditMode}>
            <i className="fa-solid fa-pencil"></i> Edit
          </button>
        )}
      </div>
      <div className="contact-activity">
        <div className="card">
          <div className="card-body">
            {editMode ? renderForm() : renderViewMode()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntitySmtpSettings;