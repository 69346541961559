import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import DatePicker from "react-datepicker";
import Alert from "../../../Alert";
import moment from "moment";
import { getAuthHeaders, getApiUrl } from "../../../../utils/AuthUtils";

const ExperienceInfoPopup = ( { candidateId, onSubmitSuccess } ) => {
    // State for experience entries
    const [experienceEntries, setExperienceEntries] = useState([
        {
            company: "",
            position: "",
            location: "",
            period_from: null,
            period_to: null,
        },
    ]);
    const headers = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    // State for errors
    const [errors, setErrors] = useState({});

    // Function to handle adding more experience entries
    const handleExperianceAddMore = () => {
        setExperienceEntries([
            ...experienceEntries,
            {
                company: "",
                position: "",
                location: "",
                period_from: null,
                period_to: null,
            },
        ]);
    };

    // Function to handle removing an experience entry
    const handleRemoveEntry = (index) => {
        if (experienceEntries.length > 1) {
            const newEntries = experienceEntries.filter((_, i) => i !== index);
            setExperienceEntries(newEntries);

            // Remove associated errors
            setErrors((prevErrors) => {
                const { [index]: _, ...rest } = prevErrors; // Remove the index-specific error
                return rest;
            });
        }
    };

    // Function to handle input change for each entry
    const handleInputChange = (index, field, value) => {
        const newEntries = [...experienceEntries];
        newEntries[index][field] = value;
        setExperienceEntries(newEntries);
    
        // Clear error when user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: {
                ...prevErrors[index],
                [field]: "",
            },
        }));
    };
    

    // Function to validate each entry
    const validateEntry = (entry) => {
        const entryErrors = {};
        // Validate company
        if (!entry.company.trim()) {
            entryErrors.company = "Company is required";
        }
        
        // Validate position
        if (!entry.position.trim()) {
            entryErrors.position = "Position is required";
        }

        // Validate Starting Date
        if (!entry.period_from) {
            entryErrors.period_from = "Period From Date is required";
        }

        // Validate ending Date
        if (!entry.period_to) {
            entryErrors.period_to = "Period To Date is required";
        } else if (entry.period_from && entry.period_to < entry.period_from) {
            entryErrors.period_to =
                "Period To Date cannot be earlier than Period From Date";
        }

        return entryErrors;
    };

    // Function to check if the form is valid
    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        experienceEntries.forEach((entry, index) => {
            const entryErrors = validateEntry(entry);

            if (Object.keys(entryErrors).length > 0) {
                isValid = false;
                newErrors[index] = entryErrors;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    // Function to handle form submission    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorAlert(false);
    
        if (validateForm()) {
            // Format the start and completion dates
            const formattedEntries = experienceEntries.map((entry) => ({
                ...entry,
                period_from: moment(entry.period_from).format("YYYY-MM-DD"),
                period_to: moment(entry.period_to).format("YYYY-MM-DD"),
            }));
    
            const formattedData = {
                experiences: formattedEntries,
            };
    
            try {
                const response = await axios.post(
                    `${getApiUrl()}/api/candidates/${candidateId}/experiences`,
                    formattedData,
                    { headers }
                );
    
                // Reset form after successful submission
                setExperienceEntries([
                    {
                        company: "",
                        position: "",
                        location: "",
                        period_from: null,
                        period_to: null,
                    },
                ]);
                setErrors({});
                setSuccessAlert(true);
                setTimeout(() => {
                    document.getElementById("modal-experience-info-close").click();
                    setSuccessAlert(false);
                    onSubmitSuccess();
                }, 3000);
            } catch (error) {
                setErrorAlert(true);
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data.errors;
                    const newErrors = {};
    
                    // Map error fields to their respective error messages
                    Object.keys(errorData).forEach((field) => {
                        const [mainField, indexStr, subField] = field.split(".");
                        const index = parseInt(indexStr, 10);
    
                        // Initialize the nested error object if it doesn't exist
                        if (!newErrors[index]) {
                            newErrors[index] = {};
                        }
    
                        // Assign the error message to the appropriate field
                        newErrors[index][subField] = errorData[field][0];
                    });
    
                    // Set errors state to display error messages
                    setErrors(newErrors);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error("Error:", error);
                    // You can show a generic error message to the user if needed
                }
            }
        } else {
            setErrorAlert(true);
        }
    };
    
    return (
        <div
            id="experience_info"
            className="modal custom-modal fade"
            role="dialog"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Experience Information</h5>
                        <button
                            id="modal-experience-info-close"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {showSuccessAlert && <Alert type="success" message="Experience details submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting Experience details." />}
                        <form onSubmit={handleSubmit}>
                            <div className="form-scroll">
                                {experienceEntries.map((entry, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                Experience Information{" "}
                                                <Link
                                                    to="#"
                                                    className={`delete-icon ${experienceEntries.length === 1
                                                        ? "disabled"
                                                        : ""
                                                        }`}
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default link behavior
                                                        handleRemoveEntry(index);
                                                    }}
                                                    style={{
                                                        cursor:
                                                            experienceEntries.length === 1
                                                                ? "not-allowed"
                                                                : "pointer",
                                                        opacity:
                                                            experienceEntries.length === 1
                                                                ? 0.5
                                                                : 1,
                                                    }}
                                                >
                                                    <i className="fa-regular fa-trash-can" />
                                                </Link>
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Company <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.company}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "company",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                        {errors[index]?.company && (
                                                            <div className="text-danger">
                                                                {errors[index].company}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Position <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.position}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "position",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                        {errors[index]?.position && (
                                                            <div className="text-danger">
                                                                {errors[index].position}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Location 
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.location}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "location",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                        {errors[index]?.location && (
                                                            <div className="text-danger">
                                                                {errors[index].location}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Period From <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="cal-icon">
                                                            <DatePicker
                                                                selected={entry.period_from}
                                                                onChange={(date) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        "period_from",
                                                                        date
                                                                    )
                                                                }
                                                                className="form-control floating datetimepicker"
                                                                dateFormat="dd-MM-yyyy"
                                                            />
                                                        </div>
                                                        {errors[index]?.period_from && (
                                                            <div className="text-danger">
                                                                {errors[index].period_from}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Period To <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="cal-icon">
                                                            <DatePicker
                                                                selected={entry.period_to}
                                                                onChange={(date) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        "period_to",
                                                                        date
                                                                    )
                                                                }
                                                                className="form-control floating datetimepicker"
                                                                dateFormat="dd-MM-yyyy"
                                                            />
                                                        </div>
                                                        {errors[index]?.period_to && (
                                                            <div className="text-danger">
                                                                {errors[index].period_to}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="add-more">
                                <Link to="#" onClick={handleExperianceAddMore}>
                                    <i className="fa-solid fa-plus-circle" /> Add
                                    More
                                </Link>
                            </div>
                            <div className="submit-section">
                                <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExperienceInfoPopup;
