import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import { getLocalStorageJSONData, setLocalStorageJSONData } from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  status: Yup.object().required("Please select status"),
});

const EditBranchTypeModal = (props) => {
  const headers = getAuthHeaders();
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    status: null,
  });
  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        const response = await axios.get(
          `${getApiUrl()}/api/masters/status-masters`,
          {
            headers,
          }
        );
        if (response) {
          setLocalStorageJSONData("status", response.data.data.status);
        }
        setStatusOptions(getLocalStorageJSONData("status"));
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchBranchData();
  }, []);

  const onSubmitBranchType = async (data) => {
    const formmattedData = {
      id: props.branchType.id,
      name: data.name,
      status: data.status.value,
    };

    try {
      const response = await axios.put(
        `${getApiUrl()}/api/masters/branch-types/update`,
        formmattedData,
        {
          headers,
        }
      );

      // Reset form after successful submission
      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        document.getElementById("close-edit-modal-branch-type").click();
        document.getElementById("success_update_btn_branch_type").click();
        props.handleUpdateList();
      }
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };
  useEffect(() => {
    const fetchBranchData = async () => {
      try {
        if (props.branchType) {
          const response = await axios.get(
            `${getApiUrl()}/api/masters/branch-types/${props.branchType.id}`,
            {
              headers,
            }
          );
          const editFormData = {
            ...formData,
            ...response.data.data,
            status: statusOptions.filter(
              (status) => status.value === response.data.data.status
            )[0],
          };
          reset(editFormData);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchBranchData();
  }, [props.branchType]);

  return (
    <div>
      {/* Edit Branch Type */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_branch_type"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Edit Branch Type
              </h5>
              <button
                type="button"
                id="close-edit-modal-branch-type"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                {loading && <PreLoader showLoader={loading} />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(onSubmitBranchType)}
                    id="add_branch_type_form"
                  >
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                             Name <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.name?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="status"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.status ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={statusOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.status?.message}{" "}
                            </span>
                          </div>
                        </div>
                        {showErrors && (
                          <>
                            <div className="col-sm-12 mb-3">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )} 
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights"
                            type="button"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary d-none"
                            type="button"
                            id="success_update_btn_branch_type"
                            data-bs-toggle="modal"
                            data-bs-target="#success_update_msg_branch_type"
                          >
                            Success
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Branch Type */}
      {/* Success Branch Type */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_branch_type"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Branch Type Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Branch Type */}
    </div>
  );
};

export default EditBranchTypeModal;
