import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import { getLocalStorageJSONData, setLocalStorageJSONData } from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  status: Yup.object().required("Please select status"),
});

const AddRoleModal = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const location = useLocation();
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPermissionAllChecked, setIsPermissionAllChecked] = useState([]);
  const [isModuleChecked, setIsModuleChecked] = useState([]);
  const [isModulePermissionsChecked, setIsModulePermissionsChecked] = useState(
    []
  );
  const [isSubmoduleChecked, setIsSubmoduleChecked] = useState([]);
  const [isSubmodulePermissionsChecked, setIsSubmodulePermissionsChecked] =
    useState([]);

  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    status: null,
  });
  const [permissionsList, setPermissionsList] = useState(
    localStorage.getItem("permissions")
      ? getLocalStorageJSONData("permissions")
      : []
  );
  const [modulesList, setModulesList] = useState(
    localStorage.getItem("modules")
      ? getLocalStorageJSONData("modules")
      : []
  );

  const initPermissions = () => {
    const submodulesList = [];
    const submodulePermissionsList = [];
    setIsPermissionAllChecked(
      permissionsList.map((permission) => ({ [permission.id]: false }))
    );
    setIsModuleChecked(
      modulesList.map((module) => {
        submodulesList.push(
          module.submodules.map((submodule) => {
            return { [submodule.id]: false };
          })
        );
        return { [module.id]: false };
      })
    );
    setIsSubmoduleChecked(submodulesList);
    setIsModulePermissionsChecked(
      modulesList.map((module) => {
        submodulePermissionsList.push(
          module.submodules.map((submodule) => {
            return {
              [submodule.id]: permissionsList.map((permission) => ({
                [permission.id]: false,
              })),
            };
          })
        );
        return {
          [module.id]: permissionsList.map((permission) => ({
            [permission.id]: false,
          })),
        };
      })
    );
    setIsSubmodulePermissionsChecked(submodulePermissionsList);
  };

  const updateModuleCheckbox = (module_id, value) => {
    setIsModuleChecked((prev) => {
      return prev.map((item) => {
        return item[module_id] !== undefined ? { [module_id]: value } : item;
      });
    });
    setIsModulePermissionsChecked((prev) => {
      return prev.map((item) => {
        return item[module_id] !== undefined
          ? {
              [module_id]: permissionsList.map((permission) => ({
                [permission.id]: value,
              })),
            }
          : item;
      });
    });
    const moduleData = modulesList.find((module) => {
      return module.id === module_id ? module : false;
    });
    const moduleIndex = modulesList.findIndex((module) => {
      return module.id === module_id ? module : false;
    });
    if (moduleIndex !== -1 && moduleData && moduleData.submodules.length) {
      moduleData.submodules.forEach((submodule, submodule_index) => {
        updateSubmoduleCheckbox(
          moduleIndex,
          submodule_index,
          submodule.id,
          value
        );
      });
    }
  };

  const updateSubmoduleCheckbox = (
    module_index,
    submodule_index,
    submodule_id,
    value
  ) => {
    // Update submodule checkbox state
    setIsSubmoduleChecked((prev) => {
      // Create a deep copy of the previous state
      const newState = prev.map((module, i) =>
        module.map((submodule, j) =>
          i === module_index && j === submodule_index
            ? { [submodule_id]: value }
            : submodule
        )
      );
      return newState;
    });
    // Update submodule permissions checkbox state
    setIsSubmodulePermissionsChecked((prev) => {
      const newPermissionsState = prev.map((module, i) =>
        module.map((submodule, j) =>
          i === module_index && j === submodule_index
            ? {
                [submodule_id]: permissionsList.map((permission) => ({
                  [permission.id]: value,
                })),
              }
            : submodule
        )
      );
      return newPermissionsState;
    });
  };

  const handleCheckAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsPermissionAllChecked(
      permissionsList.map((permission) => ({ [permission.id]: !isCheckAll }))
    );
    modulesList.forEach((module) => {
      updateModuleCheckbox(module.id, !isCheckAll);
    });
  };
  const handlePermissionAll = ( key, id) => {
    // Toggle the "checked" state for the specific permission
    const newPermissions = [...isPermissionAllChecked];
    newPermissions[key] = {
      ...newPermissions[key],
      [id]: !newPermissions[key][id],
    };
    const value = newPermissions[key][id];

    // Update the permissions state
    setIsPermissionAllChecked(newPermissions);

    // Update the permissions for all modules and submodules
    setIsModulePermissionsChecked((prev) =>
      prev.map((item) => {
        const moduleId = parseInt(Object.keys(item)[0], 10);
        if (item[moduleId]) {
          return {
            [moduleId]: item[moduleId].map((permission) => {
              if (permission[id] !== undefined) {
                return { [id]: value };
              }
              return permission;
            }),
          };
        }
        return item;
      })
    );

    setIsSubmodulePermissionsChecked((prev) =>
      prev.map((module) =>
        module.map((submodule) => {
          const submoduleId = parseInt(Object.keys(submodule)[0], 10);
          if (submodule[submoduleId]) {
            return {
              [submoduleId]: submodule[submoduleId].map((permission) => {
                if (permission[id] !== undefined) {
                  return { [id]: value };
                }
                return permission;
              }),
            };
          }
          return submodule;
        })
      )
    );
  };

  const handleModuleChecked = (key, id) => {
    updateModuleCheckbox(id, !isModuleChecked[key][id]);
  };
  const handleModulePermissionChecked = (
    e,
    key_m,
    module_id,
    key_p,
    permission_id
  ) => {
    const newModulePermissionsChecked = [...isModulePermissionsChecked];
    newModulePermissionsChecked[key_m][module_id][key_p][permission_id] =
      e.target.checked;
    setIsModulePermissionsChecked(newModulePermissionsChecked);
  };
  const handleSubmoduleChecked = (key_m, key_s, s_id) => {
    updateSubmoduleCheckbox(
      key_m,
      key_s,
      s_id,
      !isSubmoduleChecked[key_m][key_s][s_id]
    );
  };
  const handleSubmodulePermissionChecked = (
    e,
    key_m,
    key_s,
    submodule_id,
    key_p,
    permission_id
  ) => {
    setIsSubmodulePermissionsChecked((prev) => {
      // Create a deep copy of the previous state
      return prev.map((module, key_m_index) => {
        if (key_m_index === key_m) {
          // If the correct module, update the submodule
          return module.map((submodule, key_s_index) => {
            if (key_s_index === key_s) {
              // If the correct submodule, update the permissions
              return {
                ...submodule,
                [submodule_id]: submodule[submodule_id].map(
                  (permission, index) => {
                    if (index === key_p) {
                      // Update the specific permission
                      return {
                        ...permission,
                        [permission_id]: e.target.checked,
                      };
                    }
                    return permission;
                  }
                ),
              };
            }
            return submodule;
          });
        }
        return module;
      });
    });
  };

  // Function to check if at least one checkbox is selected
  const isAnyCheckboxSelected = () => {
    let check = false;
    isModulePermissionsChecked.forEach((module) => {
      if (check) return;
      const moduleId = Object.keys(module)[0];
      module[moduleId].forEach((permission) => {
        if (check) return;
        const permissionId = Object.keys(permission)[0];
        if (permission[permissionId]) {
          check = true;
        }
      });
    });
    isSubmodulePermissionsChecked.forEach((module) => {
      if (check) return;
      module.forEach((submodule) => {
        if (check) return;
        const submoduleId = Object.keys(submodule)[0];
        submodule[submoduleId].forEach((permission) => {
          if (check) return;
          const permissionId = Object.keys(permission)[0];
          if (permission[permissionId]) {
            check = true;
          }
        });
      });
    });
    return check;
  };

  const {
    reset,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [statusOptions, setStatusOptions] = useState([]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      setFormData({ ...formData });
      reset(formData);
      setShowErrors(false);
    },
  }));
  const fetchRoleData = async () => {
    try {
      const response = await axios
        .get(`${getApiUrl()}/api/masters/roles-masters`, {
          headers,
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("redirect_path", location.pathname);
              navigate(`/login`);
            }
          }
        });
      if (response) {
        setLocalStorageJSONData("status", response.data.data.status);
        setLocalStorageJSONData("permissions", response.data.data.permissions);
        setLocalStorageJSONData("modules", response.data.data.modules);
      }
      setStatusOptions(getLocalStorageJSONData("status"));
      setPermissionsList(getLocalStorageJSONData("permissions"));
      setModulesList(getLocalStorageJSONData("modules"));
      initPermissions();
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetchRoleData();
  }, []);

  const onSubmitRole = async (data) => {
    setShowErrors(false);
    // Validate checkbox selection
    if (!isAnyCheckboxSelected()) {
      setErrorsMessage("At least one permission must be selected.");
      setShowErrors(true);
      return;
    }

    const formmattedData = {
      title: data.title,
      description: data.description,
      status: data.status.value,
      module_permissions: isModulePermissionsChecked,
      submodule_permissions: isSubmodulePermissionsChecked,
    };

    try {
      await axios.post(
        `${getApiUrl()}/api/masters/roles/create`,
        formmattedData,
        {
          headers,
        }
      );

      // Reset form after successful submission
      setFormData({ ...formData });
      reset(formData);
      document.getElementById("close-modal-role").click();
      document.getElementById("success_btn_role").click();
      initPermissions();
      props.handleUpdateList();
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      {/* Add Role */}
      <div
        className="modal custom-modal fade modal-padding"
        id="add_role"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Add New Role</h5>
              <button
                type="button"
                id="close-modal-role"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                <form onSubmit={handleSubmit(onSubmitRole)} id="add_role_form">
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Title <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="title"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.title ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.title?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={statusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.status?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Description</label>
                          <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                className="form-control"
                                rows={5}
                                defaultValue={""}
                                onChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <div className="table-responsive m-t-15">
                          <table className="table table-striped custom-table">
                            <thead>
                              <tr>
                                <th>
                                  <label className="custom_check">
                                    <input
                                      type="checkbox"
                                      id="check_all"
                                      onChange={handleCheckAll}
                                      checked={isCheckAll}
                                    />
                                    <span className="checkmark" />
                                  </label>
                                  <span className="custom_title">
                                    Module Permission
                                  </span>
                                </th>
                                {permissionsList.map((permission, key) => (
                                  <th key={key} className="text-center">
                                    <label className="custom_check">
                                      <input
                                        type="checkbox"
                                        id={`permission_${permission.id}`}
                                        onChange={() =>
                                          handlePermissionAll(
                                            key,
                                            permission.id
                                          )
                                        }
                                        checked={
                                          isPermissionAllChecked[key] &&
                                          isPermissionAllChecked[key][
                                            permission.id
                                          ]
                                        }
                                      />
                                      <span className="checkmark" />
                                    </label>
                                    <span className="custom_title">
                                      {permission.title}
                                    </span>
                                  </th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {modulesList.map((module, key_m) => {
                                return (
                                  <>
                                    <tr key={key_m}>
                                      <td>
                                        <label className="custom_check">
                                          <input
                                            type="checkbox"
                                            id={`module_${module.id}`}
                                            onChange={() =>
                                              handleModuleChecked(
                                                key_m,
                                                module.id
                                              )
                                            }
                                            checked={
                                              isModuleChecked[key_m] &&
                                              isModuleChecked[key_m][module.id]
                                            }
                                          />
                                          <span className="checkmark" />
                                        </label>
                                        <span className="custom_title">
                                          {module.title}
                                        </span>
                                      </td>
                                      {permissionsList.map(
                                        (permission, key_p) => {
                                          return (
                                            <td
                                              key={key_p}
                                              className="text-center"
                                            >
                                              <label className="custom_check">
                                                <input
                                                  type="checkbox"
                                                  id={`module_permission_${module.id}_${permission.id}`}
                                                  onChange={(e) =>
                                                    handleModulePermissionChecked(
                                                      e,
                                                      key_m,
                                                      module.id,
                                                      key_p,
                                                      permission.id
                                                    )
                                                  }
                                                  checked={
                                                    isModulePermissionsChecked[
                                                      key_m
                                                    ] &&
                                                    isModulePermissionsChecked[
                                                      key_m
                                                    ][module.id] &&
                                                    isModulePermissionsChecked[
                                                      key_m
                                                    ][module.id][key_p] &&
                                                    isModulePermissionsChecked[
                                                      key_m
                                                    ][module.id][key_p][
                                                      permission.id
                                                    ]
                                                  }
                                                />
                                                <span className="checkmark" />
                                              </label>
                                            </td>
                                          );
                                        }
                                      )}
                                    </tr>
                                    {module.submodules.length > 0 &&
                                      module.submodules.map(
                                        (submodule, key_s) => {
                                          return (
                                            <tr key={`${key_m}_${key_s}`}>
                                              <td>
                                                <div className="submodule_title">
                                                  <label className="custom_check">
                                                    <input
                                                      type="checkbox"
                                                      id={`module_${module.id}`}
                                                      onChange={() =>
                                                        handleSubmoduleChecked(
                                                          key_m,
                                                          key_s,
                                                          submodule.id
                                                        )
                                                      }
                                                      checked={
                                                        isSubmoduleChecked[
                                                          key_m
                                                        ] &&
                                                        isSubmoduleChecked[
                                                          key_m
                                                        ][key_s] &&
                                                        isSubmoduleChecked[
                                                          key_m
                                                        ][key_s][submodule.id]
                                                      }
                                                    />
                                                    <span className="checkmark" />
                                                  </label>
                                                  <span className="custom_title">
                                                    {submodule.title}
                                                  </span>
                                                </div>
                                              </td>
                                              {permissionsList.map(
                                                (permission, key_p) => {
                                                  return (
                                                    <td
                                                      key={key_p}
                                                      className="text-center"
                                                    >
                                                      <label className="custom_check">
                                                        <input
                                                          type="checkbox"
                                                          id={`submodule_permission_${submodule.id}_${permission.id}`}
                                                          onChange={(e) =>
                                                            handleSubmodulePermissionChecked(
                                                              e,
                                                              key_m,
                                                              key_s,
                                                              submodule.id,
                                                              key_p,
                                                              permission.id
                                                            )
                                                          }
                                                          checked={
                                                            isSubmodulePermissionsChecked[
                                                              key_m
                                                            ] &&
                                                            isSubmodulePermissionsChecked[
                                                              key_m
                                                            ][key_s] &&
                                                            isSubmodulePermissionsChecked[
                                                              key_m
                                                            ][key_s][
                                                              submodule.id
                                                            ] &&
                                                            isSubmodulePermissionsChecked[
                                                              key_m
                                                            ][key_s][
                                                              submodule.id
                                                            ][key_p] &&
                                                            isSubmodulePermissionsChecked[
                                                              key_m
                                                            ][key_s][
                                                              submodule.id
                                                            ][key_p][
                                                              permission.id
                                                            ]
                                                          }
                                                        />
                                                        <span className="checkmark" />
                                                      </label>
                                                    </td>
                                                  );
                                                }
                                              )}
                                            </tr>
                                          );
                                        }
                                      )}
                                  </>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      {showErrors && (
                        <>
                          <div className="col-sm-12 mb-3">
                            <Alert
                              message={errorsMessage}
                              type="danger"
                              close={true}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights reset-btn"
                          type="reset"
                          onClick={() => {
                            setFormData({ ...formData });
                            reset(formData);
                            setShowErrors(false);
                          }}
                        >
                          Reset
                        </button>
                        <button className="btn btn-primary" type="submit">
                          Save
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_btn_role"
                          data-bs-toggle="modal"
                          data-bs-target="#success_msg_role"
                        >
                          Success
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role */}
      {/* Success Role */}
      <div
        className="modal custom-modal fade"
        id="success_msg_role"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Role Created Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Role */}
    </div>
  );
});

export default AddRoleModal;
