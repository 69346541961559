import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Alert from "../../Alert";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";

const StatusUpdateCurrencyModal = (props) => {
  const headers = getAuthHeaders();
  const [successAlert, setSuccessAlert] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const newStatus = props.newStatus;

  const handleUpdateStatusCurrency = async () => {
    setShowButton(false);
    try {
      const formattedData = {
        id: props.currency.id,
        status: newStatus.toLowerCase(),
      };
      const response = await axios.patch(
        `${getApiUrl()}/api/masters/currencies/update-status`,
        formattedData,
        {
          headers,
        }
      );

      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        setSuccessAlert(true);
        props.handleUpdateList();
        setTimeout(() => {
          setSuccessAlert(false);
          setShowButton(true);
          document
            .getElementById("close-update-status-modal-currency")
            .click();
        }, 3000);
      }
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status === 400) {
        // const errorData = error.response.data.message;
        // setErrors(newErrors);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };

  return (
    <div>
      {/* Delete Company */}
      <div
        className="modal custom-modal fade"
        id="change_status_currency"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                {successAlert ? (
                  <div className="col-sm-12 mb-3">
                    <Alert
                      message="Currency status updated successfully."
                      type="success"
                      close={false}
                    />
                  </div>
                ) : (
                  <>
                    <h3>
                      Are you sure, you want to update the status to{" "}
                      {newStatus && newStatus}?
                    </h3>
                  </>
                )}

                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-update-status-modal-currency"
                  >
                    Close
                  </Link>
                  {showButton && (
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={handleUpdateStatusCurrency}
                    >
                      Yes
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Company */}
    </div>
  );
};

export default StatusUpdateCurrencyModal;
