import React from "react";

const PersonalExcellence = () => {
  return (
    <>
      <section className="review-section personal-excellence">
        <div className="review-header text-center">
          <h3 className="review-title">Personal Excellence</h3>
          <p className="text-muted"></p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <table className="table table-bordered review-table mb-0">
                <thead>
                  <tr>
                    <th className="width-pixel">#</th>
                    <th>Personal Attributes</th>
                    <th>Key Indicators</th>
                    <th>Weightage</th>
                    <th>
                      Percentage achieved <br />( self Score )
                    </th>
                    <th>
                      Points Scored <br />( self )
                    </th>
                    <th>
                      Percentage achieved <br />( RM's Score )
                    </th>
                    <th>
                      Points Scored <br />( RM )
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={2}>1</td>
                    <td rowSpan={2}>Attendance</td>
                    <td>Planned or Unplanned Leaves</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={95} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={1.9}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Time Consciousness</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100}  />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>2</td>
                    <td rowSpan={2}>Attitude &amp; Behavior</td>
                    <td>Team Collaboration</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={95}  />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={1.9}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={80} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={1.6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Professionalism &amp; Responsiveness</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Policy &amp; Procedures </td>
                    <td>Adherence to policies and procedures</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={90} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={1.8}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>4</td>
                    <td>Initiatives</td>
                    <td>Special Efforts, Suggestions,Ideas,etc.</td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={2}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={90} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={1.8}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Continuous Skill Improvement</td>
                    <td>
                      Preparedness to move to next level &amp; Training
                      utilization
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={3}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={75} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={2.25}
                      />
                    </td>
                    <td>
                      <input type="text" className="form-control"  value={100} />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={3}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center">
                      Total{" "}
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={15}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={91}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={13.65}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={97.33}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={14.6}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={3} className="text-center">
                      <b>Total Percentage(%)</b>
                    </td>
                    <td colSpan={5} className="text-center">
                      <input
                        type="text"
                        className="form-control"
                        readOnly
                        defaultValue={0}
                        value={79.63}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={8} className="text-center">
                      <div className="grade-span">
                        <h4>Grade</h4>
                        <span className="badge bg-inverse-danger me-1">
                          Below 65 Poor
                        </span>
                        <span className="badge bg-inverse-warning me-1">
                          65-74 Average
                        </span>
                        <span className="badge bg-inverse-info me-1">
                          75-84 Satisfactory
                        </span>
                        <span className="badge bg-inverse-purple me-1">
                          85-92 Good
                        </span>
                        <span className="badge bg-inverse-success">
                          Above 92 Excellent
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PersonalExcellence;
