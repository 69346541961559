import { Table } from "antd";
import React, { useEffect, useState,
  forwardRef,
  useImperativeHandle, } from "react";
import axios from "axios";
import moment from "moment";
import { Link } from "react-router-dom";

import EditUserGroupModal from "../../../../components/Administration/UserGroups/EditUserGroupModal";
import StatusUpdateUserGroupModal from "../../../../components/Administration/UserGroups/StatusUpdateUserGroupModal";
import DeleteUserGroupModal from "../../../../components/Administration/UserGroups/DeleteUserGroupModal";
import { getAuthHeaders, getApiUrl } from "../../../../utils/AuthUtils";

const UserGroupsTable = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const [userGroupsData, setUserGroupsData] = useState([]);
  const [userGroupData, setUserGroupData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  useImperativeHandle(ref, () => ({
    updateUserGroupsList() {
      fetchData();
    },
  }));

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() + `/api/user-groups`,
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let userGroupData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      userGroupData = userGroupData.map((item) => {
        return {
          ...item,
          // image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setUserGroupsData(userGroupData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      render: (text, record) => {
        return moment(record.created_at).format("D MMMM YYYY, hh:mm A");
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_user_group"
                onClick={(e) => {
                  e.preventDefault();
                  setUserGroupData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_user_group"
              onClick={(e) => {
                e.preventDefault();
                setUserGroupData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_user_group"
              onClick={(e) => {
                e.preventDefault();
                setUserGroupData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="filter-section filter-bottom">
        <ul>
          <li>
            <div className="search-set">
              <div className="search-input">
                <Link to="#" className="btn btn-searchset">
                  <i className="las la-search" />
                </Link>
                <div className="dataTables_filter">
                  <label>
                    {" "}
                    <input
                      type="search"
                      className="form-control form-control-sm"
                      placeholder="Search"
                      onChange={handleSearchTextChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              className="table-striped"
              columns={columns}
              style={{ overflowX: "auto" }}
              dataSource={userGroupsData}
              rowKey={(record) => record.id}
              pagination={{
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
              }}
              onChange={handleTableChange}
            />
            <EditUserGroupModal
              userGroup={userGroupData}
              handleUpdateList={handleUpdateList}
            />
            <StatusUpdateUserGroupModal
              userGroup={userGroupData}
              handleUpdateList={handleUpdateList}
              newStatus={newStatus}
            />
            <DeleteUserGroupModal
              userGroup={userGroupData}
              handleUpdateList={handleUpdateList}
            />
          </div>
        </div>
      </div>
    </>
  );
});

export default UserGroupsTable;
