import React, { useEffect, useState } from "react";
import { TagsInput } from "react-tag-input-component";

const TagsSuggestion = (props) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    setSuggestions(props.suggestions);
    if(props.value.length){
      setSelectedTags(props.value);
    }
  }, [props.suggestions]);

  // Handle input change and show/hide the dropdown
  const handleInputChange = (value) => {
    setInputValue(value);
    setShowDropdown(value.length > 0);
  };

  // Filter the suggestions based on the label
  const filteredSuggestions = suggestions.filter((suggestion) => {
    return (
      suggestion.label &&
      suggestion.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  });

  // Handle adding a tag from filtered suggestions
  const handleAddTag = (tag) => {
    if (!selectedTags.some((t) => t.label === tag.label)) {
      setSelectedTags([...selectedTags, tag]);
    }
    setInputValue(""); // Clear the input after adding a tag
    setShowDropdown(false); // Hide the dropdown after adding a tag
  };

  // Handle selecting a suggestion from the dropdown
  const handleSelectSuggestion = (suggestion, element) => {
    handleAddTag(suggestion);
    setInputValue(""); // Clear the input text
    setShowDropdown(false);
    clearClosestInput(element);
  };

  // Function to clear the closest input field with class "rti--input"
  const clearClosestInput = (element) => {
    const parentDiv = element.closest("div").parentNode.parentNode; // Find closest parent div
    const inputField = parentDiv.querySelector(".rti--input"); // Find the input field within that div
    if (inputField) {
      inputField.value = ""; // Clear the input value
    }
  };

  const handleTags = (tags) => {
    props.getUpdatedTags({[props.name]: tags});  // Call parent function to pass value
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <TagsInput
          {...props}
          value={selectedTags.map((tag) => tag.label)} // Use the label for TagsInput display
          onChange={(tags) => {
            const newSelectedTags = tags.map((tagLabel) => {
              const tagObj = suggestions.find(
                (suggestion) => suggestion.label === tagLabel
              );
              return tagObj || { value: tagLabel, label: tagLabel }; // Fallback if it's a custom tag
            });
            setSelectedTags(newSelectedTags);
            handleTags(newSelectedTags);
            setInputValue("");
          }}
          placeHolder="Search..."
          onKeyUp={(e) => handleInputChange(e.target.value)}
          classNames={{
            tagInputField: "rti--input", // Apply custom class to input
          }}
        />
        {showDropdown && (
          <div className="suggestion-dropdown">
            {filteredSuggestions.length > 0 && (
              filteredSuggestions.map((suggestion, index) => (
                <div
                  key={index}
                  onClick={(e) => handleSelectSuggestion(suggestion, e.currentTarget)} // Correct way to pass the event
                  className="suggestion-dropdown-item"
                >
                  {suggestion.label}
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default TagsSuggestion;
