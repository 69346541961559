import React from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { MedExpertLogo } from "../../../../../Routes/ImagePath";
import Footer from "../../../../layout/Footer";

const PaySlip = () => {
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Payslip"
          title="Dashboard"
          subtitle="Payslip"
          modal="#add_categories"
          name="Add Salary"
        />

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="payslip-title">
                  Payslip for the month of Jun 2024
                </h4>
                <div className="row">
                  <div className="col-sm-6 m-b-20">
                    <img src={MedExpertLogo} className="inv-logo" alt="Logo" />
                    <ul className="list-unstyled mb-0">
                      <li>MedExpert Billing and Consulting Pvt Ltd</li>
                      <li>Tower A, 5th Floor, Door No 111, KRC Commerzone Mount-Poonamallee Road,
							 </li>
                      <li>Porur, Sriperumbudur, Kanchipuram, 
                      TN 600116 IN</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 m-b-20">
                    <div className="invoice-details">
                      <h3 className="text-uppercase">Payslip #49029</h3>
                      <ul className="list-unstyled">
                        <li>
                          Salary Month: <span>June, 2024</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="mb-0">
                          <strong>Anil Choudhary</strong>
                        </h5>
                      </li>
                      <li>
                        <span>CEO</span>
                      </li>
                      <li>Employee ID: MED-1203</li>
                      <li>Joining Date: 18 Jun 2020</li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Earnings</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Basic Salary</strong>{" "}
                              <span className="float-end">₹65000</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>House Rent Allowance (H.R.A.)</strong>{" "}
                              <span className="float-end">₹32500</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Conveyance</strong>{" "}
                              <span className="float-end">₹5500</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Other Allowance</strong>{" "}
                              <span className="float-end">₹5500</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Earnings</strong>{" "}
                              <span className="float-end">
                                <strong>₹108500</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Deductions</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Tax Deducted at Source (T.D.S.)</strong>{" "}
                              <span className="float-end">₹5000</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Professional Tax (P.T.)</strong>{" "}
                              <span className="float-end">₹200</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Provident Fund</strong>{" "}
                              <span className="float-end">₹1800</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>ESI</strong>{" "}
                              <span className="float-end">₹0</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Salary Advance</strong>{" "}
                              <span className="float-end">₹300</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Deductions</strong>{" "}
                              <span className="float-end">
                                <strong>₹7300</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      <strong>Net Salary: ₹101200</strong> (One lakh one thousand
                      two hundred only.)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PaySlip;
