import React, { useEffect, useState } from "react";
import axios from "axios";

import { ts_logo } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Select from "react-select";
import AddEntityModal from "./AddEntityModal";
import DateRangePicker from "react-bootstrap-daterangepicker";
import AddNotes from "../modelpopup/Crm/AddNotes";
import EditEntityModal from "./EditEntityModal";
import DeleteEntityModal from "./DeleteEntityModal";
import StatusUpdateEntityModal from "./StatusUpdateEntityModal";
import { getAuthHeaders , getApiUrl} from "../../utils/AuthUtils";

const Entities = () => {
  const headers = getAuthHeaders();
  const [companyId, setCompanyId] = useState(null);
  const [entityData, setEntityData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [entitiesData, setEntitiesData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        getApiUrl() + "/api/entities/",
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let entityData = response.data.data.data;
      let entityChildrenData = response.data.data.children;
      setTotalRecords(response.data.data.total);
      entityData = entityData.map((item) => {
        let children = entityChildrenData.filter(
          (entityChild) => entityChild.parent_id === item.id
        );
        if (children) {
          children = children.map((child) => {
            return {
              ...child,
              image: ts_logo,
              status: child.status === "active" ? "Active" : "Inactive",
              backgroundColor: "yellow",
            };
          });
        }
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
          children: children.length > 0 ? children : false,
        };
      });
      setEntitiesData(entityData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const [showFilter, setShowFilter] = useState(false);
  const [fieldInputs, setFieldInputs] = useState(false);
  const [focused, setFocused] = useState(false);
  const [focusedTwo, setFocusedTwo] = useState(false);
  const [focusedThree, setFocusedThree] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueTwo, setInputValueTwo] = useState("");
  const [inputValueThree, setInputValueThree] = useState("");

  const columns = [
    {
      title: "Name",
      dataIndex: "entity_name",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <Link
                to={`/company-details/${record.id}`}
                className="company-img"
              >
                <img src={record.logo_file && record.logo_file.file_path} alt="UserImage" />
              </Link>
              <Link
                to={`/company-details/${record.id}`}
                className="profile-split"
              >
                {record.entity_name}
              </Link>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Code",
      dataIndex: "entity_code",
      sorter: true,
    },
    {
      title: "Type",
      dataIndex: "entity_type",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: true,
    },
    {
      title: "City",
      dataIndex: "city",
      sorter: true,
    },
    // {
    //   title: "PAN",
    //   dataIndex: "pan",
    //   sorter: true,
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_company"
                onClick={(e) => {
                  e.preventDefault();
                  setEntityData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
      sorter: true,
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            {/* <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_company"
              onClick={(e) => {
                e.preventDefault();
                setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link> */}
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_company"
              onClick={(e) => {
                e.preventDefault();
                setEntityData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
            <Link
              className="dropdown-item"
              to={`/company-details/${record.id}`}
            >
              <i className="fa-regular fa-eye"></i> Preview
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const optionSort = [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "Canada", label: "Canada" },
    { value: "China", label: "China" },
  ];

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (value !== "" && !focused) {
      setFocused(true);
    }
  };

  const handleLabelClickTwo = () => {
    setFocusedTwo(true);
  };

  const handleInputBlurTwo = () => {
    if (inputValueTwo === "") {
      setFocusedTwo(false);
    }
  };
  const handleInputChangeTwo = (e) => {
    const value = e.target.value;
    setInputValueTwo(value);
    if (value !== "" && !focusedTwo) {
      setFocusedTwo(true);
    }
  };

  const handleLabelClickThree = () => {
    setFocusedThree(true);
  };

  const handleInputBlurThree = () => {
    if (inputValueThree === "") {
      setFocusedThree(false);
    }
  };
  const handleInputChangeThree = (e) => {
    const value = e.target.value;
    setInputValueThree(value);
    if (value !== "" && !focusedThree) {
      setFocusedThree(true);
    }
  };

  const initialSettings = {
    endDate: new Date("2020-08-11T12:30:00.000Z"),
    ranges: {
      "Last 30 Days": [
        new Date("2020-07-12T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last 7 Days": [
        new Date("2020-08-04T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      "Last Month": [
        new Date("2020-06-30T18:30:00.000Z"),
        new Date("2020-07-31T18:29:59.999Z"),
      ],
      "This Month": [
        new Date("2020-07-31T18:30:00.000Z"),
        new Date("2020-08-31T18:29:59.999Z"),
      ],
      Today: [
        new Date("2020-08-10T04:57:17.076Z"),
        new Date("2020-08-10T04:57:17.076Z"),
      ],
      Yesterday: [
        new Date("2020-08-09T04:57:17.076Z"),
        new Date("2020-08-09T04:57:17.076Z"),
      ],
    },
    startDate: new Date("2020-08-04T04:57:17.076Z"), // Set "Last 7 Days" as default
    timePicker: false,
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-4">
              <h3 className="page-title">Companies</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Companies</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                {/* <div className="view-icons">
                  <Link
                    to="#"
                    className={
                      fieldInputs
                        ? "list-view btn btn-link active-filter"
                        : "list-view btn btn-link"
                    }
                    id="filter_search"
                    onClick={() => setFieldInputs(!fieldInputs)}
                  >
                    <i className="las la-filter" />
                  </Link>
                </div>
                <div className="form-sort">
                  <Link
                    to="#"
                    className="list-view btn btn-link"
                    data-bs-toggle="modal"
                    data-bs-target="#export"
                  >
                    <i className="las la-file-export" />
                    Export
                  </Link>
                </div> */}
                <Link
                  to="#"
                  className="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_company"
                >
                  <i className="la la-plus-circle" /> Add Company
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        {/* Search Filter */}
        <div
          className="filter-filelds"
          id="filter_inputs"
          style={{ display: fieldInputs ? "block" : "none" }}
        >
          <div className="row filter-row">
            <div className="col-xl-2">
              <div
                className={
                  focused || inputValue !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValue}
                  onFocus={handleLabelClick}
                  onBlur={handleInputBlur}
                  onChange={handleInputChange}
                />
                <label className="focus-label" onClick={handleLabelClick}>
                  Company Name
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className={
                  focusedTwo || inputValueTwo !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueTwo}
                  onFocus={handleLabelClickTwo}
                  onBlur={handleInputBlurTwo}
                  onChange={handleInputChangeTwo}
                />
                <label className="focus-label" onClick={handleLabelClickTwo}>
                  Email
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div
                className={
                  focusedThree || inputValueThree !== ""
                    ? "input-block form-focus focused"
                    : "input-block form-focus"
                }
              >
                <input
                  type="text"
                  className="form-control floating"
                  value={inputValueThree}
                  onFocus={handleLabelClickThree}
                  onBlur={handleInputBlurThree}
                  onChange={handleInputChangeThree}
                />
                <label className="focus-label" onClick={handleLabelClickThree}>
                  Phone Number
                </label>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="input-block mb-3 form-focus focused">
                <DateRangePicker initialSettings={initialSettings}>
                  <input
                    className="form-control  date-range bookingrange"
                    type="text"
                  />
                </DateRangePicker>
                <label className="focus-label">From - To Date</label>
              </div>
            </div>
            <div className="col-xl-2">
              <div className="input-block mb-3 form-focus select-focus">
                <Select
                  options={optionSort}
                  placeholder="--Select--"
                  className="select floating"
                  isSearchable={false}
                  styles={customStyles}
                />
                <label className="focus-label">Location</label>
              </div>
            </div>
            <div className="col-xl-2">
              <Link to="#" className="btn btn-success w-100">
                {" "}
                Search{" "}
              </Link>
            </div>
          </div>
        </div>
        <hr />
        {/* /Search Filter */}
        <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearchTextChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={entitiesData}
                rowKey={(record) => record.id}
                key="dummyTable"
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                }}
                onChange={handleTableChange}
                expandable={{ defaultExpandAllRows: true }}
              />
            </div>
          </div>
        </div>
      </div>
      <AddEntityModal />
      <EditEntityModal
        companyId={companyId}
        handleUpdateList={handleUpdateList}
      />
      <DeleteEntityModal
        entity={entityData}
        handleUpdateList={handleUpdateList}
      />
      <StatusUpdateEntityModal
        entity={entityData}
        handleUpdateList={handleUpdateList}
        newStatus={newStatus}
      />
      <AddNotes />
    </div>
  );
};

export default Entities;
