import moment from "moment";
// Email validation
export const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  // Phone number validation (10 digits)
  export const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };
  
  // Date validation (check if date is in the past)
  export const validateDate = (date) => {
    const today = new Date();
    const inputDate = new Date(date);
    return inputDate <= today;
  };
  // Image file type validation 
  export const validateImage = (file) => {
    const allowedTypes = [
      "svg",
      "bmp",
      "png",
      "gif",
      "jpeg",
      "jpg",
      "webp"
    ];
    const fileType = file.type.split('/').pop(); // Get file type extension
    return allowedTypes.includes(fileType);
  };

  // Helper function to check if a date is valid using Moment.js
  export const isValidDate = (date) => {
    // Check if the date is valid and formatted correctly
    return moment(date, 'YYYY-MM-DD', true).isValid();
  };