import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Alert = ({ message, type, close, timeout }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (timeout) {
      const timer = setTimeout(() => setShow(false), timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  if (!show) return null;

  const alertClass = `alert alert-outline-${type} alert-dismissible fade show`;

  return (
    <div className={alertClass}>
      {message}
      {close && (
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="alert"
          aria-label="Close"
          onClick={() => setShow(false)}
        >
          <i className="fas fa-xmark" />
        </button>
      )}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['danger', 'warning', 'info', 'success']).isRequired,
  close: PropTypes.bool,
  timeout: PropTypes.number,
};

Alert.defaultProps = {
  close: true,
  timeout: null,
};

export default Alert;
