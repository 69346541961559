import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MoreVertical } from 'react-feather';
import moment from 'moment';
import _ from "lodash";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import EmployeeDocumentPopup from "../../../components/modelpopup/EmployeeDocumentPopup";
import alertify from "alertifyjs";

const EmployeeDocuments = () => {

  const headers = getAuthHeaders();
  const { id } = useParams();
  const [documentCategories, setDocumentCategories] = useState([]);
  const [employeeDocuments, setEmployeeDocuments] = useState([]);
  const [documentTypeId, setDocumentTypeId] = useState(null);
  const [categoryId, setCategoryId] = useState(null);
  const [isRevealed, setIsRevealed] = useState(false);
  const [actionMode, setActionMode] = useState(null);
  const [editDocument, setEditDocument] = useState({});

  const handleToggleVisibility = () => {
    setIsRevealed(!isRevealed);
  };

  // const maskDocumentNumber = (number) => {
  //   const maskedNumber = _.padStart(number.slice(-4), number.length, '*');  
  //   console.log(maskedNumber);     
  //   return maskedNumber
  // };
  const maskDocumentNumber = (number) => {
    return _.repeat('*', number.length);
  };
  useEffect(() => {
    // Fetch categories from the API
    const fetchDocuments = async () => {
      try {
        const response = await axios.get(`${getApiUrl()}/api/employees/${id}/documents/categories`, { headers });
        const categories = response.data.data;
        setDocumentCategories(categories);
        // Fetch documents for the first category
        if (categories.length > 0) {
          await getEmployeeDocuments(categories[0].category_id)
        }
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, []);
  const getEmployeeDocuments = async (categoryId) => {
    try {
      const documentsResponse = await axios.get(`${getApiUrl()}/api/employees/${id}/documents/${categoryId}`, { headers });
      setEmployeeDocuments(documentsResponse.data.data);
      setDocumentTypeId(documentsResponse.data.data[0].document_type_id)
      setCategoryId(categoryId)
    } catch (error) {
      console.error("Error fetching employee documents:", error);
    }

  }
  const renderMandatoryTooltip = (props) => (
    <Tooltip id="tooltip-success" className='tooltip-danger' {...props}>
      Mandatory Document
    </Tooltip>
  );
  const onUpdateFunction = () => {
    getEmployeeDocuments(categoryId)
  };

  const handleEditClick = (action, document) => {
    setActionMode(action);
    setEditDocument(document);
  };
  const handleOkCancelConfirmation = (documentId) => {
    alertify.confirm(
      "Delete Document",
      "Are you sure you want to delete this document?",
      async function () {
        try {
          await axios.delete(`${getApiUrl()}/api/employees/${id}/documents/${documentId}`, { headers });
          alertify.success("Document deleted successfully!");
          onUpdateFunction();
        } catch (error) {
          console.error("Error deleting document:", error);
          alertify.error("Failed to delete the document.");
        }
      },
      function () {
          return;
      }
    ).set('labels', { ok: 'Yes', cancel: 'No' });
  };

  const docStatusClass = (status) => {    
    if (status === 'inactive') {
      return `badge badge-soft-danger`
    } else if (status === 'active') {
      return `badge badge-soft-success`
    } else {
      return `badge badge-soft-warning`
    }
  }
  return (
    <>
      <div className="tab-pane fade" id="emp_documents">
        <div className="row">
          {/* Documents Sidebar */}
          <div className="col-xl-4">
            <div className='stickybar'>
              <div className="card contact-sidebar">
                <h4>EMPLOYEE DOCUMENT FOLDERS </h4>
                <hr />
                <ul className="employee-profile-documents">
                  {documentCategories.map((category) => (
                    <li
                      className="document-item"
                      key={category.category_id}
                      onClick={() => getEmployeeDocuments(category.category_id)}
                      style={{ cursor: "pointer" }} // To change cursor to pointer on hover
                    >
                      <span class="icon-container"><i class="fa fa-file fa-2x"></i></span>
                      <div class="text-container">
                        <p>{category.category_name}</p>
                        {category.category_description && <p className="category-sub-text">{category.category_description}</p>}
                        <p class="document-count">{category.document_type_count} {category.document_type_count === 1 ? 'Document' : 'Documents'}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {/* /Documents Sidebar */}
          {/* /Documents Details */}
          <div className="col-xl-8">
            <div className="contact-tab-view">
              <div className="tab-content pt-0">
                {/* <div className="view-header">
                  <h4>Documents pending for upload</h4>
                </div> */}
                <div className="files-activity">
                  {employeeDocuments && employeeDocuments.length > 0 && employeeDocuments.map((doc) => {
                    const fileInfo = JSON.parse(doc.file_info);
                    return (
                      <div className="files-wrap">
                        <div className="row align-items-center">
                          <div className="col-md-8">
                            <div className="file-info">
                              <h4>
                                {doc.type_name} &nbsp;
                                {doc.is_mandatory === 1 && (
                                  <Link
                                    to="#"
                                    data-bs-toggle="tooltip"
                                    data-bs-custom-class="tooltip-danger"
                                    data-bs-placement="top"
                                    data-bs-original-title="Danger Tooltip"
                                  >
                                    <OverlayTrigger placement="top" overlay={renderMandatoryTooltip} offset={[0, 15]}>
                                      <span class="badge badge-soft-danger">
                                        <i className="fa fa-exclamation fa-sm" />
                                      </span>
                                    </OverlayTrigger>
                                  </Link>
                                )}

                              </h4>
                              <p>
                                {doc.description}
                              </p>
                            </div>
                            <div className="file-info">
                              <div class="row align-items-center">
                                {doc.document_number && doc.document_number.trim() !== "" && (
                                  <div className="col-md-4">
                                    <div className="title">Document Number</div>
                                    <div className="text">
                                      {isRevealed ? doc.document_number : maskDocumentNumber(doc.document_number)}
                                      <i
                                        className={`fa ${isRevealed ? 'fa-eye-slash' : 'fa-eye'}`}
                                        onClick={handleToggleVisibility}
                                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                                      />
                                    </div>
                                  </div>
                                )}
                                {doc.issued_date && doc.issued_date.trim() !== "" && (
                                  <div className="col-md-4">
                                    <div className="title">Issued Date</div>
                                    <div className="text">
                                      {moment(doc.issued_date).format(
                                        "D MMMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                )}
                                {doc.validity_date && doc.validity_date.trim() !== "" && (
                                  <div className="col-md-4">
                                    <div className="title">Valid Till</div>
                                    <div className="text">
                                      {moment(doc.validity_date).format(
                                        "D MMMM YYYY"
                                      )}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4 text-md-end">
                            {doc.employee_document_id && (
                              <div className="file-action">
                                <span className={docStatusClass(doc.document_status)}>{doc.document_status}</span>
                              </div>
                            )}
                            <ul className="file-action">
                              {doc.employee_document_id && (
                                <li>
                                  <div className="dropdown action-drop">
                                    <Link
                                      to="#"
                                      className="dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <MoreVertical size={15} />
                                    </Link>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#employee_document_modal"
                                        onClick={() => handleEditClick('edit', doc)}
                                      >
                                        <i className="las la-edit me-1" />
                                        Edit Details
                                      </Link>
                                      <Link
                                        className="dropdown-item"
                                        to="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#employee_document_modal"
                                        onClick={() => handleEditClick('file-upload', doc)}
                                      >
                                        <i className="fa fa-file me-1" />
                                        Reupload Files
                                      </Link>
                                      <Link className="delete-link" to="#" onClick={() => handleOkCancelConfirmation(doc.employee_document_id)}>
                                      <i className="las la-trash me-1" /> Delete
                                      </Link>
                                      
                                    </div>
                                  </div>
                                </li>
                              )}
                              {!doc.employee_document_id && (
                                <li>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#employee_document_modal"
                                    onClick={() => handleEditClick('create', {})}
                                  >
                                    <span className="badge badge-soft-success">Add Details</span>
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        {doc.employee_document_id && (
                          <hr />
                        )}
                        {doc.employee_document_id && (
                          <div className="row">
                            {fileInfo && fileInfo.length > 0 && fileInfo.map((file, index) => (
                              <div className="col-md-3 text-center" key={index}>
                                <div className="title">
                                  <a href={file.file_path} download>
                                    <i className="fa fa-file fa-2x"></i>
                                  </a>
                                </div>
                                <div className="text "><a href={file.file_path} download>Download  </a></div>
                              </div>
                            ))}
                          </div>
                        )}

                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* /Documents Details */}
        </div>
      </div>
      <EmployeeDocumentPopup employeeId={id} documentType={documentTypeId} actionMode={actionMode} documentDetails={editDocument} onUpdateFunction={onUpdateFunction} />
    </>
  );
};

export default EmployeeDocuments; 