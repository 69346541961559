import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../Alert';
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
import Select from "react-select";
import {
    statuses
} from "../../utils/Constants";

const EmployeeDocumentPopup = ({ employeeId, documentType, actionMode, documentDetails = {}, onUpdateFunction }) => {
    const authHeaders = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    const [documentData, setDocumentData] = useState({});
    const [errors, setErrors] = useState({});
    const [files, setFiles] = useState([]);
    useEffect(() => {
        if (actionMode === 'edit' || actionMode === 'file-upload') {
            setDocumentData(documentDetails);
        }

    }, [actionMode, documentDetails]);

    const handleChange = (field, value) => {
        setDocumentData((prevDocumentData) => ({
            ...prevDocumentData,
            [field]: value,
        }));
    };

    const handleFileChange = (e) => {
        setFiles(Array.from(e.target.files));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        if (actionMode === 'edit') {
            if (!documentData.document_status) {
                setErrors({
                    // document_number: !documentData.document_number ? 'Document Number is required' : '',
                    // issued_date: !documentData.issued_date ? 'Issued Date is required' : '',
                    // validity_date: !documentData.validity_date ? 'Valid Till date is required' : '',
                    document_status: !documentData.document_status ? 'Status is required' : '',
                });
                return;
            }
        }
        else if (actionMode === 'file-upload') {
            if (!documentData.document_status || files.length === 0 || files.length > 10) {
                setErrors({
                    document_status: !documentData.document_status ? 'Status is required' : '',
                    files: files.length === 0 ? 'At least one document file is required' : files.length > 10 ? 'You can upload up to 10 files only' : '',
                });
                return;
            }
        }
         else {
            // if (!documentData.document_number || !documentData.issued_date || !documentData.validity_date || !documentData.document_status || files.length === 0 || files.length > 10) {
            if (!documentData.document_status || files.length === 0 || files.length > 10) {
                setErrors({
                    // document_number: !documentData.document_number ? 'Document Number is required' : '',
                    // issued_date: !documentData.issued_date ? 'Issued Date is required' : '',
                    // validity_date: !documentData.validity_date ? 'Valid Till date is required' : '',
                    document_status: !documentData.document_status ? 'Status is required' : '',
                    files: files.length === 0 ? 'At least one document file is required' : files.length > 10 ? 'You can upload up to 10 files only' : '',
                });
                return;
            }
        }
        const formData = new FormData();
        if (actionMode !== 'file-upload') {
            formData.append('doc_type_id', documentType);
            formData.append('document_number', documentData.document_number);
            formData.append('issued_date', documentData.issued_date || '')
            formData.append('validity_date', documentData.validity_date || '');
            formData.append('comments', documentData.comments || '');
        }
        formData.append('document_status', documentData.document_status);
        formData.append('document_id', documentData.employee_document_id || null);
        if (actionMode !== 'edit') {
            for (let i = 0; i < files.length; i++) {
                formData.append('employee_document_file', files[i]);
            }
        }
        try {
            let response;
            const headers = {
                'Content-Type': 'multipart/form-data',
                ...authHeaders, // Merge the headers
            };
            if (actionMode === 'edit') {
                response = await axios.put(`${getApiUrl()}/api/employees/${employeeId}/documents/document-info`, formData, { headers });
            } else if (actionMode === 'file-upload') {
                response = await axios.put(`${getApiUrl()}/api/employees/${employeeId}/documents/document-files`, formData, { headers });
            } else {
                response = await axios.post(`${getApiUrl()}/api/employees/${employeeId}/documents`, formData, { headers });
            }

            if (response.status === 200 || response.status === 201) {
                setSuccessAlert(true);
                setTimeout(() => {
                    document.getElementById("close-document-details-modal").click();
                    setSuccessAlert(false);
                    onUpdateFunction()
                }, 3000);
            }
        } catch (error) {
            setErrorAlert(true);
            setTimeout(() => setErrorAlert(false), 3000);
        }
    };
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
            color: state.isFocused ? "#fff" : "#000",
            "&:hover": {
                backgroundColor: "#ff9b44",
            },
        }),
    };
    return (
        <div id="employee_document_modal" className="modal custom-modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Employee Document</h5>
                        <button
                            id="close-document-details-modal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {showSuccessAlert && <Alert type="success" message="Document submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting document." />}
                        <form onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    {actionMode !== 'file-upload' && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Document Number
                                                    </label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        value={documentData.document_number}
                                                        onChange={(e) => handleChange('document_number', e.target.value)}
                                                    />
                                                    {errors.document_number && <div className="text-danger">{errors.document_number}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Issued Date 
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={documentData.issued_date}
                                                        onChange={(e) => handleChange('issued_date', e.target.value)}
                                                    />
                                                    {errors.issued_date && <div className="text-danger">{errors.issued_date}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Valid Till 
                                                    </label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        value={documentData.validity_date}
                                                        onChange={(e) => handleChange('validity_date', e.target.value)}
                                                    />
                                                    {errors.validity_date && <div className="text-danger">{errors.validity_date}</div>}
                                                </div>
                                            </div>
                                            {actionMode !== 'edit' && (
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Select Document <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="file"
                                                            className="form-control"
                                                            multiple
                                                            onChange={handleFileChange}
                                                        />
                                                        {errors.files && <div className="text-danger">{errors.files}</div>}
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Status <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        options={statuses}
                                                        value={statuses.find(
                                                            (option) => option.value === documentData.document_status
                                                        )}
                                                        onChange={(selectedOption) =>
                                                            handleChange("document_status", selectedOption.value)
                                                        }
                                                        styles={customStyles}
                                                    />
                                                    {errors.document_status && <div className="text-danger">{errors.document_status}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Comments
                                                    </label>
                                                    <textarea
                                                        className="form-control"
                                                        value={documentData.comments || ''}
                                                        onChange={(e) => handleChange('comments', e.target.value)}
                                                    ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {actionMode === 'file-upload' && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Select Document <span className="text-danger">*</span>
                                                    </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        multiple
                                                        onChange={handleFileChange}
                                                    />
                                                    {errors.files && <div className="text-danger">{errors.files}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-block mb-3">
                                                    <label className="col-form-label">
                                                        Status <span className="text-danger">*</span>
                                                    </label>
                                                    <Select
                                                        options={statuses}
                                                        value={statuses.find(
                                                            (option) => option.value === documentData.document_status
                                                        )}
                                                        onChange={(selectedOption) =>
                                                            handleChange("document_status", selectedOption.value)
                                                        }
                                                        styles={customStyles}
                                                    />
                                                    {errors.document_status && <div className="text-danger">{errors.document_status}</div>}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="submit-section">
                                <button type="submit" className="btn btn-primary submit-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeDocumentPopup;
