import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import "react-datepicker/dist/react-datepicker.css";
import { getAuthHeaders, getApiUrl } from "../../../../utils/AuthUtils";
import Alert from "../../../Alert";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../../utils/Misc";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  date_of_birth: Yup.date()
    .required("Date of Birth is required")
    .nullable() // Allow null values to prevent casting errors
    .typeError("Please select a valid date"),
  pan: Yup.string().required("PAN is required"),
  mobile: Yup.string().required("Mobile is required"),
  job: Yup.object().required("Please select job"),
  status: Yup.object().required("Please select status"),
  candidate_status: Yup.object().required("Please select candidate status"),
  applied_date: Yup.date()
    .required("Applied Date is required")
    .nullable() // Allow null values to prevent casting errors
    .typeError("Please select a valid date"),
});

const AddCandidateModal = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImage, setProfileImage] = useState(null);

  const [jobsOptions, setJobsOptions] = useState([]);
  const [genderOptions, setGenderOptions] = useState([]);
  const [candidateStatusOptions, setCandidateStatusOptions] = useState([]);
  const [maritalStatusOptions, setMaritalStatusOptions] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [formData, setFormData] = useState({
    candidate_profile_image: null,
    first_name: "",
    last_name: "",
    email: "",
    date_of_birth: null,
    gender: null,
    marital_status: null,
    pan: "",
    mobile: "",
    job: null,
    status: null,
    candidate_status: null,
    applied_date: null,
    joining_date: null,
    offer_date: null,
    offer_acceptance_date: null,
  });

  const handleFileUpload = (e) => {
    setProfileImage(e.target.files[0]);
    setValue("candidate_profile_image", e.target.files[0], {
      shouldDirty: true,
    });
  };

  const resetFileHandler = (e) => {
    document.getElementById("candidate_profile_image").value = null;
    setProfileImage(null);
    setValue("candidate_profile_image", null, { shouldDirty: true });
  };

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [statusOptions, setStatusOptions] = useState([]);

  useImperativeHandle(ref, () => ({
    resetForm() {
      setFormData({ ...formData });
      reset(formData);
      setShowErrors(false);
      resetFileHandler();
    },
  }));
  const fetchCandidateData = async () => {
    try {
      const response = await axios
        .get(`${getApiUrl()}/api/masters/candidates-masters`, {
          headers,
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("redirect_path", location.pathname);
              navigate(`/login`);
            }
          }
        });
      if (response) {
        setLocalStorageJSONData("status", response.data.data.status);
        setLocalStorageJSONData("gender", response.data.data.gender);
        setLocalStorageJSONData(
          "marital_status",
          response.data.data.marital_status
        );
        setLocalStorageJSONData(
          "candidate_status",
          response.data.data.candidate_status
        );
        setLocalStorageJSONData(
          "jobs",
          response.data.data.jobs.map((job) => {
            return {
              value: job.id,
              label: job.title + " - " + job.location,
            };
          })
        );
      }
      setStatusOptions(getLocalStorageJSONData("status"));
      setJobsOptions(getLocalStorageJSONData("jobs"));
      setGenderOptions(getLocalStorageJSONData("gender"));
      setMaritalStatusOptions(getLocalStorageJSONData("marital_status"));
      setCandidateStatusOptions(getLocalStorageJSONData("candidate_status"));
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetchCandidateData();
  }, []);

  const onSubmitCandidate = async (data) => {
    setShowErrors(false);

    const finalFormData = {
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      dob:
        data.date_of_birth && moment(data.date_of_birth).format("YYYY-MM-DD"),
      gender: data.gender && data.gender.value,
      marital_status: data.marital_status && data.marital_status.value,
      pan: data.pan,
      mobile: data.mobile,
      job_id: data.job && data.job.value,
      status: data.status.value,
      candidate_status: data.candidate_status && data.candidate_status.value,
      applied_date:
        data.applied_date && moment(data.applied_date).format("YYYY-MM-DD"),
      joining_date:
        data.joining_date && moment(data.joining_date).format("YYYY-MM-DD"),
      offer_date:
        data.offer_date && moment(data.offer_date).format("YYYY-MM-DD"),
      offer_acceptance_date:
        data.offer_acceptance_date &&
        moment(data.offer_acceptance_date).format("YYYY-MM-DD"),
    };

    let newFormData = finalFormData;
    const url = `${getApiUrl()}/api/candidates/create`;
    let config = {
      headers,
    };

    if (data.candidate_profile_image) {
      config = {
        headers: {
          ...headers,
          "content-type": "multipart/form-data",
        },
      };

      newFormData = new FormData();
      newFormData.append(
        "candidate_profile_image",
        data.candidate_profile_image
      );
      for (let key in finalFormData) {
        newFormData.append(key, finalFormData[key]);
      }
    }

    try {
      await axios.post(url, newFormData, {
        headers,
      });

      // Reset form after successful submission
      setFormData({ ...formData });
      reset(formData);
      setShowErrors(false);
      resetFileHandler();
      document.getElementById("close-modal-candidate").click();
      document.getElementById("success_btn_candidate").click();
      props.handleUpdateList();
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  return (
    <div>
      {/* Add Candidate */}
      <div
        className="modal custom-modal fade modal-padding"
        id="add_candidate"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Add New Candidate</h5>
              <button
                type="button"
                id="close-modal-candidate"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                <form
                  onSubmit={handleSubmit(onSubmitCandidate)}
                  id="add_candidate_form"
                >
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-upload-profile">
                          <h6 className>Profile Image</h6>
                          <div className="profile-pic-upload">
                            <div className="candidate-field">
                              {profileImage
                                ? profileImage.name
                                : "No File Chosen"}
                              <div className="mb-0">
                                <div className="image-upload mb-0 mx-3">
                                  <input
                                    type="file"
                                    name="candidate_profile_image"
                                    id="candidate_profile_image"
                                    onChange={handleFileUpload}
                                  />
                                  <div className="image-uploads">
                                    <h4>Upload</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="img-reset-btn">
                                <Link to="#" onClick={resetFileHandler}>
                                  Reset
                                </Link>
                              </div>
                            </div>
                          </div>
                          <span className="text-danger form-text">
                            {errors.candidate_profile_image?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            First Name <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="first_name"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.first_name ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.first_name?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Last Name</label>
                          <Controller
                            name="last_name"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.last_name ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.last_name?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Email Address <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="email"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.email ? "error-input" : ""
                                }`}
                                type="email"
                                defaultValue=""
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.email?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Date of Birth <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="date_of_birth"
                            control={control}
                            render={({ field }) => (
                              <div className="cal-icon">
                                <DatePicker
                                  className={`form-control floating datetimepicker ${
                                    errors?.date_of_birth ? "error-input" : ""
                                  }`}
                                  placeholderText="Select date of birth (DD-MM-YYYY)"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                />
                              </div>
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.date_of_birth?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Gender</label>
                          <Controller
                            name="gender"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.gender ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={genderOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.gender?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Marital Status
                          </label>
                          <Controller
                            name="marital_status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.marital_status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={maritalStatusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.marital_status?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            PAN <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="pan"
                            control={control}
                            render={({ field }) => (
                              <InputMask
                                className={`form-control ${
                                  errors?.pan ? "error-input" : ""
                                }`}
                                mask="aaaaa9999a"
                                onChange={(e) => {
                                  setValue("pan", e.target.value.toUpperCase());
                                }}
                                value={field.value}
                              ></InputMask>
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.pan?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Mobile <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="mobile"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.mobile ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={15}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.mobile?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={statusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.status?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Candidate Status{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="candidate_status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.candidate_status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={candidateStatusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.candidate_status?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Job <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="job"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.job ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={jobsOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.job?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Applied Date <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="applied_date"
                            control={control}
                            render={({ field }) => (
                              <div className="cal-icon">
                                <DatePicker
                                  className={`form-control floating datetimepicker ${
                                    errors?.applied_date ? "error-input" : ""
                                  }`}
                                  placeholderText="Select applied date (DD-MM-YYYY)"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                />
                              </div>
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.applied_date?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Offer Date</label>
                          <Controller
                            name="offer_date"
                            control={control}
                            render={({ field }) => (
                              <div className="cal-icon">
                                <DatePicker
                                  className={`form-control floating datetimepicker ${
                                    errors?.offer_date ? "error-input" : ""
                                  }`}
                                  placeholderText="Select offer date (DD-MM-YYYY)"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Offer Acceptance Date
                          </label>
                          <Controller
                            name="offer_acceptance_date"
                            control={control}
                            render={({ field }) => (
                              <div className="cal-icon">
                                <DatePicker
                                  className={`form-control floating datetimepicker ${
                                    errors?.offer_acceptance_date
                                      ? "error-input"
                                      : ""
                                  }`}
                                  placeholderText="Select offer acceptance date (DD-MM-YYYY)"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Joining Date</label>
                          <Controller
                            name="joining_date"
                            control={control}
                            render={({ field }) => (
                              <div className="cal-icon">
                                <DatePicker
                                  className={`form-control floating datetimepicker ${
                                    errors?.joining_date ? "error-input" : ""
                                  }`}
                                  placeholderText="Select joining date (DD-MM-YYYY)"
                                  selected={field.value}
                                  onChange={(date) => field.onChange(date)}
                                  dateFormat="dd-MM-yyyy"
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>

                      {showErrors && (
                        <>
                          <div className="col-sm-12 mb-3">
                            <Alert
                              message={errorsMessage}
                              type="danger"
                              close={true}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights reset-btn"
                          type="reset"
                          onClick={() => {
                            setFormData({ ...formData });
                            reset(formData);
                            setShowErrors(false);
                          }}
                        >
                          Reset
                        </button>
                        <button className="btn btn-primary" type="submit">
                          Save
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_btn_candidate"
                          data-bs-toggle="modal"
                          data-bs-target="#success_msg_candidate"
                        >
                          Success
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Candidate */}
      {/* Success Candidate */}
      <div
        className="modal custom-modal fade"
        id="success_msg_candidate"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Candidate Created Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Candidate */}
    </div>
  );
});

export default AddCandidateModal;
