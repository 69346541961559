import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import PreLoader from "../../PreLoader";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First Name is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Please enter a valid email address"),
  mobile: Yup.string().required("Mobile is required"),
  role: Yup.object().required("Please select role"),
  status: Yup.object().required("Please select status"),
});

const EditUserModal = (props) => {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImage, setProfileImage] = useState(null);

  const [rolesOptions, setRolesOptions] = useState([]);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isPermissionAllChecked, setIsPermissionAllChecked] = useState([]);
  const [isModuleChecked, setIsModuleChecked] = useState([]);
  const [isModulePermissionsChecked, setIsModulePermissionsChecked] = useState(
    []
  );
  const [isSubmoduleChecked, setIsSubmoduleChecked] = useState([]);
  const [isSubmodulePermissionsChecked, setIsSubmodulePermissionsChecked] =
    useState([]);

  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");
  const [statusOptions, setStatusOptions] = useState(
    getLocalStorageJSONData("status")
  );

  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    password: "",
    profile_image: false,
    role_id: null,
    module_permissions: [],
    submodule_permissions: [],
    status: null,
  });
  const [permissionsList, setPermissionsList] = useState(
    localStorage.getItem("permissions")
      ? getLocalStorageJSONData("permissions")
      : []
  );
  const [modulesList, setModulesList] = useState(
    localStorage.getItem("modules") ? getLocalStorageJSONData("modules") : []
  );

  const handleFileUpload = (e) => {
    setProfileImage(e.target.files[0]);
    setValue("user_profile_image", e.target.files[0], { shouldDirty: true });
  };

  const resetFileHandler = (e) => {
    document.getElementById("user_profile_image").value = null;
    setProfileImage(null);
    setValue("user_profile_image", null, { shouldDirty: true });
  };

  const initPermissions = () => {
    const submodulesList = [];
    const submodulePermissionsList = [];
    setIsCheckAll(false);
    setIsPermissionAllChecked(
      permissionsList.map((permission) => ({ [permission.id]: false }))
    );
    setIsModuleChecked(
      modulesList.map((module) => {
        submodulesList.push(
          module.submodules.map((submodule) => {
            return { [submodule.id]: false };
          })
        );
        return { [module.id]: false };
      })
    );
    setIsSubmoduleChecked(submodulesList);
    setIsModulePermissionsChecked(
      modulesList.map((module) => {
        submodulePermissionsList.push(
          module.submodules.map((submodule) => {
            return {
              [submodule.id]: permissionsList.map((permission) => ({
                [permission.id]: false,
              })),
            };
          })
        );
        return {
          [module.id]: permissionsList.map((permission) => ({
            [permission.id]: false,
          })),
        };
      })
    );
    setIsSubmodulePermissionsChecked(submodulePermissionsList);
  };

  const updateModuleCheckbox = (module_id, value) => {
    setIsModuleChecked((prev) => {
      return prev.map((item) => {
        return item[module_id] !== undefined ? { [module_id]: value } : item;
      });
    });
    setIsModulePermissionsChecked((prev) => {
      setValue(
        "module_permissions",
        prev.map((item) => {
          return item[module_id] !== undefined
            ? {
                [module_id]: permissionsList.map((permission) => ({
                  [permission.id]: value,
                })),
              }
            : item;
        }),
        { shouldDirty: true }
      );
      return prev.map((item) => {
        return item[module_id] !== undefined
          ? {
              [module_id]: permissionsList.map((permission) => ({
                [permission.id]: value,
              })),
            }
          : item;
      });
    });
    const moduleData = modulesList.find((module) => {
      return module.id === module_id ? module : false;
    });
    const moduleIndex = modulesList.findIndex((module) => {
      return module.id === module_id ? module : false;
    });
    if (moduleIndex !== -1 && moduleData && moduleData.submodules.length) {
      moduleData.submodules.forEach((submodule, submodule_index) => {
        updateSubmoduleCheckbox(
          moduleIndex,
          submodule_index,
          submodule.id,
          value
        );
      });
    }
  };

  const updateSubmoduleCheckbox = (
    module_index,
    submodule_index,
    submodule_id,
    value
  ) => {
    // Update submodule checkbox state
    setIsSubmoduleChecked((prev) => {
      // Create a deep copy of the previous state
      const newState = prev.map((module, i) =>
        module.map((submodule, j) =>
          i === module_index && j === submodule_index
            ? { [submodule_id]: value }
            : submodule
        )
      );
      return newState;
    });
    // Update submodule permissions checkbox state
    setIsSubmodulePermissionsChecked((prev) => {
      const newPermissionsState = prev.map((module, i) =>
        module.map((submodule, j) =>
          i === module_index && j === submodule_index
            ? {
                [submodule_id]: permissionsList.map((permission) => ({
                  [permission.id]: value,
                })),
              }
            : submodule
        )
      );
      setValue("submodule_permissions", newPermissionsState, {
        shouldDirty: true,
      });
      return newPermissionsState;
    });
  };

  const handleCheckAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsPermissionAllChecked(
      permissionsList.map((permission) => ({ [permission.id]: !isCheckAll }))
    );
    modulesList.forEach((module) => {
      updateModuleCheckbox(module.id, !isCheckAll);
    });
  };
  const handlePermissionAll = (key, id) => {
    // Toggle the "checked" state for the specific permission
    const newPermissions = [...isPermissionAllChecked];
    newPermissions[key] = {
      ...newPermissions[key],
      [id]: !newPermissions[key][id],
    };
    const value = newPermissions[key][id];

    // Update the permissions state
    setIsPermissionAllChecked(newPermissions);

    // Update the permissions for all modules and submodules
    setIsModulePermissionsChecked((prev) => {
      setValue(
        "module_permissions",
        prev.map((item) => {
          const moduleId = parseInt(Object.keys(item)[0], 10);
          if (item[moduleId]) {
            return {
              [moduleId]: item[moduleId].map((permission) => {
                if (permission[id] !== undefined) {
                  return { [id]: value };
                }
                return permission;
              }),
            };
          }
          return item;
        }),
        { shouldDirty: true }
      );
      return prev.map((item) => {
        const moduleId = parseInt(Object.keys(item)[0], 10);
        if (item[moduleId]) {
          return {
            [moduleId]: item[moduleId].map((permission) => {
              if (permission[id] !== undefined) {
                return { [id]: value };
              }
              return permission;
            }),
          };
        }
        return item;
      });
    });

    setIsSubmodulePermissionsChecked((prev) => {
      setValue(
        "submodule_permissions",
        prev.map((module) =>
          module.map((submodule) => {
            const submoduleId = parseInt(Object.keys(submodule)[0], 10);
            if (submodule[submoduleId]) {
              return {
                [submoduleId]: submodule[submoduleId].map((permission) => {
                  if (permission[id] !== undefined) {
                    return { [id]: value };
                  }
                  return permission;
                }),
              };
            }
            return submodule;
          })
        ),
        { shouldDirty: true }
      );
      return prev.map((module) =>
        module.map((submodule) => {
          const submoduleId = parseInt(Object.keys(submodule)[0], 10);
          if (submodule[submoduleId]) {
            return {
              [submoduleId]: submodule[submoduleId].map((permission) => {
                if (permission[id] !== undefined) {
                  return { [id]: value };
                }
                return permission;
              }),
            };
          }
          return submodule;
        })
      );
    });
  };

  const handleModuleChecked = (key, id) => {
    updateModuleCheckbox(id, !isModuleChecked[key][id]);
  };
  const handleModulePermissionChecked = (
    e,
    key_m,
    module_id,
    key_p,
    permission_id
  ) => {
    const newModulePermissionsChecked = [...isModulePermissionsChecked];
    newModulePermissionsChecked[key_m][module_id][key_p][permission_id] =
      e.target.checked;
    setIsModulePermissionsChecked(newModulePermissionsChecked);
    setValue("module_permissions", newModulePermissionsChecked, {
      shouldDirty: true,
    });
  };
  const handleSubmoduleChecked = (key_m, key_s, s_id) => {
    updateSubmoduleCheckbox(
      key_m,
      key_s,
      s_id,
      !isSubmoduleChecked[key_m][key_s][s_id]
    );
  };
  const handleSubmodulePermissionChecked = (
    e,
    key_m,
    key_s,
    submodule_id,
    key_p,
    permission_id
  ) => {
    setIsSubmodulePermissionsChecked((prev) => {
      setValue(
        "submodule_permissions",
        prev.map((module, key_m_index) => {
          if (key_m_index === key_m) {
            // If the correct module, update the submodule
            return module.map((submodule, key_s_index) => {
              if (key_s_index === key_s) {
                // If the correct submodule, update the permissions
                return {
                  ...submodule,
                  [submodule_id]: submodule[submodule_id].map(
                    (permission, index) => {
                      if (index === key_p) {
                        // Update the specific permission
                        return {
                          ...permission,
                          [permission_id]: e.target.checked,
                        };
                      }
                      return permission;
                    }
                  ),
                };
              }
              return submodule;
            });
          }
          return module;
        }),
        { shouldDirty: true }
      );
      // Create a deep copy of the previous state
      return prev.map((module, key_m_index) => {
        if (key_m_index === key_m) {
          // If the correct module, update the submodule
          return module.map((submodule, key_s_index) => {
            if (key_s_index === key_s) {
              // If the correct submodule, update the permissions
              return {
                ...submodule,
                [submodule_id]: submodule[submodule_id].map(
                  (permission, index) => {
                    if (index === key_p) {
                      // Update the specific permission
                      return {
                        ...permission,
                        [permission_id]: e.target.checked,
                      };
                    }
                    return permission;
                  }
                ),
              };
            }
            return submodule;
          });
        }
        return module;
      });
    });
  };

  // Function to check if at least one checkbox is selected
  const isAnyCheckboxSelected = () => {
    let check = false;
    isModulePermissionsChecked.forEach((module) => {
      if (check) return;
      const moduleId = Object.keys(module)[0];
      module[moduleId].forEach((permission) => {
        if (check) return;
        const permissionId = Object.keys(permission)[0];
        if (permission[permissionId]) {
          check = true;
        }
      });
    });
    isSubmodulePermissionsChecked.forEach((module) => {
      if (check) return;
      module.forEach((submodule) => {
        if (check) return;
        const submoduleId = Object.keys(submodule)[0];
        submodule[submoduleId].forEach((permission) => {
          if (check) return;
          const permissionId = Object.keys(permission)[0];
          if (permission[permissionId]) {
            check = true;
          }
        });
      });
    });
    return check;
  };

  const {
    reset,
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const fetchUserMasterData = async () => {
    try {
      const response = await axios
        .get(`${getApiUrl()}/api/masters/users-masters`, {
          headers,
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("redirect_path", location.pathname);
              navigate(`/login`);
            }
          }
        });
      if (response) {
        setLocalStorageJSONData("status", response.data.data.status);
        setLocalStorageJSONData("permissions", response.data.data.permissions);
        setLocalStorageJSONData("roles", response.data.data.roles);
        setLocalStorageJSONData("modules", response.data.data.modules);
      }
      setStatusOptions(getLocalStorageJSONData("status"));
      setPermissionsList(getLocalStorageJSONData("permissions"));
      setModulesList(getLocalStorageJSONData("modules"));
      setRolesOptions(getLocalStorageJSONData("roles").map((role) => {
        return { value: role.id, label: role.title };
      }));
      initPermissions();
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetchUserMasterData();
  }, []);

  const onSubmitUser = async (data) => {
    setShowErrors(false);
    // Validate checkbox selection
    if (!isAnyCheckboxSelected()) {
      setErrorsMessage("At least one permission must be selected.");
      setShowErrors(true);
      return;
    }

    const finalFormData = {
      id: props.user.id,
      first_name: data.first_name,
      last_name: data.last_name,
      email: data.email,
      mobile: data.mobile,
      password: data.password,
      role_id: data.role.value,
      module_permissions: JSON.stringify(isModulePermissionsChecked),
      submodule_permissions: JSON.stringify(isSubmodulePermissionsChecked),
      status: data.status.value,
    };

    let newFormData = finalFormData;
    const url = `${getApiUrl()}/api/users/update`;
    let config = {
      headers,
    };

    if (data.user_profile_image) {
      config = {
        headers: {
          ...headers,
          "content-type": "multipart/form-data",
        },
      };

      newFormData = new FormData();
      newFormData.append("user_profile_image", data.user_profile_image);
      for (let key in finalFormData) {
        newFormData.append(key, finalFormData[key]);
      }
    }

    try {
      const response = await axios.put(url, newFormData, {
        headers,
      });

      // Reset form after successful submission
      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        document.getElementById("close-edit-modal-user").click();
        document.getElementById("success_update_btn_user").click();
        props.handleUpdateList();
      }
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (props.user) {
          initPermissions();
          const response = await axios.get(
            `${getApiUrl()}/api/users/${props.user.id}`,
            {
              headers,
            }
          );
          if (response.data.data.module_permissions) {
            setIsModulePermissionsChecked(
              JSON.parse(JSON.parse(response.data.data.module_permissions))
            );
            setValue(
              "module_permissions",
              JSON.parse(JSON.parse(response.data.data.module_permissions)),
              { shouldDirty: true }
            );
          }
          if (response.data.data.submodule_permissions) {
            setIsSubmodulePermissionsChecked(
              JSON.parse(JSON.parse(response.data.data.submodule_permissions))
            );
            setValue(
              "submodule_permissions",
              JSON.parse(JSON.parse(response.data.data.submodule_permissions)),
              { shouldDirty: true }
            );
          }
          const editFormData = {
            ...formData,
            ...response.data.data,
            password: "",
            confirm_password: "",
            profile_image: response.data.data.profile_image
              ? JSON.parse(response.data.data.profile_image)
              : false,
            status: statusOptions.filter(
              (status) => status.value === response.data.data.status
            )[0],
            role: rolesOptions.filter(
              (role) => role.value === response.data.data.role_id
            )[0],
          };
          setFormData(editFormData);
          reset(editFormData);
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchUserData();
  }, [props.user]);

  return (
    <div>
      {/* Edit User */}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_user"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Edit User</h5>
              <button
                type="button"
                id="close-edit-modal-user"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                {loading && <PreLoader showLoader={loading} />}
                {!loading && (
                  <form
                    onSubmit={handleSubmit(onSubmitUser)}
                    id="edit_user_form"
                  >
                    <div className="contact-input-set">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-upload-profile">
                            <h6 className>Profile Image</h6>
                            <div className="profile-pic-upload">
                              <div className="profile-pic">
                                {formData.profile_image && (
                                  <span>
                                    <img
                                      src={
                                        formData.profile_image &&
                                        formData.profile_image.location
                                      }
                                      alt="Img"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="employee-field">
                                {profileImage
                                  ? profileImage.name
                                  : "No File Chosen"}
                                <div className="mb-0">
                                  <div className="image-upload mb-0 mx-3">
                                    <input
                                      type="file"
                                      name="user_profile_image"
                                      id="user_profile_image"
                                      onChange={handleFileUpload}
                                    />
                                    <div className="image-uploads">
                                      <h4>Upload</h4>
                                    </div>
                                  </div>
                                </div>
                                <div className="img-reset-btn">
                                  <Link to="#" onClick={resetFileHandler}>
                                    Reset
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <span className="text-danger form-text">
                              {errors.user_profile_image?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              First Name <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="first_name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.first_name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.first_name?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Last Name</label>
                            <Controller
                              name="last_name"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.last_name ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.last_name?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Email Address{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="email"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.email ? "error-input" : ""
                                  }`}
                                  type="email"
                                  defaultValue=""
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.email?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Mobile <span className="text-danger"> *</span>
                            </label>
                            <Controller
                              name="mobile"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.mobile ? "error-input" : ""
                                  }`}
                                  type="text"
                                  defaultValue=""
                                  maxlength={15}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.mobile?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Password</label>
                            <Controller
                              name="password"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.password ? "error-input" : ""
                                  }`}
                                  type="password"
                                  defaultValue=""
                                  maxlength={255}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.password?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Confirm Password{" "}
                            </label>
                            <Controller
                              name="confirm_password"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.confirm_password
                                      ? "error-input"
                                      : ""
                                  }`}
                                  type="password"
                                  defaultValue=""
                                  maxlength={15}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.confirm_password?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Role <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="role"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.role ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={(e) => {
                                    const roles = JSON.parse(
                                      localStorage.getItem("roles")
                                    );
                                    roles.forEach((role) => {
                                      if (e.value === role.id) {
                                        setIsModulePermissionsChecked(
                                          JSON.parse(role.module_permissions)
                                        );
                                        setIsSubmodulePermissionsChecked(
                                          JSON.parse(role.submodule_permissions)
                                        );
                                      }
                                    });
                                    setValue("role", e, { shouldDirty: true });
                                  }}
                                  value={field.value}
                                  options={rolesOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.role?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Status <span className="text-danger">*</span>
                            </label>
                            <Controller
                              name="status"
                              control={control}
                              render={({ field }) => (
                                <Select
                                  className={`${
                                    errors?.status ? "error-input" : ""
                                  }`}
                                  {...field}
                                  onChange={field.onChange}
                                  value={field.value}
                                  options={statusOptions}
                                  isClearable={true}
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.status?.message}{" "}
                            </span>
                          </div>
                        </div>
                        <div className="col-md-12 mb-3">
                          <div className="table-responsive m-t-15">
                            <table className="table table-striped custom-table">
                              <thead>
                                <tr>
                                  <th>
                                    <label className="custom_check">
                                      <input
                                        type="checkbox"
                                        id="check_all"
                                        onChange={handleCheckAll}
                                        checked={isCheckAll}
                                      />
                                      <span className="checkmark" />
                                    </label>
                                    <span className="custom_title">
                                      Module Permission
                                    </span>
                                  </th>
                                  {permissionsList.map((permission, key) => (
                                    <th key={key} className="text-center">
                                      <label className="custom_check">
                                        <input
                                          type="checkbox"
                                          id={`permission_${permission.id}`}
                                          onChange={() =>
                                            handlePermissionAll(
                                              key,
                                              permission.id
                                            )
                                          }
                                          checked={
                                            isPermissionAllChecked[key] &&
                                            isPermissionAllChecked[key][
                                              permission.id
                                            ]
                                          }
                                        />
                                        <span className="checkmark" />
                                      </label>
                                      <span className="custom_title">
                                        {permission.title}
                                      </span>
                                    </th>
                                  ))}
                                </tr>
                              </thead>
                              <tbody>
                                {modulesList.map((module, key_m) => {
                                  return (
                                    <>
                                      <tr key={key_m}>
                                        <td>
                                          <label className="custom_check">
                                            <input
                                              type="checkbox"
                                              id={`module_${module.id}`}
                                              onChange={() =>
                                                handleModuleChecked(
                                                  key_m,
                                                  module.id
                                                )
                                              }
                                              checked={
                                                isModuleChecked[key_m] &&
                                                isModuleChecked[key_m][
                                                  module.id
                                                ]
                                              }
                                            />
                                            <span className="checkmark" />
                                          </label>
                                          <span className="custom_title">
                                            {module.title}
                                          </span>
                                        </td>
                                        {permissionsList.map(
                                          (permission, key_p) => {
                                            return (
                                              <td
                                                key={key_p}
                                                className="text-center"
                                              >
                                                <label className="custom_check">
                                                  <input
                                                    type="checkbox"
                                                    id={`module_permission_${module.id}_${permission.id}`}
                                                    onChange={(e) =>
                                                      handleModulePermissionChecked(
                                                        e,
                                                        key_m,
                                                        module.id,
                                                        key_p,
                                                        permission.id
                                                      )
                                                    }
                                                    checked={
                                                      isModulePermissionsChecked[
                                                        key_m
                                                      ] &&
                                                      isModulePermissionsChecked[
                                                        key_m
                                                      ][module.id] &&
                                                      isModulePermissionsChecked[
                                                        key_m
                                                      ][module.id][key_p] &&
                                                      isModulePermissionsChecked[
                                                        key_m
                                                      ][module.id][key_p][
                                                        permission.id
                                                      ]
                                                    }
                                                  />
                                                  <span className="checkmark" />
                                                </label>
                                              </td>
                                            );
                                          }
                                        )}
                                      </tr>
                                      {module.submodules.length > 0 &&
                                        module.submodules.map(
                                          (submodule, key_s) => {
                                            return (
                                              <tr key={`${key_m}_${key_s}`}>
                                                <td>
                                                  <div className="submodule_title">
                                                    <label className="custom_check">
                                                      <input
                                                        type="checkbox"
                                                        id={`submodule_${submodule.id}`}
                                                        onChange={() =>
                                                          handleSubmoduleChecked(
                                                            key_m,
                                                            key_s,
                                                            submodule.id
                                                          )
                                                        }
                                                        checked={
                                                          isSubmoduleChecked[
                                                            key_m
                                                          ] &&
                                                          isSubmoduleChecked[
                                                            key_m
                                                          ][key_s] &&
                                                          isSubmoduleChecked[
                                                            key_m
                                                          ][key_s][submodule.id]
                                                        }
                                                      />
                                                      <span className="checkmark" />
                                                    </label>
                                                    <span className="custom_title">
                                                      {submodule.title}
                                                    </span>
                                                  </div>
                                                </td>
                                                {permissionsList.map(
                                                  (permission, key_p) => {
                                                    return (
                                                      <td
                                                        key={key_p}
                                                        className="text-center"
                                                      >
                                                        <label className="custom_check">
                                                          <input
                                                            type="checkbox"
                                                            id={`submodule_permission_${submodule.id}_${permission.id}`}
                                                            onChange={(e) =>
                                                              handleSubmodulePermissionChecked(
                                                                e,
                                                                key_m,
                                                                key_s,
                                                                submodule.id,
                                                                key_p,
                                                                permission.id
                                                              )
                                                            }
                                                            checked={
                                                              isSubmodulePermissionsChecked[
                                                                key_m
                                                              ] &&
                                                              isSubmodulePermissionsChecked[
                                                                key_m
                                                              ][key_s] &&
                                                              isSubmodulePermissionsChecked[
                                                                key_m
                                                              ][key_s][
                                                                submodule.id
                                                              ] &&
                                                              isSubmodulePermissionsChecked[
                                                                key_m
                                                              ][key_s][
                                                                submodule.id
                                                              ][key_p] &&
                                                              isSubmodulePermissionsChecked[
                                                                key_m
                                                              ][key_s][
                                                                submodule.id
                                                              ][key_p][
                                                                permission.id
                                                              ]
                                                            }
                                                          />
                                                          <span className="checkmark" />
                                                        </label>
                                                      </td>
                                                    );
                                                  }
                                                )}
                                              </tr>
                                            );
                                          }
                                        )}
                                    </>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {showErrors && (
                          <>
                            <div className="col-sm-12 mb-3">
                              <Alert
                                message={errorsMessage}
                                type="danger"
                                close={true}
                              />
                            </div>
                          </>
                        )}
                        <div className="col-lg-12 text-end form-wizard-button">
                          <button
                            className="button btn-lights"
                            type="button"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </button>
                          <button
                            className="btn btn-primary"
                            type="submit"
                            disabled={!isDirty}
                          >
                            Update
                          </button>
                          <button
                            className="btn btn-primary d-none"
                            type="button"
                            id="success_update_btn_user"
                            data-bs-toggle="modal"
                            data-bs-target="#success_update_msg_user"
                          >
                            Success
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit User */}
      {/* Success User */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_user"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>User Updated Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success User */}
    </div>
  );
};

export default EditUserModal;
