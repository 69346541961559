import React, { useRef } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import UserFilter from "./UserFilter";
import UsersTable from "./UsersTable";
import AddUserModal from "../../../../components/Administration/Users/AddUserModal";
import Footer from "../../../layout/Footer";

const Users = () => {
  const userRef = useRef();
  const updateUserTableData = () => {
    userRef.current.updateUsersList();
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Users "
          title="Dashboard"
          subtitle="Users"
          modal="#add_user"
          name="Add User"
        />
        {/* <UserFilter /> */}
        <UsersTable ref={userRef} />
        <AddUserModal handleUpdateList={updateUserTableData} />
      </div>
      <Footer />
    </div>
  );
};

export default Users;
