import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import DatePicker from "react-datepicker";
import Alert from "../Alert";
import moment from "moment";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
const EducationInfoPopup = ( { employeeId, onSubmitSuccess } ) => {
    // State for education entries
    const [educationEntries, setEducationEntries] = useState([
        {
            institution: "",
            subject: "",
            start_date: null,
            completion_date: null,
            degree: "",
            grade: "",
        },
    ]);
    const headers = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    // State for errors
    const [errors, setErrors] = useState({});

    // Function to handle adding more education entries
    const handleAddMore = () => {
        setEducationEntries([
            ...educationEntries,
            {
                institution: "",
                subject: "",
                start_date: null,
                completion_date: null,
                degree: "",
                grade: "",
            },
        ]);
    };

    // Function to handle removing an education entry
    const handleRemoveEntry = (index) => {
        if (educationEntries.length > 1) {
            const newEntries = educationEntries.filter((_, i) => i !== index);
            setEducationEntries(newEntries);

            // Remove associated errors
            setErrors((prevErrors) => {
                const { [index]: _, ...rest } = prevErrors; // Remove the index-specific error
                return rest;
            });
        }
    };

    // Function to handle input change for each entry
    const handleInputChange = (index, field, value) => {
        const newEntries = [...educationEntries];
        newEntries[index][field] = value;
        setEducationEntries(newEntries);
    
        // Clear error when user starts typing
        setErrors((prevErrors) => ({
            ...prevErrors,
            [index]: {
                ...prevErrors[index],
                [field]: "",
            },
        }));
    };
    

    // Function to validate each entry
    const validateEntry = (entry) => {
        const entryErrors = {};
        // Validate Institution
        if (!entry.institution.trim()) {
            entryErrors.institution = "Institution is required";
        }

        // Validate Starting Date
        if (!entry.start_date) {
            entryErrors.start_date = "Starting Date is required";
        }

        // Validate Completion Date
        if (!entry.completion_date) {
            entryErrors.completion_date = "Completion Date is required";
        } else if (entry.start_date && entry.completion_date < entry.start_date) {
            entryErrors.completion_date =
                "Completion Date cannot be earlier than Starting Date";
        }

        // Validate Degree
        if (!entry.degree.trim()) {
            entryErrors.degree = "Degree is required";
        }

        return entryErrors;
    };

    // Function to check if the form is valid
    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        educationEntries.forEach((entry, index) => {
            const entryErrors = validateEntry(entry);

            if (Object.keys(entryErrors).length > 0) {
                isValid = false;
                newErrors[index] = entryErrors;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    // Function to handle form submission    
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorAlert(false);
    
        if (validateForm()) {
            // Format the start and completion dates
            const formattedEntries = educationEntries.map((entry) => ({
                ...entry,
                start_date: moment(entry.start_date).format("YYYY-MM-DD"),
                completion_date: moment(entry.completion_date).format("YYYY-MM-DD"),
            }));
    
            const formattedData = {
                educations: formattedEntries,
            };
    
            try {
                const response = await axios.post(
                    `${getApiUrl()}/api/employees/${employeeId}/educations`,
                    formattedData,
                    { headers }
                );
    
                // Reset form after successful submission
                setEducationEntries([
                    {
                        institution: "",
                        subject: "",
                        start_date: null,
                        completion_date: null,
                        degree: "",
                        grade: "",
                    },
                ]);
                setErrors({});
                setSuccessAlert(true);
                setTimeout(() => {
                    document.getElementById("modal-education-info-close").click();
                    setSuccessAlert(false);
                    onSubmitSuccess();
                }, 3000);
            } catch (error) {
                setErrorAlert(true);
                if (error.response && error.response.status === 400) {
                    const errorData = error.response.data.errors;
                    const newErrors = {};
    
                    // Map error fields to their respective error messages
                    Object.keys(errorData).forEach((field) => {
                        const [mainField, indexStr, subField] = field.split(".");
                        const index = parseInt(indexStr, 10);
    
                        // Initialize the nested error object if it doesn't exist
                        if (!newErrors[index]) {
                            newErrors[index] = {};
                        }
    
                        // Assign the error message to the appropriate field
                        newErrors[index][subField] = errorData[field][0];
                    });
    
                    // Set errors state to display error messages
                    setErrors(newErrors);
                } else {
                    // Handle other types of errors (e.g., network errors)
                    console.error("Error:", error);
                    // You can show a generic error message to the user if needed
                }
            }
        } else {
            setErrorAlert(true);
        }
    };
    
    return (
        <div
            id="education_info"
            className="modal custom-modal fade"
            role="dialog"
        >
            <div
                className="modal-dialog modal-dialog-centered modal-lg"
                role="document"
            >
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Education Information</h5>
                        <button
                            id="modal-education-info-close"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {showSuccessAlert && <Alert type="success" message="Education details submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting bank details." />}
                        <form onSubmit={handleSubmit}>
                            <div className="form-scroll">
                                {educationEntries.map((entry, index) => (
                                    <div className="card" key={index}>
                                        <div className="card-body">
                                            <h3 className="card-title">
                                                Education Information{" "}
                                                <Link
                                                    to="#"
                                                    className={`delete-icon ${educationEntries.length === 1
                                                        ? "disabled"
                                                        : ""
                                                        }`}
                                                    onClick={(e) => {
                                                        e.preventDefault(); // Prevent default link behavior
                                                        handleRemoveEntry(index);
                                                    }}
                                                    style={{
                                                        cursor:
                                                            educationEntries.length === 1
                                                                ? "not-allowed"
                                                                : "pointer",
                                                        opacity:
                                                            educationEntries.length === 1
                                                                ? 0.5
                                                                : 1,
                                                    }}
                                                >
                                                    <i className="fa-regular fa-trash-can" />
                                                </Link>
                                            </h3>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Institution <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.institution}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "institution",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                        {errors[index]?.institution && (
                                                            <div className="text-danger">
                                                                {errors[index].institution}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Subject
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.subject}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "subject",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Starting Date <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="cal-icon">
                                                            <DatePicker
                                                                selected={entry.start_date}
                                                                onChange={(date) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        "start_date",
                                                                        date
                                                                    )
                                                                }
                                                                className="form-control floating datetimepicker"
                                                                dateFormat="dd-MM-yyyy"
                                                            />
                                                        </div>
                                                        {errors[index]?.start_date && (
                                                            <div className="text-danger">
                                                                {errors[index].start_date}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Complete Date <span className="text-danger">*</span>
                                                        </label>
                                                        <div className="cal-icon">
                                                            <DatePicker
                                                                selected={entry.completion_date}
                                                                onChange={(date) =>
                                                                    handleInputChange(
                                                                        index,
                                                                        "completion_date",
                                                                        date
                                                                    )
                                                                }
                                                                className="form-control floating datetimepicker"
                                                                dateFormat="dd-MM-yyyy"
                                                            />
                                                        </div>
                                                        {errors[index]?.completion_date && (
                                                            <div className="text-danger">
                                                                {errors[index].completion_date}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Degree <span className="text-danger">*</span>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.degree}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "degree",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                        {errors[index]?.degree && (
                                                            <div className="text-danger">
                                                                {errors[index].degree}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-block mb-3">
                                                        <label className="col-form-label">
                                                            Grade
                                                        </label>
                                                        <input
                                                            type="text"
                                                            value={entry.grade}
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    index,
                                                                    "grade",
                                                                    e.target.value
                                                                )
                                                            }
                                                            className="form-control floating"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className="add-more">
                                <Link to="#" onClick={handleAddMore}>
                                    <i className="fa-solid fa-plus-circle" /> Add
                                    More
                                </Link>
                            </div>
                            <div className="submit-section">
                                <button
                                    className="btn btn-primary submit-btn"
                                    type="submit"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EducationInfoPopup;
