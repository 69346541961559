import React, {
  forwardRef,
  useEffect,
  useState,
  useImperativeHandle,
} from "react";
import axios from "axios";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import Select from "react-select";
import DefaultEditor from "react-simple-wysiwyg";
import { getApiUrl, getAuthHeaders } from "../../../utils/AuthUtils";
import Alert from "../../Alert";
import TagsSuggestion from "../../TagsSuggestion";
import {
  getLocalStorageJSONData,
  setLocalStorageJSONData,
} from "../../../utils/Misc";

const validationSchema = Yup.object().shape({
  template_title: Yup.string().required("Title is required"),
  receiver_type: Yup.object().required(
    "Please select receiver of the communication"
  ),
  trigger: Yup.object().required("Please select trigger at"),
  event: Yup.object().required("Please select event"),
  communication_modes: Yup.array()
    .min(1, "At least one communication mode must be selected.")
    .required("Communication modes are required."),
  template_applicable_in: Yup.array()
    .min(1, "At least one applicable category must be selected.")
    .required("Template Applicable Categories are required."),
  email_subject: Yup.string().when("communication_modes", {
    is: (modes) =>
      Array.isArray(modes) && modes.some((mode) => mode.value === "email"),
    then: () => Yup.string().required("Email Subject is required."),
    otherwise: () => Yup.string().notRequired(),
  }),
  email_body: Yup.string().when("communication_modes", {
    is: (modes) =>
      Array.isArray(modes) && modes.some((mode) => mode.value === "email"),
    then: () => Yup.string().required("Email Body is required."),
    otherwise: () => Yup.string().notRequired(),
  }),
  sms_text: Yup.string().when("communication_modes", {
    is: (modes) =>
      Array.isArray(modes) && modes.some((mode) => mode.value === "sms"),
    then: () => Yup.string().required("SMS Text is required."),
    otherwise: () => Yup.string().notRequired(),
  }),
  whatsapp_text: Yup.string().when("communication_modes", {
    is: (modes) =>
      Array.isArray(modes) && modes.some((mode) => mode.value === "whatsapp"),
    then: () => Yup.string().required("Whatsapp Text is required."),
    otherwise: () => Yup.string().notRequired(),
  }),
  status: Yup.object().required("Please select status"),
});

const AddNotificationTemplateModal = forwardRef((props, ref) => {
  const headers = getAuthHeaders();
  const navigate = useNavigate();
  const location = useLocation();

  const [receiverTypesOptions, setReceiverTypesOptions] = useState([]);
  const [triggerOptions, setTriggerOptions] = useState([]);
  const [eventOptions, setEventOptions] = useState([]);
  const [communicationModesOptions, setCommunicationModesOptions] = useState(
    []
  );
  const [templateApplicableOptions, setTemplateApplicableOptions] = useState(
    []
  );

  const [showErrors, setShowErrors] = useState(false);
  const [errorsMessage, setErrorsMessage] = useState("");

  const [internalSuggestions, setInternalSuggestions] = useState([]);
  const [externalSuggestions, setExternalSuggestions] = useState([]);
  const [formData] = useState({
    template_title: "",
    receiver_type: null,
    internal_receivers: [],
    external_receivers: [],
    trigger: null,
    number_of_days: "",
    event: null,
    template_applicable_in: [],
    mandatory_notification: "0",
    communication_modes: [],
    whatsapp_text: "",
    sms_text: "",
    sms_template_id: "",
    email_subject: "",
    email_body: "",
    send_reminder: "0",
    reminder_at: null,
    reminder_number_of_days: "",
    status: null,
  });

  const {
    reset,
    control,
    handleSubmit,
    setError,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const [statusOptions, setStatusOptions] = useState([]);

  const showReceiverType = watch("receiver_type", null);
  const showDays = watch("trigger", null);
  const showCommunicationModes = watch("communication_modes", null);
  const showReminder = watch("send_reminder", null);

  useImperativeHandle(ref, () => ({
    resetForm() {
      reset(formData);
      setShowErrors(false);
    },
  }));
  const fetchNotificationTemplateData = async () => {
    try {
      const response = await axios
        .get(`${getApiUrl()}/api/masters/notification-templates-masters`, {
          headers,
        })
        .catch(function (error) {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("redirect_path", location.pathname);
              navigate(`/login`);
            }
          }
        });
      if (response) {
        setLocalStorageJSONData("status", response.data.data.status);
        setLocalStorageJSONData(
          "communication_modes",
          response.data.data.communication_modes
        );
        setLocalStorageJSONData(
          "template_applicable_in",
          response.data.data.template_applicable_in
        );
        setLocalStorageJSONData("events", response.data.data.events);
        setLocalStorageJSONData(
          "receiver_types",
          response.data.data.receiver_types
        );
        setLocalStorageJSONData("triggers", response.data.data.triggers);
        setLocalStorageJSONData("user_groups", response.data.data.user_groups);
        setLocalStorageJSONData("users", response.data.data.users);
      }
      setStatusOptions(getLocalStorageJSONData("status"));
      setTriggerOptions(getLocalStorageJSONData("triggers"));
      setEventOptions(getLocalStorageJSONData("events"));
      setReceiverTypesOptions(getLocalStorageJSONData("receiver_types"));
      setCommunicationModesOptions(
        getLocalStorageJSONData("communication_modes")
      );
      setTemplateApplicableOptions(
        getLocalStorageJSONData("template_applicable_in")
      );
      const userGroups = getLocalStorageJSONData("user_groups").map(
        (user_group) => ({
          value: user_group.id,
          label: user_group.title,
        })
      );
      const users = getLocalStorageJSONData("users").map((user) => ({
        value: user.id,
        label: `${user.first_name} ${user.last_name} (${user.email})`,
      }));

      setInternalSuggestions([
        ...userGroups.map((user_group) => ({
          value: "ug_" + user_group.value,
          label: "User Group - " + user_group.label,
        })),
        ...users.map((user) => ({
          value: "u_" + user.value,
          label: user.label,
        })),
      ]);
      setExternalSuggestions([
        {
          value: "candidate",
          label: "Candidate",
        },
      ]);
      reset(formData);
    } catch (err) {
      console.error(err.message);
    }
  };
  useEffect(() => {
    fetchNotificationTemplateData();
  }, []);

  const onSubmitNotificationTemplate = async (data) => {
    setShowErrors(false);

    const finalFormData = {
      template_title: data.template_title,
      receiver_type: data.receiver_type.value,
      receiver_internal: data.internal_receivers,
      receiver_external: data.external_receivers,
      triggered_at: data.trigger.value,
      triggered_days: data.number_of_days,
      event: data.event.value,
      mandatory_notification: data.mandatory_notification,
      template_applicable_in: data.template_applicable_in,
      communication_modes: data.communication_modes,
      whatsapp_text: data.whatsapp_text,
      sms_text: data.sms_text,
      sms_template_id: data.sms_template_id,
      email_subject: data.email_subject,
      email_body: data.email_body,
      send_reminder: data.send_reminder,
      reminder_data: [
        {
          reminder_at: data.reminder_at && data.reminder_at.value,
          reminder_number_of_days: data.reminder_number_of_days,
        },
      ],
      remarks: data.remarks,
      status: data.status.value,
    };

    let newFormData = finalFormData;
    const url = `${getApiUrl()}/api/notification-templates/create`;

    try {
      await axios.post(url, newFormData, {
        headers,
      });

      // Reset form after successful submission
      reset(formData);
      document.getElementById("close-modal-notification-template").click();
      document.getElementById("success_btn_notification_template").click();
      props.handleUpdateList();
    } catch (error) {
      if (error.response && error.response.status > 300) {
        const errorData = error.response.data.message;
        const errorField = error.response.data.field;
        setShowErrors(false);
        if (errorField) {
          setError(
            errorField,
            {
              type: "focus",
              message: errorData,
            },
            { shouldFocus: true }
          );
        } else {
          setErrorsMessage(errorData);
          setShowErrors(true);
        }
      } else {
        console.error("Error:", error);
      }
    }
  };

  const getUpdatedTags = (tags) => {
    const key = Object.keys(tags)[0];
    setValue(key, tags[key], { shouldDirty: true });
  };

  return (
    <div>
      {/* Add Notification Template */}
      <div
        className="modal custom-modal fade modal-padding"
        id="add_notification_template"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">Add New Notification Template</h5>
              <button
                type="button"
                id="close-modal-notification-template"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-details-wizard">
                <div className="row mb-3">
                  <div className="col-md-12">
                    <div
                      className="alert alert-secondary alert-dismissible fade show custom-alert-icon shadow-sm d-flex align-items-center"
                      role="alert"
                    >
                      <i className="feather-check-circle flex-shrink-0 me-2" />{" "}
                      Fields marked with an{" "}
                      <span className="text-danger"> &nbsp; * &nbsp;</span> are
                      mandatory.
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-info-fieldset">
                <form
                  onSubmit={handleSubmit(onSubmitNotificationTemplate)}
                  id="add_notification_template_form"
                >
                  <div className="contact-input-set">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Template Title{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="template_title"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.template_title ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.template_title?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Receiver of the communication{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="receiver_type"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.receiver_type ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={receiverTypesOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.receiver_type?.message}{" "}
                          </span>
                        </div>
                      </div>
                      {showReceiverType &&
                        ["internal", "both"].includes(
                          showReceiverType.value
                        ) && (
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Internal Receivers{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="internal_receivers"
                                control={control}
                                render={({ field }) => (
                                  <TagsSuggestion
                                    {...field}
                                    suggestions={internalSuggestions}
                                    className={`form-control ${
                                      errors?.internal_receivers
                                        ? "error-input"
                                        : ""
                                    }`}
                                    getUpdatedTags={getUpdatedTags}
                                    onChange={(selectedTags) => {
                                      field.onChange(selectedTags);
                                    }}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        )}
                      {showReceiverType &&
                        ["external", "both"].includes(
                          showReceiverType.value
                        ) && (
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                External Receivers{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="external_receivers"
                                control={control}
                                render={({ field }) => (
                                  <TagsSuggestion
                                    {...field}
                                    suggestions={externalSuggestions}
                                    className={`form-control ${
                                      errors?.external_receivers
                                        ? "error-input"
                                        : ""
                                    }`}
                                    getUpdatedTags={getUpdatedTags}
                                    onChange={(selectedTags) =>
                                      field.onChange(selectedTags)
                                    }
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                            </div>
                          </div>
                        )}

                      <div className="col-md-12">
                        <h6 className="input-block mb-0">
                          Occassion of the event
                        </h6>
                      </div>
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Triggered At <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="trigger"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.trigger ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={triggerOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.trigger?.message}{" "}
                          </span>
                        </div>
                      </div>
                      {showDays && showDays.value !== "same_day" && (
                        <div className="col-md-4">
                          <div className="input-block mb-3">
                            <label className="col-form-label">
                              Number of Days
                            </label>
                            <Controller
                              name="number_of_days"
                              control={control}
                              render={({ field }) => (
                                <input
                                  className={`form-control ${
                                    errors?.number_of_days ? "error-input" : ""
                                  }`}
                                  type="number"
                                  defaultValue=""
                                  min={0}
                                  max={1000}
                                  onChange={field.onChange}
                                  value={field.value}
                                  autoComplete="true"
                                />
                              )}
                            />
                            <span className="text-danger form-text">
                              {errors.number_of_days?.message}{" "}
                            </span>
                          </div>
                        </div>
                      )}

                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Event <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="event"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.event ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={eventOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.event?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Template Applicable In{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="template_applicable_in"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.template_applicable_in
                                    ? "error-input"
                                    : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                isMulti={true}
                                options={templateApplicableOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.template_applicable_in?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <label className="col-form-label">
                          Mandatory Notification?
                        </label>
                        <div className="">
                          <Controller
                            name="mandatory_notification"
                            control={control}
                            render={({ field }) => (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="1"
                                    checked={field.value === "1"}
                                    onChange={field.onChange}
                                  />
                                  <label>Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="0"
                                    checked={field.value === "0"}
                                    onChange={field.onChange}
                                  />
                                  <label>No</label>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Communication modes{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="communication_modes"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.communication_modes
                                    ? "error-input"
                                    : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                isMulti={true}
                                options={communicationModesOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.communication_modes?.message}{" "}
                          </span>
                        </div>
                      </div>
                      {showCommunicationModes &&
                        showCommunicationModes.length > 0 && (
                          <div className="col-md-12">
                            <h6 className="input-block mb-0">
                              Allowed Variables
                            </h6>
                            <label className="col-form-label font-italic">
                              ##candidateName##, ##eventDate##
                            </label>
                          </div>
                        )}
                      {showCommunicationModes &&
                        showCommunicationModes.some(
                          (option) => option.value === "whatsapp"
                        ) && (
                          <>
                            <div className="col-md-12">
                              <h6 className="input-block mb-0">WhatsApp</h6>
                            </div>
                            <div className="col-md-12">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  WhatsApp Template Text{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="whatsapp_text"
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      className={`form-control ${
                                        errors?.whatsapp_text
                                          ? "error-input"
                                          : ""
                                      }`}
                                      rows={5}
                                      defaultValue={""}
                                      onChange={field.onChange}
                                      value={field.value}
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.whatsapp_text?.message}{" "}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      {showCommunicationModes &&
                        showCommunicationModes.some(
                          (option) => option.value === "sms"
                        ) && (
                          <>
                            <div className="col-md-12">
                              <h6 className="input-block mb-0">SMS</h6>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  SMS Template ID
                                </label>
                                <Controller
                                  name="sms_template_id"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      className={`form-control`}
                                      type="text"
                                      defaultValue=""
                                      maxlength={255}
                                      onChange={field.onChange}
                                      value={field.value}
                                      autoComplete="true"
                                    />
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  SMS Template Text{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="sms_text"
                                  control={control}
                                  render={({ field }) => (
                                    <textarea
                                      className={`form-control ${
                                        errors?.sms_text ? "error-input" : ""
                                      }`}
                                      rows={5}
                                      defaultValue={""}
                                      onChange={field.onChange}
                                      value={field.value}
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.sms_text?.message}{" "}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      {showCommunicationModes &&
                        showCommunicationModes.some(
                          (option) => option.value === "email"
                        ) && (
                          <>
                            <div className="col-md-12">
                              <h6 className="input-block mb-0">Email</h6>
                            </div>
                            <div className="col-md-12">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Email Subject{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="email_subject"
                                  control={control}
                                  render={({ field }) => (
                                    <input
                                      className={`form-control ${
                                        errors?.email_subject
                                          ? "error-input"
                                          : ""
                                      }`}
                                      type="text"
                                      defaultValue=""
                                      maxlength={255}
                                      onChange={field.onChange}
                                      value={field.value}
                                      autoComplete="true"
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.email_subject?.message}{" "}
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="input-block mb-3">
                                <label className="col-form-label">
                                  Email Body{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <Controller
                                  name="email_body"
                                  control={control}
                                  render={({ field }) => (
                                    <DefaultEditor
                                      className={`form-control ${
                                        errors?.email_body ? "error-input" : ""
                                      }`}
                                      onChange={field.onChange}
                                      value={field.value}
                                    />
                                  )}
                                />
                                <span className="text-danger form-text">
                                  {errors.email_body?.message}{" "}
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Remarks</label>
                          <Controller
                            name="remarks"
                            control={control}
                            render={({ field }) => (
                              <textarea
                                className="form-control"
                                rows={5}
                                defaultValue={""}
                                onChange={field.onChange}
                                value={field.value}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <label className="col-form-label">
                          Send Reminders?
                        </label>
                        <div className="">
                          <Controller
                            name="send_reminder"
                            control={control}
                            render={({ field }) => (
                              <>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="1"
                                    checked={field.value === "1"}
                                    onChange={field.onChange}
                                  />
                                  <label>Yes</label>
                                </div>
                                <div className="form-check form-check-inline">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value="0"
                                    checked={field.value === "0"}
                                    onChange={field.onChange}
                                  />
                                  <label>No</label>
                                </div>
                              </>
                            )}
                          />
                        </div>
                      </div>
                      {showReminder === "1" && (
                        <>
                          <div className="col-md-12">
                            <h6 className="input-block mb-0">
                              Reminder of the event
                            </h6>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Reminder At{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="reminder_at"
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    className={`${
                                      errors?.reminder_at ? "error-input" : ""
                                    }`}
                                    {...field}
                                    onChange={field.onChange}
                                    value={field.value}
                                    options={triggerOptions.filter(
                                      (option) => option.value !== "same_day"
                                    )}
                                    isClearable={true}
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.reminder_at?.message}{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Number of Days{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <Controller
                                name="reminder_number_of_days"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    className={`form-control ${
                                      errors?.number_of_days
                                        ? "error-input"
                                        : ""
                                    }`}
                                    type="text"
                                    defaultValue=""
                                    onChange={field.onChange}
                                    value={field.value}
                                    autoComplete="true"
                                  />
                                )}
                              />
                              <span className="text-danger form-text">
                                {errors.number_of_days?.message}{" "}
                              </span>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="status"
                            control={control}
                            render={({ field }) => (
                              <Select
                                className={`${
                                  errors?.status ? "error-input" : ""
                                }`}
                                {...field}
                                onChange={field.onChange}
                                value={field.value}
                                options={statusOptions}
                                isClearable={true}
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.status?.message}{" "}
                          </span>
                        </div>
                      </div>

                      {showErrors && (
                        <>
                          <div className="col-sm-12 mb-3">
                            <Alert
                              message={errorsMessage}
                              type="danger"
                              close={true}
                            />
                          </div>
                        </>
                      )}
                      <div className="col-lg-12 text-end form-wizard-button">
                        <button
                          className="button btn-lights reset-btn"
                          type="reset"
                          onClick={() => {
                            reset(formData);
                            setShowErrors(false);
                          }}
                        >
                          Reset
                        </button>
                        <button className="btn btn-primary" type="submit">
                          Save
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_btn_notification_template"
                          data-bs-toggle="modal"
                          data-bs-target="#success_msg_notification_template"
                        >
                          Success
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Notification Template */}
      {/* Success Notification Template */}
      <div
        className="modal custom-modal fade"
        id="success_msg_notification_template"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Notification Template Created Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-success"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Notification Template */}
    </div>
  );
});

export default AddNotificationTemplateModal;
