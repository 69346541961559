import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
const SuccessAlertModal = ({ itemName, action, show, onClose }) => {
    // console.log(itemName);
    const titleText = `${itemName} ${(action === 'create'?  'Created' : 'Updated')} Successfully!!!`
    useEffect(() => {
        let timer;
        if (show) {
            timer = setTimeout(onClose, 2000); // Automatically close modal after 3 seconds
        }
        return () => clearTimeout(timer); // Cleanup the timer on unmount or onClose
    }, [show, onClose]);
    return (
        <div>
            <div className={`modal custom-modal fade ${show ? 'show' : ''}`}  id="success_alert_modal" role="dialog" >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="success-message text-center">
                                <div className="success-popup-icon bg-success">
                                    <i className="fa fa-check-circle" />
                                </div>
                                <h3>{titleText}</h3>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SuccessAlertModal;
