import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Alert from "../../Alert";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";

const DeleteSubmoduleModal = (props) => {
  const headers = getAuthHeaders();
  const [successAlert, setSuccessAlert] = useState(false);
  const [showButton, setShowButton] = useState(true);
  const handleDeleteSubmodule = async () => {
    setShowButton(false);
    try {
      const formattedData = {
        id: props.submodule.id,
        status: "deleted",
      };
      const response = await axios.patch(
        `${getApiUrl()}/api/masters/${props.moduleId}/submodules/update-status`,
        formattedData,
        {
          headers,
        }
      );

      const updated_row_count = response.data.data[0].updated_row_count;
      if (updated_row_count == 1) {
        setSuccessAlert(true);
        props.handleUpdateList();
        setTimeout(() => {
          setSuccessAlert(false);
          setShowButton(true);
          document.getElementById("close-delete-modal-submodule").click();
        }, 3000);
      }
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status === 400) {
        // const errorData = error.response.data.message;
        // setErrors(newErrors);
      } else {
        // Handle other types of errors (e.g., network errors)
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
    }
  };

  return (
    <div>
      {/* Delete Company */}
      <div
        className="modal custom-modal fade"
        id="delete_submodule"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                {successAlert && (
                  <div className="col-sm-12 mb-3">
                    <Alert
                      message="Enitity Type deleted successfully."
                      type="success"
                      close={false}
                    />
                  </div>
                )}
                <div className="success-popup-icon bg-danger">
                  <i className="la la-trash-restore" />
                </div>
                <h3>Are you sure, you want to delete</h3>
                {props.submodule && (
                  <h3>
                    "{props.submodule.title}"?
                  </h3>
                )}
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-delete-modal-submodule"
                  >
                    Close
                  </Link>
                  {showButton && (
                    <Link
                      to="#"
                      className="btn btn-primary"
                      onClick={handleDeleteSubmodule}
                    >
                      Yes
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Delete Company */}
    </div>
  );
};

export default DeleteSubmoduleModal;
