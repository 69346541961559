/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const Logout = () => {
  const loginUrl = localStorage.getItem("loginUrl") || '/login';
  localStorage.clear();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(loginUrl);
  }, []);
};

export default Logout;
