import React, { useRef } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import NotificationTemplatesTable from "./NotificationTemplatesTable";
import AddNotificationTemplateModal from "../../../../components/Administration/NotificationTemplates/AddNotificationTemplateModal";
import Footer from "../../../layout/Footer";

const NotificationTemplates = () => {
  const notificationTemplateRef = useRef();
  const updateNotificationTemplateTableData = () => {
    notificationTemplateRef.current.updateNotificationTemplatesList();
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Notification Templates"
          title="Dashboard"
          subtitle="Notification Templates"
          modal="#add_notification_template"
          name="Add Notification Template"
        />
        <NotificationTemplatesTable ref={notificationTemplateRef} />
        <AddNotificationTemplateModal handleUpdateList={updateNotificationTemplateTableData} />
      </div>
      <Footer />
    </div>
  );
};

export default NotificationTemplates;
