import React, { useState, useEffect } from "react";
import axios from "axios";
import { MedExpertIcon } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import StatusUpdateModal from "../../../components/modelpopup/StatusUpdateModal";
import PreLoader from "../../../components/PreLoader";
import SearchFilter from "../../../components/SearchFilter";
import {
  employeeStatus, // Importing employee status constants
} from "../../../utils/Constants"; // Importing constants
import {
  getEmployeeStatusBadgeClass, // Importing employee status class
} from "../../../utils/Misc"; // Importing Misc functions
import {
  getAuthHeaders, getApiUrl // Importing employee status class
} from "../../../utils/AuthUtils"; // Importing Misc functions

const PeopleList = () => {
  // State variables initialization
  const headers = getAuthHeaders();
  const [employeeData, setEmployeeData] = useState([]); // State to hold employee data
  const [currentPage, setCurrentPage] = useState(1); // State for current page number
  const [pageSize, setPageSize] = useState(10); // State for number of items per page
  const [totalRecords, setTotalRecords] = useState(0); // State for total number of records
  const [loading, setLoading] = useState(true); // State for loading status
  const [sorter, setSorter] = useState({}); // State for sorting information
  const [selectedEmployeeDetails, setSelectedEmployeeDetails] = useState({}); // State for selected employee details
  const [searchText, setSearchText] = useState('');


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${getApiUrl()}/api/employees/`,
        {
          params: {
            filter: searchText, // Placeholder for filter criteria
            limit: pageSize, // Number of items per page
            page: currentPage, // Current page number
            sortField: sorter.field, // Field to sort by
            sortOrder: sorter.order === "descend" ? "desc" : "asc", // Sort order (ascending or descending)
          },
          headers: headers,
        }
      );
      setEmployeeData(response.data.data); // Set fetched employee data
      setTotalRecords(response.data.total); // Set total number of records
      setLoading(false); // Update loading state to false after data is fetched
    } catch (error) {
      setLoading(true); // Set loading state to true on error
      console.error("Error fetching employee data", error);
    }
  };
  // Fetch data from API on component mount or when dependencies change
  useEffect(() => {   
    fetchData(); // Call fetch data function
  }, [currentPage, pageSize, sorter, searchText]); // Dependencies for useEffect hook

  // Handle table pagination and sorting changes
  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current); // Update current page
    setPageSize(pagination.pageSize); // Update page size
    setSorter(sorter); // Update sorting information
  };

  // Handle click on "Change Status" action
  const handleStatusChange = (employeeId, employeeName, currentStatus) => {
    setSelectedEmployeeDetails({
      // Update selected employee details for status change
      employeeId: employeeId,
      employeeName: employeeName,
      currentStatus: currentStatus,
    });
  };

  // Handle status update of an employee
  const handleStatusUpdate = async (employeeId, newStatus) => {
    try {
      const response = await axios.put(
        `${getApiUrl()}/api/employees/status`,
        { employeeId: employeeId, status: newStatus },
        { headers }
      );
      if (response.status === 200) {
        // Update employeeData state with new status
        setEmployeeData((prevData) =>
          prevData.map((employee) =>
            employee.id === employeeId
              ? { ...employee, status: newStatus }
              : employee
          )
        );
      }
    } catch (error) {
      console.error("Error updating employee status", error);
    }
  };

  // Table columns configuration
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to={`/profile/${record.id}`}>{text}</Link>
        </span>
      ),
      sorter: true, // Enable sorting by this column
    },
    {
      title: "Name",
      dataIndex: "full_name",
      render: (text, record) => (
        <span className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.avatar ? record.avatar : MedExpertIcon} />
          </Link>
          <Link to={`/profile/${record.id}`}>{text}</Link>
        </span>
      ),
      sorter: (a, b) => a.full_name.length - b.full_name.length, // Custom sorter function
    },
    {
      title: "Employee Code",
      dataIndex: "code",
      sorter: (a, b) => a.code.length - b.code.length, // Custom sorter function
    },
    {
      title: "Official Email",
      dataIndex: "work_email",
      sorter: (a, b) => a.work_email.length - b.work_email.length, // Custom sorter function
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile.length - b.mobile.length, // Custom sorter function
    },
    {
      title: "Designation",
      dataIndex: "designation",
      sorter: (a, b) => a.designation.length - b.designation.length, // Custom sorter function
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => {
        // let badgeClass = "badge "; // Base class for badges
        const badgeClass = getEmployeeStatusBadgeClass(text);
        return <span className={badgeClass}>{text}</span>; // Return styled status badge
      },
      sorter: (a, b) => a.status.length - b.status.length, // Custom sorter function
    },
    {
      title: "Action",
      sorter: true, // Enable sorting by this column
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link className="dropdown-item" to={`/profile/${record.id}`}>
              <i className="fa fa-eye m-r-5" /> View
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() =>
                handleStatusChange(record.id, record.full_name, record.status)
              }
              data-bs-toggle="modal"
              data-bs-target="#update-status"
            >
              <i className="fa fa-pencil m-r-5" /> Change Status
            </Link>
          </div>
        </div>
      ),
    },
  ];


  /// search componant functions 
  
  const handleSearchTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleSearchButtonClick = () => {
    fetchData(); 
  };

  /// search componant functions 

  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Employees"
            title="Dashboard"
            subtitle="Employees"
            modal="#add_employee"
            name="Add Employee"
            // Linkname="/people"
            Linkname="/employees-list"
          />
          {/* /Page Header */}
          {/* <EmployeeListFilter /> */}
          <div className="row">
            <div className="col-md-12">
              <PreLoader showLoader={loading} />
              {!loading && (
                <div className="table-responsive">
                  <SearchFilter
                    handleSearchTextChange={handleSearchTextChange}
                    onSearchButtonClick={handleSearchButtonClick}
                    placeholder="Search..."
                    inputClassName=""
                    buttonClassName=""
                    containerClassName=""
                    searchValue={searchText}
                  />
                  <Table
                    className="table-striped"
                    columns={columns}
                    dataSource={employeeData}
                    rowKey={(record) => record.id}
                    pagination={{
                      current: currentPage,
                      pageSize: pageSize,
                      total: totalRecords,
                      showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                      showSizeChanger: true, // Enables the page size selection dropdown
                      pageSizeOptions: ['10', '20', '50', '100'], // Options for the page size selection
                    }}
                    onChange={handleTableChange}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup />
        <StatusUpdateModal
          statusValues={employeeStatus}
          employeeDetails={selectedEmployeeDetails}
          onUpdateStatus={handleStatusUpdate} // Pass the status update handler
        />
      </div>
    </div>
  );
};

export default PeopleList;
