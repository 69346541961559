import axios from "axios";
import { TSLogoDark } from "../Routes/ImagePath";
export const getAuthHeaders = () => {
  const token = localStorage.getItem("token");
  return { Authorization: `Bearer ${token}` };
};

export const isAuthenticated = () => {
  return localStorage.getItem("token") !== null;
};

// Function to determine the API URL based on the current hostname
export const validateDomain = async () => {
  const domain = window.location.hostname;
  const localStorageKey = `domain_${domain}_validity`;  
  const cachedValidity = localStorage.getItem(localStorageKey);
  if (cachedValidity !== null) {
    // Step 3: Return true or false based on cached value
    return cachedValidity === 'true';
  }
  try {
    const data = await axios.get(`${getApiUrl()}/api/validate-domain`);
    // Assuming the API returns a response like { valid: true/false }
    const isValid = data.data.valid;
    // Step 5: Store the result in localStorage
    localStorage.setItem(localStorageKey, isValid ? 'true' : 'false');
    localStorage.setItem('isSubdomain', data.data.is_subdomain ? "true" : "false");  
    // Step 6: Return the validity result
    return isValid;
  } catch (error) {
    console.error("Error validating domain:", error);
    // Handle the error (optional)
    return false; // or you can decide how to handle failure (like returning false)
  }
  // return true;
};

// Function to determine the API URL based on the current hostname
export const getEntityDetails = async () => {
  try {
    const data = await axios.get(`${getApiUrl()}/api/entity-settings`);

    localStorage.setItem('enity_name', data.data.enitity_details !== "" ? data.data.enitity_details.entity_name : "Advantage");
    localStorage.setItem('enity_logo', data.data.enitity_details !== "" ? data.data.enitity_details.logo : TSLogoDark);   
    return  data.data.enitity_details;
    // Step 6: Return the validity result    
  } catch (error) {
    console.error("Error validating domain:", error);
    // Handle the error (optional)
    return false; // or you can decide how to handle failure (like returning false)
  }
  // return true;
};


// Function to determine the API URL based on the current hostname
export const getApiUrl = () => {
  const hostname = window.location.hostname; 
  // Determine the API URL based on the hostname
  let apiUrl;
  apiUrl = process.env.REACT_APP_BASE_URL;
  return apiUrl;
};
