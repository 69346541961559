import React, { useEffect, useState } from "react";
import axios from "axios";
import { ColorPicker, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { getAuthHeaders, getApiUrl } from "../../utils/AuthUtils";
import Alert from "../Alert";
import PreLoader from "../../components/PreLoader";

const validationSchema = Yup.object().shape({
  domain: Yup.string()
    .required("Domain is required")
    .matches(/^(?!(www|http|https)\.)\w+(\.\w+)+$/, "Invalid domain name")
});

const EditEntitySetupModal = (props) => {
  const headers = getAuthHeaders();
  const [formData, setFormData] = useState({    
    entity_id: null,
    domain: "",
    database_name: "",
  });
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading status
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const editFormData = {
    ...formData,    
    entity_id: props.entity.id,
    domain: props.entity.domain === "Not Set" ? "" : props.entity.domain,
    database_name: props.entity.database_name,
  };
  useEffect(() => {
    reset(editFormData);
  }, []);

  const onSubmitSetup = async (data) => {
    const finalFormData = {
      entity_id: data.entity_id,
      domain: data.domain,
    };
    let formData = finalFormData;
    const url = `${getApiUrl()}/api/entities/complete-setup`;
    let config = {
      headers,
    };
    setErrorAlert(false);
    setLoading(true);

    try {
      const response = await axios.patch(url, formData, config);
      if (response.data.status == 'success') {
        // props.handleUpdateList();
        document
          .getElementById("close-edit-setup-modal-entity")
          .click();
        document.getElementById("success_update_btn_setup").click();        
        props.handleUpdateData();
      }
      else if(response.data.status == 'error') {
        setErrorAlert(true);
        setErrorMessage(response.data.error);
      }
      setLoading(false);
    } catch (error) {
      // setErrorAlert(true);
      if (error.response && error.response.status >= 400) {
        setErrorAlert(true);
        setErrorMessage(error.response.data.error);
      } else {
        // Handle other types of errors (e.g., network errors)
        setErrorAlert(true);
        setErrorMessage(error);
        console.error("Error:", error);
        // You can show a generic error message to the user if needed
      }
      setLoading(false);
    }
  };

  return (
    <div>
      {/* Edit Company setup*/}
      <div
        className="modal custom-modal fade modal-padding"
        id="edit_company_setup"
        role="dialog"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header header-border justify-content-between p-0">
              <h5 className="modal-title">
                Complete Company Setup - {props.entity.entity_name}
              </h5>
              <button
                type="button"
                className="btn-close position-static"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="close-edit-setup-modal-entity"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="add-info-fieldset">
                <form onSubmit={handleSubmit(onSubmitSetup)}>                
                  <div className="contact-input-set">
                  {showErrorAlert && (              
                  <Alert type="danger" message={errorMessage} />
                )}
                    <div className="row">
                      <div className="col-md-4">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Domain <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="domain"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.domain ? "error-input" : ""
                                }`}
                                type="text"
                                defaultValue=""
                                maxlength={255}
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                          <span className="text-danger form-text">
                            {errors.domain?.message}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Database</label>
                          <Controller
                            name="database_name"
                            control={control}
                            render={({ field }) => (
                              <input
                                className={`form-control ${
                                  errors?.email ? "error-input" : ""
                                }`}
                                type="text"
                                readOnly={true}
                                defaultValue=""
                                onChange={field.onChange}
                                value={field.value}
                                autoComplete="true"
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 text-end form-wizard-button">                        
                        <button
                          className="button btn-lights"
                          type="button"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          className="btn btn-primary"
                          type="submit"
                          disabled={!isDirty}
                        >
                          Update
                        </button>
                        <button
                          className="btn btn-primary d-none"
                          type="button"
                          id="success_update_btn_setup"
                          data-bs-toggle="modal"
                          data-bs-target="#success_update_msg_setup"
                        >
                          Success
                        </button>
                        <PreLoader showLoader={loading} />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Company */}
      {/* Success Company */}
      <div
        className="modal custom-modal fade"
        id="success_update_msg_setup"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="success-message text-center">
                <div className="success-popup-icon">
                  <i className="la la-building" />
                </div>
                <h3>Company Setup Completed Successfully!!!</h3>
                <div className="col-lg-12 text-center form-wizard-button">
                  <Link
                    to="#"
                    className="button btn-lights"
                    data-bs-dismiss="modal"
                    id="close-modal-update-success-setup"
                  >
                    Close
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Success Company */}
    </div>
  );
};

export default EditEntitySetupModal;
