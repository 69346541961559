import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Alert from '../Alert';
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const EmployeeBankDetailsPopup = ({ employeeId, bankInformation = {}, onSubmitSuccess }) => {
    
    const headers = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    const [bankData, setBankData] = useState({});
    const [errors, setErrors] = useState({});

    const initialBankInfo = useRef(bankInformation);

    useEffect(() => {
        setBankData({
            bank_name: bankInformation.bank_name || '',
            branch_name: bankInformation.branch_name || '',
            account_number: bankInformation.account_number || '',
            account_holder_name: bankInformation.account_holder_name || '',
            ifsc_code: bankInformation.ifsc_code || '',
            swift_code: bankInformation.swift_code || '',
        });
    }, []);

    const handleChange = (field, value) => {
        setBankData((prevBankData) => ({
            ...prevBankData,
            [field]: value,
        }));
    };

    const validateBankForm = () => {
        const newErrors = {};
        if (!bankData.bank_name) newErrors.bank_name = 'Bank name is required';
        if (!bankData.branch_name) newErrors.branch_name = 'Branch name is required';
        if (!bankData.account_number) {
            newErrors.account_number = 'Account number is required';
        } else if (!/^[0-9]{8,}$/.test(bankData.account_number)) {
            newErrors.account_number = 'Account number must be at least 8 digits';
        }
        if (!bankData.account_holder_name) newErrors.account_holder_name = 'Account holder name is required';
        if (!bankData.ifsc_code) {
            newErrors.ifsc_code = 'IFSC code is required';
        } else if (!/^[0-9A-Za-z]{10}$/.test(bankData.ifsc_code)) {
            newErrors.ifsc_code = 'IFSC code must be exactly 10 characters';
        }
        if (bankData.swift_code && !/^[0-9A-Za-z]{8,}$/.test(bankData.swift_code)) {
            newErrors.swift_code = 'SWIFT code must be at least 8 characters if provided';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorAlert(false)
        if (!validateBankForm()) {
            setErrorAlert(true)
            return;
        }

        try {
            let response;
            if (bankInformation.id) {
                response = await axios.put(`${getApiUrl()}/api/employees/${employeeId}/bank/${bankInformation.id}`, bankData, {headers});
            } else {
                response = await axios.post(`${getApiUrl()}/api/employees/${employeeId}/bank`, bankData, {headers});
            }
            setSuccessAlert(true);
            setErrorAlert(false);
            setTimeout(() => {
                document.getElementById("close-bank-data-modal").click();
                setSuccessAlert(false);
            }, 3000);
            onSubmitSuccess(bankData);
        } catch (error) {
            setErrorAlert(true);
            if (error.response && error.response.status === 400) {
                const errorData = error.response.data.message;
                const newErrors = {};

                Object.keys(errorData).forEach((field) => {
                    const errorMessage = errorData[field][0];
                    newErrors[field] = errorMessage;
                });
                setErrors(newErrors);
            } else {
                console.error("Error:", error);
            }
        }
    };

    return (
        <div id="bank_data_modal" className="modal custom-modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Bank Information</h5>
                        <button
                            id="close-bank-data-modal"
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {showSuccessAlert && <Alert type="success" message="Bank details submitted successfully!" />}
                        {showErrorAlert && <Alert type="danger" message="Error submitting bank details." />}
                        <form onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Bank Name <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.bank_name}
                                                    onChange={(e) => handleChange('bank_name', e.target.value)}
                                                />
                                                {errors.bank_name && <div className="text-danger">{errors.bank_name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Branch <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.branch_name}
                                                    onChange={(e) => handleChange('branch_name', e.target.value)}
                                                />
                                                {errors.branch_name && <div className="text-danger">{errors.branch_name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Account Holder Name <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.account_holder_name}
                                                    onChange={(e) => handleChange('account_holder_name', e.target.value)}
                                                />
                                                {errors.account_holder_name && <div className="text-danger">{errors.account_holder_name}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">
                                                    Account Number <span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.account_number}
                                                    onChange={(e) => handleChange('account_number', e.target.value)}
                                                />
                                                {errors.account_number && <div className="text-danger">{errors.account_number}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">IFSC code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.ifsc_code}
                                                    onChange={(e) => handleChange('ifsc_code', e.target.value)}
                                                />
                                                {errors.ifsc_code && <div className="text-danger">{errors.ifsc_code}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3">
                                                <label className="col-form-label">SWIFT code</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={bankData.swift_code}
                                                    onChange={(e) => handleChange('swift_code', e.target.value)}
                                                />
                                                {errors.swift_code && <div className="text-danger">{errors.swift_code}</div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button type="submit" className="btn btn-primary submit-btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeBankDetailsPopup;
