import axios from "axios";
import { getApiUrl, getAuthHeaders } from "./AuthUtils";

export const getEmployeeStatusBadgeClass = (status) => {
    let badgeClass = "badge ";
  
    switch (status) {
      case "active":
      case "probation":
        badgeClass += "badge-outline-success";
        break;
      case "retired":
      case "suspended":
      case "temporary layoff":
        badgeClass += "badge-outline-warning";
        break;
      case "contract ended":
      case "deceased":
      case "deleted":
      case "permanent layoff":
      case "resigned":
      case "terminated":
        badgeClass += "badge-outline-danger";
        break;
      default:
        badgeClass += "badge-outline-primary"; // Default class for unlisted statuses
    }
      
    return "badge "+badgeClass;
};
export const getEmployeeListData = async () =>{
  const headers = getAuthHeaders();  
    try {
      const response = await axios.get(`${getApiUrl()}/api/employees/employee-list`, {headers});
      // console.log('axios data', response.data.data);
      return response.data.data; // Assuming the data you need is in the response.data
    } catch (error) {
        console.error("Error fetching employee list data:", error);
        throw error; // Optionally re-throw the error to handle it elsewhere
    }
};

export const setLocalStorageJSONData = (key, data) => {
  let parsedString = JSON.stringify([]);
  if(typeof data !== 'undefined'){
    parsedString = JSON.stringify(data)
  }
  localStorage.setItem(
    key,
    parsedString
  )
}

export const getLocalStorageJSONData = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const getStatusBadgeClass = (status) => {
  let badgeClass = 'btn btn-white btn-sm pe-none ';
  switch (status) {
      case "active":
        badgeClass += "badge-outline-success status-color";
        break;
      case "inactive":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      case "deleted":
        badgeClass += "badge-outline-danger status-color-dg";
        break;
      default:
        badgeClass += "badge-outline-success status-color-dg"; // Default class for unlisted statuses
  }
  return badgeClass;
}