import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { ts_logo } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import { Table } from "antd";
import StatusUpdateStateModal from "./StatusUpdateStateModal";
import DeleteStateModal from "./DeleteStateModal";
import EditStateModal from "./EditStateModal";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import AddStateModal from "./AddStateModal";

const States = (props) => {
  const addStateRef = useRef();
  const { countryId } = useParams();
  const headers = getAuthHeaders();
  const [statesData, setStatesData] = useState([]);
  const [countryData, setCountryData] = useState(null);
  const [stateData, setStateData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  const fetchData = async () => {
    try {
      const responseCountry = await axios.get(
        getApiUrl() + `/api/masters/countries/${countryId}`,
        {
          headers,
        }
      );
      setCountryData(responseCountry.data.data);
      const response = await axios.get(
        getApiUrl() + `/api/masters/${countryId}/states`,
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let stateData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      stateData = stateData.map((item) => {
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setStatesData(stateData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <Link to="#" className="profile-split">
                {record.name}
              </Link>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "State Code",
      dataIndex: "state_code",
    },
    {
      title: "GST Code",
      dataIndex: "gst_code",
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_state"
                onClick={(e) => {
                  e.preventDefault();
                  setStateData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          <Link
              className="dropdown-item"
              to={`/masters/${countryId}/${record.id}/cities`}
            >
              <i className="fa fa-pencil m-r-5" /> Cities
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_state"
              onClick={(e) => {
                e.preventDefault();
                setStateData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_state"
              onClick={(e) => {
                e.preventDefault();
                setStateData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h3 className="page-title">States{(countryData && ` - ${countryData.name}`)}</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Masters</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/masters/countries`}>Countries</Link>
                </li>
                <li className="breadcrumb-item active">States</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <Link
                  to="#"
                  className="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_state"
                  onClick={(e) => {
                    e.preventDefault();
                    addStateRef.current.resetForm();
                    // setCompanyId(record.id);
                  }}
                >
                  <i className="la la-plus-circle" /> Add State
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <hr />
        {/* /Search Filter */}
        <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearchTextChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={statesData}
                rowKey={(record) => record.id}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <AddStateModal
        countryId={countryId}
        country={countryData}
        ref={addStateRef}
        handleUpdateList={handleUpdateList}
      />
      <EditStateModal
        countryId={countryId}
        country={countryData}
        state={stateData}
        handleUpdateList={handleUpdateList}
      />
      <StatusUpdateStateModal
        countryId={countryId}
        state={stateData}
        handleUpdateList={handleUpdateList}
        newStatus={newStatus}
      />
      <DeleteStateModal
        countryId={countryId}
        state={stateData}
        handleUpdateList={handleUpdateList}
      />
    </div>
  );
};

export default States;
