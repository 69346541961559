import React, { useEffect, useState } from "react";
import { MedExpertIcon } from "../../Routes/ImagePath";
import axios from "axios";
import Alert from "../Alert";
import { validateImage } from "../../utils/ValidatorFunctions";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const EmployeeAvatarUploadModal = ({
  employeeId,
  avatar,
  updateEmployeeAvatar,
}) => {
  const authHeaders = getAuthHeaders();
  const [file, setFile] = useState(null); // State to hold the selected file
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [employeeAvatar, setEmployeeAvatar] = useState(MedExpertIcon);
  useEffect(() => {
    const form = document.getElementById("image-form");
    if (form) {
      form.reset();
    }
    setFile(null);
    setEmployeeAvatar(avatar ? avatar : MedExpertIcon);
  }, [avatar]);
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0]; // Get the selected file from event
    // console.log(selectedFile);
    setFile(selectedFile); // Update the file state with the selected file
    // Optionally, update the employeeAvatar state temporarily with the selected file
    if (selectedFile && validateImage(selectedFile)) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setEmployeeAvatar(reader.result); // Update employeeAvatar with the selected file temporarily
      };
    } else {
      setFile(null);
      setEmployeeAvatar(avatar ? avatar : MedExpertIcon); // Reset to default avatar if invalid file type
      setErrorMessage(
        "Please select a valid file type. only svg, bmp, png, gif, jpeg, jpg, webp files are supported "
      );
      setErrorAlert(true); // Show error alert for invalid file type
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    setErrorAlert(false);

    if (file) {
      const formData = new FormData();
      formData.append("employee_profile_image", file);
      formData.append("employee_id", employeeId);

      try {
        const headers = {
          "Content-Type": "multipart/form-data",
          ...authHeaders, // Merge the headers
        };
        const response = await axios.post(
          `${getApiUrl()}/api/employees/update-avatar`,
          formData,
          { headers }
        );
        setSuccessAlert(true);
        setErrorAlert(false);
        setEmployeeAvatar(response.data.avatar);
        updateEmployeeAvatar(response.data.avatar);
        setTimeout(() => {
          document.getElementById("close_employee_avatar_modal").click();
          setSuccessAlert(false);
        }, 3000);
        // Handle the response from the server, e.g., update the user's profile image
        console.log("File uploaded successfully", response.data);

        // Optionally: Close the modal or update UI upon successful upload
      } catch (error) {
        setErrorMessage("Failed to update profile image.");
        console.error("Error uploading file", error);
      }
    } else {
      setErrorAlert(true);
      setErrorMessage("Please select a file.");
      console.log("No file selected");
    }
  };

  return (
    <div
      id="employee_avatar_modal"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Update Employee Image</h5>
            <button
              id="close_employee_avatar_modal"
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {showSuccessAlert && (
              <Alert
                type="success"
                message="Profile image updated successfully!"
              />
            )}
            {showErrorAlert && <Alert type="danger" message={errorMessage} />}
            <form id="image-form" onSubmit={handleSubmit}>
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="profile-img-wrap edit-img">
                        <img
                          className="inline-block"
                          src={employeeAvatar}
                          alt="user"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3 mb-3">
                        <label className="col-form-label">
                          Select Image To Update{" "}
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3 mb-3">
                        {/* Input for file selection */}
                        <input
                          className="upload"
                          type="file"
                          onChange={handleFileChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button type="submit" className="btn btn-primary submit-btn">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeAvatarUploadModal;
