import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import {
  Avatar_01,
  MedExpertIcon,
  Avatar_03,
} from "../../../../../Routes/ImagePath";

const ShortListCandidatesTable = () => {
  const data = [
    {
      id: 1,
      image: MedExpertIcon,
      name: "Yogesh Mesta",
      role: "Payment Posting Specialist",
      jobtitle: "Payment Posting Specialist",
      department: "Billing",
    },
  ];

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id.length - b.id.length,
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">
          <Link to="#" className="avatar">
            <img alt="" src={record.image} />
          </Link>
          <Link to="#">{text}</Link>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Job Title",
      dataIndex: "jobtitle",
      render: (text) => <Link to="/app/administrator/job-details">{text}</Link>,
      sorter: (a, b) => a.jobtitle.length - b.jobtitle.length,
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: () => (
        <div className="action-label">
          <Link className="btn btn-white btn-sm btn-rounded" to="#">
            <i className="far fa-dot-circle text-danger" /> Offered
          </Link>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
  ];
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default ShortListCandidatesTable;
