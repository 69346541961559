import React, { useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import axios from "axios"; // Import Axios
import { familyRelations } from "../../utils/Constants";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";
import Alert from "../Alert";
import moment from "moment";
const FamilyInformationModal = ({ employeeId, onSubmitSuccess }) => {
  const headers = getAuthHeaders();
  // Initialize state for family members
  const [familyMembers, setFamilyMembers] = useState([
    { name: "", relationship: "", dob: "", phone: "" }, // Default initial family member
  ]);

  // State for errors
  const [errors, setErrors] = useState({});
  const [showSuccessAlert, setSuccessAlert] = useState(false);
  const [showErrorAlert, setErrorAlert] = useState(false);
  // Custom styles for the Select component
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  // Function to add a new family member
  const addFamilyMember = () => {
    setFamilyMembers([
      ...familyMembers,
      { name: "", relationship: "", dob: "", phone: "" },
    ]);
  };

  // Function to delete a family member by index
  const deleteFamilyMember = (index) => {
    if (familyMembers.length > 1) {
      setFamilyMembers(familyMembers.filter((_, i) => i !== index));
    }
  };

  // Function to handle input changes
  const handleInputChange = (index, field, value) => {
    const updatedMembers = [...familyMembers];
    updatedMembers[index][field] = value;
    setFamilyMembers(updatedMembers);

    // Clear error when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [index]: {
        ...prevErrors[index],
        [field]: "",
      },
    }));
  };

  // Function to validate the form fields
  const validateFields = () => {
    console.log('asd')
    const newErrors = {};
    let isValid = true;

    familyMembers.forEach((member, index) => {
      const memberErrors = {};

      // Validate name
      if (!member.name.trim()) {
        memberErrors.name = "Name is required.";
        isValid = false;
      }

      // Validate relationship
      if (!member.relationship) {
        memberErrors.relationship = "Relationship is required.";
        isValid = false;
      }

      if (Object.keys(memberErrors).length > 0) {
        newErrors[index] = memberErrors;
      }
    });

    setErrors(newErrors);
    return isValid;
  };


  // Function to handle form submission    
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorAlert(false);

    if (validateFields()) {
      // Format the start and completion dates
      const formattedEntries = familyMembers.map((entry) => ({
        ...entry,
        dob: (entry.dob !== '' ? moment(entry.dob).format("YYYY-MM-DD") : '')
      }));

      const formattedData = {
        familyMembers: formattedEntries,
      };

      try {
        const response = await axios.post(
          `${getApiUrl()}/api/employees/${employeeId}/families`,
          formattedData,
          { headers }
        );

        // Reset form after successful submission
        setFamilyMembers([
          { name: "", relationship: "", dob: "", phone: "" },
        ]);
        setErrors({});
        setSuccessAlert(true);
        setTimeout(() => {
          document.getElementById("modal-famil-info-close").click();
          setSuccessAlert(false);
          onSubmitSuccess();
        }, 3000);
      } catch (error) {
        setErrorAlert(true);
        if (error.response && error.response.status === 400) {
          const errorData = error.response.data.errors;
          const newErrors = {};

          // Map error fields to their respective error messages
          Object.keys(errorData).forEach((field) => {
            const [mainField, indexStr, subField] = field.split(".");
            const index = parseInt(indexStr, 10);

            // Initialize the nested error object if it doesn't exist
            if (!newErrors[index]) {
              newErrors[index] = {};
            }

            // Assign the error message to the appropriate field
            newErrors[index][subField] = errorData[field][0];
          });

          // Set errors state to display error messages
          setErrors(newErrors);
        } else {
          // Handle other types of errors (e.g., network errors)
          console.error("Error:", error);
          // You can show a generic error message to the user if needed
        }
      }
    } else {
      setErrorAlert(true);
    }
  };

  return (
    <>
      <div id="family_info_modal" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Family Information</h5>
              <button
                id="modal-famil-info-close"
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              {showSuccessAlert && <Alert type="success" message="Education details submitted successfully!" />}
              {showErrorAlert && <Alert type="danger" message="Error submitting bank details." />}
              <form onSubmit={handleSubmit}>
                <div className="form-scroll">
                  {familyMembers.map((member, index) => (
                    <div className="card" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Family Member{" "}
                          <Link
                            to="#"
                            className={`delete-icon ${familyMembers.length === 1 ? "disabled" : ""}`}
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default link behavior
                              deleteFamilyMember(index);
                            }}
                            style={{
                              cursor: familyMembers.length === 1 ? "not-allowed" : "pointer",
                              opacity: familyMembers.length === 1 ? 0.5 : 1,
                            }}
                          >
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                value={member.name}
                                onChange={(e) =>
                                  handleInputChange(index, "name", e.target.value)
                                }
                              />
                              {errors[index]?.name && (<div className="text-danger">{errors[index].name}</div>)}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Relationship <span className="text-danger">*</span>
                              </label>
                              <Select
                                options={familyRelations}
                                value={familyRelations.find(
                                  (option) => option.value === member.relationship
                                )}
                                onChange={(selectedOption) =>
                                  handleInputChange(
                                    index,
                                    "relationship",
                                    selectedOption.value
                                  )
                                }
                                styles={customStyles}
                              />
                              {errors[index]?.relationship && (<div className="text-danger">{errors[index].relationship}</div>)}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Date of Birth
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                value={member.dob}
                                onChange={(e) =>
                                  handleInputChange(index, "dob", e.target.value)
                                }
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">
                                Phone
                              </label>
                              <input
                                className="form-control"
                                type="tel"
                                value={member.phone}
                                onChange={(e) =>
                                  handleInputChange(index, "phone", e.target.value)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="add-more">
                    <Link to="#" onClick={addFamilyMember}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FamilyInformationModal;
