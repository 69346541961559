import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { contactRelations } from '../../utils/Constants';
import Alert from "../Alert";
import { getApiUrl, getAuthHeaders } from "../../utils/AuthUtils";

const EmployeeContactPopup = ({ primaryContactData, secondaryContactData, employeeId, onSubmitSuccess }) => {
    const headers = getAuthHeaders();
    const [showSuccessAlert, setSuccessAlert] = useState(false);
    const [showErrorAlert, setErrorAlert] = useState(false);
    const [primaryContact, setPrimaryContact] = useState({
        name: '',
        relationship: null,
        phone: '',
        alternative_phone: '',
    });
    const [secondaryContact, setSecondaryContact] = useState({
        name: '',
        relationship: null,
        phone: '',
        alternative_phone: '',
    });
    const [errors, setErrors] = useState({});


    const handleChange = (setContact, field, value) => {
        setContact((prevContact) => ({
            ...prevContact,
            [field]: value,
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!primaryContact.name) newErrors.primaryName = 'Primary contact name is required';
        if (!primaryContact.relationship) newErrors.primaryRelationship = 'Primary contact relationship is required';
        if (!primaryContact.phone) newErrors.primaryPhone = 'Primary contact number is required';

        if (
            secondaryContact.name ||
            secondaryContact.relationship ||
            secondaryContact.phone ||
            secondaryContact.alternative_phone
        ) {
            if (!secondaryContact.name) newErrors.secondaryName = 'Secondary contact name is required if any secondary contact field is filled';
            if (!secondaryContact.relationship) newErrors.secondaryRelationship = 'Secondary contact relationship is required if any secondary contact field is filled';
            if (!secondaryContact.phone) newErrors.secondaryPhone = 'Secondary contact number is required if any secondary contact field is filled';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            try {
                const isSecondaryContactEmpty = !secondaryContact.name && !secondaryContact.relationship && !secondaryContact.contactNumber && !secondaryContact.altContactNumber;
                
                const payload = {
                    primary: primaryContact,
                    ...(isSecondaryContactEmpty ? {} : { secondary: secondaryContact }),
                };
                
                const response = await axios.post(`${getApiUrl()}/api/employees/${employeeId}/contacts`, payload, {headers});

                onSubmitSuccess(primaryContact, (isSecondaryContactEmpty ? {} : secondaryContact) );
                setSuccessAlert(true);
                setTimeout(() => {
                    document.getElementById("close-modal-emergency-contact").click();
                    setSuccessAlert(false);
                }, 3000);
                console.log(response.data);
                // Handle success (e.g., close the modal, show a success message, etc.)
            } catch (error) {
                setErrorAlert(true);
                console.error(error);
                // Handle error (e.g., show an error message)
            }
        }
    };
    useEffect(() => {
        if (primaryContactData) {
            setPrimaryContact({
                name: primaryContactData.name || '',
                relationship: primaryContactData.relationship || null,
                phone: primaryContactData.phone || '',
                alternative_phone: primaryContactData.alternative_phone || '',
            });
        }
        if (secondaryContactData) {
            setSecondaryContact({
                name: secondaryContactData.name || '',
                relationship: secondaryContactData.relationship || null,
                phone: secondaryContactData.phone || '',
                alternative_phone: secondaryContactData.alternative_phone || '',
            });
        }
    }, [primaryContactData, secondaryContactData]);
    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#ff9b44' : '#fff',
            color: state.isFocused ? '#fff' : '#000',
            '&:hover': {
                backgroundColor: '#ff9b44',
            },
        }),
    };

    return (
        <div id="emergency_contact_modal" className="modal custom-modal fade" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Emergency Contact Details</h5>
                        <button id="close-modal-emergency-contact" type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Primary Contact</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Name <span className="text-danger">*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={primaryContact.name}
                                                    onChange={(e) => handleChange(setPrimaryContact, 'name', e.target.value)}
                                                />
                                                {errors.primaryName && <div className="text-danger">{errors.primaryName}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Relationship <span className="text-danger">*</span></label>
                                                <Select
                                                    options={contactRelations}
                                                    value={contactRelations.find(
                                                        (option) => option.value === primaryContact.relationship
                                                    )}
                                                    onChange={(selectedOption) => handleChange(setPrimaryContact, 'relationship', selectedOption.value)}
                                                    styles={customStyles}
                                                />
                                                {errors.primaryRelationship && <div className="text-danger">{errors.primaryRelationship}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Contact Number <span className="text-danger">*</span></label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={primaryContact.phone}
                                                    onChange={(e) => handleChange(setPrimaryContact, 'phone', e.target.value)}
                                                />
                                                {errors.primaryPhone && <div className="text-danger">{errors.primaryPhone}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Alternative Contact Number</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={primaryContact.alternative_phone}
                                                    onChange={(e) => handleChange(setPrimaryContact, 'alternative_phone', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h3 className="card-title">Secondary Contact</h3>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Name</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    value={secondaryContact.name}
                                                    onChange={(e) => handleChange(setSecondaryContact, 'name', e.target.value)}
                                                />
                                                {errors.secondaryName && <div className="text-danger">{errors.secondaryName}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Relationship</label>
                                                <Select
                                                    options={contactRelations}
                                                    value={contactRelations.find(
                                                        (option) => option.value === secondaryContact.relationship
                                                    )}
                                                    onChange={(selectedOption) => handleChange(setSecondaryContact, 'relationship', selectedOption.value)}
                                                    styles={customStyles}
                                                />
                                                {errors.secondaryRelationship && <div className="text-danger">{errors.secondaryRelationship}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Contact Number</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={secondaryContact.phone}
                                                    onChange={(e) => handleChange(setSecondaryContact, 'phone', e.target.value)}
                                                />
                                                {errors.secondaryPhone && <div className="text-danger">{errors.secondaryPhone}</div>}
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="input-block mb-3 mb-3">
                                                <label className="col-form-label">Alternative Contact Number</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    value={secondaryContact.alternative_phone}
                                                    onChange={(e) => handleChange(setSecondaryContact, 'alternative_phone', e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        {showSuccessAlert && (
                                            <div className="col-sm-12">
                                                <Alert
                                                    message="Emergency Contact has been created/updated successfully!!"
                                                    type="success"
                                                    close={false}
                                                />
                                            </div>
                                        )}
                                        {showErrorAlert && (
                                            <div className="col-sm-12">
                                                <Alert
                                                    message="Failed to create/update employee due to errors!"
                                                    type="danger"
                                                    close={false}
                                                />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="submit-section">
                                <button className="btn btn-primary submit-btn">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EmployeeContactPopup;
