import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

import { ts_logo } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import { Table } from "antd";
import StatusUpdateCityModal from "./StatusUpdateCityModal";
import DeleteCityModal from "./DeleteCityModal";
import EditCityModal from "./EditCityModal";
import { getAuthHeaders, getApiUrl } from "../../../utils/AuthUtils";
import AddCityModal from "./AddCityModal";

const Cities = (props) => {
  const addCityRef = useRef();
  const { stateId, countryId } = useParams();
  const headers = getAuthHeaders();
  const [citiesData, setCitiesData] = useState([]);
  const [stateData, setStateData] = useState(null);
  const [cityData, setCityData] = useState(null);
  const [newStatus, setNewStatus] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortingColumn, setSortingColumn] = useState("id");
  const [sortingOrder, setSortingOrder] = useState("desc");
  const [filterText, setFilterText] = useState("");

  const fetchData = async () => {
    try {
      const responseState = await axios.get(
        getApiUrl() + `/api/masters/${countryId}/states/${stateId}`,
        {
          headers,
        }
      );
      setStateData(responseState.data.data);
      const response = await axios.get(
        getApiUrl() + `/api/masters/${stateId}/cities`,
        {
          params: {
            filter: filterText, // add filter if needed
            limit: pageSize,
            page: currentPage,
            order: sortingOrder,
            orderField: sortingColumn,
          },
          headers,
        }
      );
      let cityData = response.data.data.data;
      setTotalRecords(response.data.data.total);
      cityData = cityData.map((item) => {
        return {
          ...item,
          image: ts_logo,
          status: item.status === "active" ? "Active" : "Inactive",
        };
      });
      setCitiesData(cityData);
    } catch (error) {
      console.error("Error fetching entities data", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, sortingColumn, sortingOrder, filterText]);

  const handleUpdateList = () => {
    fetchData();
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (typeof sorter.order === "undefined") {
      setSortingOrder("desc");
      setSortingColumn("id");
    } else {
      setSortingColumn(sorter.field);
      setSortingOrder(sorter.order === "ascend" ? "asc" : "desc");
    }
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleSearchTextChange = (e) => {
    const value = e.target.value;
    setFilterText(value);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="d-flex">
          <div>
            <h2 className="table-avatar d-flex align-items-center">
              <Link to="#" className="profile-split">
                {record.name}
              </Link>
            </h2>
          </div>
        </div>
      ),
      sorter: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (text, record) => {
        return (
          <>
            <div className="dropdown action-label">
              <Link
                to="#"
                className={
                  text === "Inactive"
                    ? "btn btn-white btn-sm badge-outline-danger status-color-dg"
                    : "btn btn-white btn-sm badge-outline-success status-color"
                }
                data-bs-toggle="modal"
                data-bs-target="#change_status_city"
                onClick={(e) => {
                  e.preventDefault();
                  setCityData(record);
                  record.status === "Active"
                    ? setNewStatus("Inactive")
                    : setNewStatus("Active");
                }}
              >
                {" "}
                {text}{" "}
              </Link>
            </div>
          </>
        );
      },
    },

    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_city"
              onClick={(e) => {
                e.preventDefault();
                setCityData(record);
                // setCompanyId(record.id);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_city"
              onClick={(e) => {
                e.preventDefault();
                setCityData(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      {/* Page Content */}
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h3 className="page-title">Cities{(stateData && ` - ${stateData.name}`)}</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="#">Masters</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/masters/countries`}>Countries</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/masters/${countryId}/states`}>States</Link>
                </li>
                <li className="breadcrumb-item active">Cities</li>
              </ul>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <Link
                  to="#"
                  className="btn add-btn"
                  data-bs-toggle="modal"
                  data-bs-target="#add_city"
                  onClick={(e) => {
                    e.preventDefault();
                    addCityRef.current.resetForm();
                    // setCompanyId(record.id);
                  }}
                >
                  <i className="la la-plus-circle" /> Add City
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Header */}
        <hr />
        {/* /Search Filter */}
        <div className="filter-section filter-bottom">
          <ul>
            <li>
              <div className="search-set">
                <div className="search-input">
                  <Link to="#" className="btn btn-searchset">
                    <i className="las la-search" />
                  </Link>
                  <div className="dataTables_filter">
                    <label>
                      {" "}
                      <input
                        type="search"
                        className="form-control form-control-sm"
                        placeholder="Search"
                        onChange={handleSearchTextChange}
                      />
                    </label>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                columns={columns}
                dataSource={citiesData}
                rowKey={(record) => record.id}
                pagination={{
                  current: currentPage,
                  pageSize: pageSize,
                  total: totalRecords,
                }}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
      <AddCityModal
        stateId={stateId}
        state={stateData}
        ref={addCityRef}
        handleUpdateList={handleUpdateList}
      />
      <EditCityModal
        stateId={stateId}
        state={stateData}
        city={cityData}
        handleUpdateList={handleUpdateList}
      />
      <StatusUpdateCityModal
        stateId={stateId}
        city={cityData}
        handleUpdateList={handleUpdateList}
        newStatus={newStatus}
      />
      <DeleteCityModal
        stateId={stateId}
        city={cityData}
        handleUpdateList={handleUpdateList}
      />
    </div>
  );
};

export default Cities;
